import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch/*, shallowEqual*/ } from "react-redux";
import { InfoWindowF, MarkerF/*, useGoogleMap*/ } from '@react-google-maps/api';
//import { remove_carecenters } from '../../features/CareCenterDataSlice';
//import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// import carecenterIcon from '../../assets/carecentericon.svg';
// import MMWarriorSVG from '../../assets/patienticon.svg';
// import CaregiverSVG from '../../assets/caregiver.svg';
// import MedicSVG from '../../assets/medic.svg';
import { activateMarker } from "../../features/activeMarkers";// activateCareCenter, activateWarrior /*} from '../features/activeMarkers'
//import { useAuthenticator } from "@aws-amplify/ui-react";
//import { useGetUserProfileQuery, isFetchBaseQueryError, isErrorWithMessage, FetchBaseQueryError } from '../../features/profilesSlice';
//import { skipToken } from '@reduxjs/toolkit/query';
import { useAuthProfile } from "../../app/hooks"
import { setPublicPinClicked /*, getProfileFromID, getAIProfileSummary */ } from "../../features/dataProcessingSlice"
import { GetUserTypePinSVG1 } from './MMMarkers';
import { PinChip } from "../MessageProfile";
import { Box, Button, Tooltip } from "@mui/material";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { customIcon } from "./MMMarkers"
import { AppDispatch } from "../../app/store";
import { showProfile } from "../../features/dialogSlice";
//import { chatApi } from "../../features/ChatSlice";
//import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from '@mui/icons-material/Edit';




export const UserMarkerNotMemoized: React.FC<any> = ({ zoom }) => {
  const markerRef = useRef<any>(null);
  const lastAnimationRef = useRef<number | null>(0);
  //const AIState = useSelector((state: any) => state.dataProcessing)

  const { authStatus, profile, isLoading, isSuccess, isError /*, error, userAttributesStateStatus*/ } = useAuthProfile()

  const [showinfoWindowAfterAnimation, setShowinfoWindowAfterAnimation] = useState(false);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const dispatch = useDispatch()

  //const [animationEnded, setAnimationEnded] = useState(false);
  //const markerLib = useMapsLibrary("marker");
  const markerType = authStatus === 'authenticated' ? profile.type : undefined
  // const userTypePin = {
  //   url: markerType === "P" ? patientIcon : markerType === "C" ? caregiverIcon : markerType === "M" ? medicIcon : carecenterIcon,
  // };

  //const activeMarkers = useSelector(state:any) => state.activeMarkers);
  const isMarkerActive = useSelector(
    (state: any) => {
      if (state.activeMarkers?.id === profile.id) {
        return true
      }
      return false
    }
  );

  const userTypePinSVG = GetUserTypePinSVG1(markerType)
  useEffect(() => {
    // to change the Pin if user type changes
    if (authStatus === 'authenticated' && markerRef.current) {
      markerRef.current.setIcon(customIcon(userTypePinSVG as unknown as string, zoom))//, { style: pinIconStyle }).icon);
    }
  }, [authStatus, userTypePinSVG, profile.type, zoom])

  useEffect(() => {
    if (markerRef.current) {
      if (!lastAnimationRef.current ) {//window.google.maps.Animation.DROP ){
        lastAnimationRef.current = 2
        markerRef.current.setAnimation(2);
        return
      }
    }
  }, [profile.type, profile.lat, profile.lng])//center.lat, center.lng,


  const handleAnimationChange = () => {
    if (markerRef.current) {
      const currentAanimation = markerRef.current.getAnimation()
      if (currentAanimation === null) {
        if (lastAnimationRef.current === 2) {
          markerRef.current.setAnimation(window.google.maps.Animation.BOUNCE);
          lastAnimationRef.current = 1 
          const timer1 = setTimeout(() => {
            markerRef.current.setAnimation(null);
            dispatch(activateMarker({ id: profile.id, disableHover: true }))
           setShowinfoWindowAfterAnimation(true)
            lastAnimationRef.current = null
          }, 1000);
          return () => { clearTimeout(timer1) };
        }
      }
      // if (currentAanimation === null) {
      //   if (lastAnimationRef.current  && !showinfoWindowAfterAnimation) {// multiple listeners?          
      //     dispatch(activateMarker({ id: profile.id, disableHover: true }))
      //     setShowinfoWindowAfterAnimation(true)
      //   }
      // } else {
      //   lastAnimationRef.current = markerRef.current.getAnimation()
      //   //setAnimationEnded( true)
      // }
    }
  }




  function onLoad(marker: any) {
    markerRef.current = marker
    window.google.maps.event.addListener(marker, "click", function (event: any) {
      //console.log("test")
      setInfoWindowOpen(true);
      dispatch(setPublicPinClicked(profile.id))
      dispatch(activateMarker({ id: profile.id, disableHover: true }))
      setInfoWindowOpen(false);
    });
  }


  const handleMouseOver = () => {
    if (isMarkerActive) {//if (activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(true);

    //activateCallBack(id)
  };

  const handleMouseOut = () => {
    if (isMarkerActive) {//if (activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(false);
  };

  function handleWindowClose() {
    setInfoWindowOpen(false);
    dispatch(activateMarker({ id: null, disableHover: false }))
    dispatch(setPublicPinClicked(false))
  };

  if (authStatus === 'unauthenticated' || !profile.is_completed || isLoading || isError)
    return null

  if (isSuccess)
    return (
      <MarkerF
        //key={`marker-user-${zoom}`}
        onLoad={onLoad}
        animation={window.google.maps.Animation.DROP}
        position={{ lat: profile.lat, lng: profile.lng }}
        icon={customIcon(userTypePinSVG as unknown as string, zoom)}
        onMouseOver={() => handleMouseOver()}
        onMouseOut={() => handleMouseOut()}
        //onClick={(e) => handleClick(e)}
        onAnimationChanged={() => handleAnimationChange()}
      >
        {((isMarkerActive && showinfoWindowAfterAnimation) || infoWindowOpen) && (
          <InfoWindowF
            options={{
              disableAutoPan: true,
            }}
            position={{ lat: profile.lat, lng: profile.lng }}
            onCloseClick={() => handleWindowClose()}>
            < UserMarkerInfoWindowDetails data={profile} />
          </InfoWindowF>
        )}
      </MarkerF>
    );
  return null
}


export const UserMarkerInfoWindowDetails: React.FC<{ data: any, children?: React.ReactNode }> = ({ data, children }) => {
  const dispatch = useDispatch<AppDispatch>()
  function handleClick() {
    dispatch(showProfile({ isForced: true }))
  }
  return (<>
    <Box sx={{ maxHeight: '250px', maxWidth: '225px' }} gap={2}>
      <Typography sx={{ mb: '5px', fontWeight: 'bold' }} variant="subtitle1">{data.name_public/*profile.name_public*/}
        {data?.comment?.length > 100 && <><Tooltip sx={{ display: 'inline' }} title="Edit My Pin" arrow>
          <EditIcon onClick={() => handleClick()} sx={{ ml: '7px', cursor: 'pointer', color: '#656EFF' }} />
        </Tooltip></>}
      </Typography>
      <PinChip category="User" type={data.type} size="small" />
      <Box sx={{ mt: '5px', width: '100%', display: 'flex', alignItems: 'center' }}>
        <RoomOutlinedIcon fontSize="small" color="primary" /><Typography variant="body2" color="#656EFF">{data.formattedsearchterm}</Typography>
      </Box>

      <Typography sx={{ mt: '5px' }} variant="body2">{data.comment}</Typography>


      <div>{children}</div>

      <Box sx={{ mt: '5px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ textTransform: 'none' }} onClick={() => handleClick()}> <Typography variant="body1" color="primary">Edit</Typography></Button>
      </Box>
    </Box>
  </>);
}

export const UserMarker = React.memo(UserMarkerNotMemoized);