import React from "react";
import {
  Typography,
  TypographyProps,
  createTheme,
  ThemeOptions,
  ThemeProvider
} from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    caption12r: true;  
    h7: true;
    h8: true;
    h9: true
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  caption12r: React.CSSProperties;
  h7: React.CSSProperties;
  h8: React.CSSProperties; 
  h9: React.CSSProperties; 

}

export const themePolygon = createTheme({
   
  typography: {
    h7: {
      fontSize: '1.1rem', 
      fontWeight: '470',
      lineHeight: '1.2',
      //fontWeight: 'bold',
      
        //fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
       // fontWeight: 500,
       // fontSize: "1.25rem",
       //lineHeight: '0.1',  
        //letterSpacing: "0.0075em",
      
    },
    h8: {
      fontSize: '1rem', 
      fontWeight: '540',
      lineHeight: '1.2',
      //fontWeight: 'bold',
      
        //fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
       // fontWeight: 500,
       // fontSize: "1.25rem",
       //lineHeight: '0.1',  
        //letterSpacing: "0.0075em",
      
    },
    h9: {
      fontSize: '1rem', 
      fontWeight: '410',
      lineHeight: '1.2',
      //fontWeight: 'bold',
      
        //fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
       // fontWeight: 500,
       // fontSize: "1.25rem",
       //lineHeight: '0.1',  
        //letterSpacing: "0.0075em",
      
    },
    poster: {
      fontSize: '4rem',
      color: 'red',
    },
    caption12r: {
      color: "red"
    },
    accentText: {
      fontWeight: 'bold', // Example accent style
      color: '#ff5722', // Accent color
    },
  } as ExtendedTypographyOptions,
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: '#ff5722', // Your custom button color
  //         color: 'white', // Text color
  //         '&:hover': {
  //           backgroundColor: '#e64a19', // Hover color
  //         },
  //       },
  //     },
  //   },
  // },
} as ThemeOptions);