import React, { useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CardMedia } from '@mui/material';
import { loadYouTubeAPI } from '../../app/utils'
import Linkify from 'react-linkify';

export const YouTubeContent: React.FC<{ videoId: string, title: string, description: string, isPlaying: boolean }> = ({ videoId, title = "", description, isPlaying }) => {
  const playerRef = useRef<HTMLIFrameElement>(null);
  /*
   useEffect(() => {
     const initializePlayer = async () => {
       try {
         await loadYouTubeAPI();
         const player = new window.YT.Player(playerRef.current, {
           videoId: videoId,
           // Other player configuration
         });
         // You can store the player instance or handle events here
       } catch (error) {
         console.error('Error loading YouTube API:', error);
       }
     };
 
     initializePlayer();
   }, [videoId]);
 */
  useEffect(() => {
    const handlePlay = (event: Event) => {
      console.log('Video started playing');
    };

    const handlePause = (event: Event) => {
      console.log('Video paused');
    };
    playerRef.current?.addEventListener('playing', handlePlay);
    playerRef.current?.addEventListener('pause', handlePause);
    return () => {
      playerRef.current?.removeEventListener('playing', handlePlay);
      playerRef.current?.removeEventListener('pause', handlePause);
    };
  }, [])
  return (
    <>
      <div>
        <CardMedia sx={{
          width: '100%', // Ensure the iframe takes up the entire width of the CardMedia
          aspectRatio: '16/9', // Maintain 16:9 aspect ratio
        }}>
          <iframe
            ref={playerRef}
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allowFullScreen
          ></iframe>
        </CardMedia>
      </div>
      <Box sx={{
        minHeight: '200px', maxHeight: '200px', overflow: 'auto',
        textOverflow: 'ellipsis',
      }}>
        <Typography variant="body2" color="text.secondary" >
          <Linkify> {description}</Linkify>
        </Typography>
      </Box>
    </>
  );
};

interface YouTubeCardProps {
  videoId: string;
  title: string;
  description: string;
  isPlaying: boolean
}

export const YouTubeContent1: React.FC<YouTubeCardProps> = ({ videoId, title, description, isPlaying }) => {
  const url = `https://www.youtube.com/embed/${videoId}`
  return (
    <>
      <CardMedia
        component="iframe"
        title="YouTube video player"
        src={url}
        allowFullScreen
        sx={{
          width: '100%', // Ensure the iframe takes up the entire width of the CardMedia
          aspectRatio: '16/9', // Maintain 16:9 aspect ratio
        }}
      />
      <CardContent sx={{
        minHeight: '220px', maxHeight: '220px', overflow: 'auto',        
      }}>
        <Typography gutterBottom variant="subtitle2" component="div" fontWeight='bold'>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <Linkify>{description.trim()}</Linkify>
        </Typography>
      </CardContent>
    </>)
}

/*

interface YouTubeCardProps {
  videoId: string;
  title: string;
  description: string;
}

export const YouTubeCard: React.FC<YouTubeCardProps> = ({ videoId, title, description }) => {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <div>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allowFullScreen
          ></iframe>
        </div>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

*/
/*
      return (
          <Card elevation={0} onClick={() => handleClick()} sx={{
              borderRadius: '16px',
              backgroundColor: resourceData.backgroundColor,
              display: 'flex',
              height: '100%',
              padding: 0
              //minHeight: '100vh', // Adjust this value based on your needs
          }}>
  
              <CardContent>
                  {isLoading ? <CircularProgressCentered /> : <>
                      <Box sx={{ width: "100%", height: "80%", backgroundColor: 'grey' }}>
                          {resourceData.image && <MySvgImage filePath={resourceData.image} />}
                          {(resourceData.urlImage) && <img src={resourceData.urlImage} alt={title} width="100%" height="100%" />}
                          {(true && isSuccess && metaData && metaData.image) &&
                              <img src={metaData.image} alt={metaData["image:alt:"] ? metaData["image:alt:"] : ""} width="100%" height="100%" />}
                      </Box>
                      <Typography onClick={handleClick} variant='h6' fontWeight="bold" sx={{
                          display: 'flex',
                          alignItems: 'center', alignContent: 'center',
                          justifyContent: 'left'
                      }}  >
                          {title}
                      </Typography>
                      <Typography onClick={handleClick} sx={{
                          display: 'flex',
                          alignItems: 'center', alignContent: 'center',
                          justifyContent: 'left'
                      }} variant="body2" >
                          {subTitle}
                      </Typography>
                  </>
                  }
  
              </CardContent>
          </Card>
  
      );
  */