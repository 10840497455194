const resourceData: any = {
    cardType: "ResourceCardCTA",
    subs: [
        //    {
        //    title: 'Patient Stories',
        //    subTitle: 'Hear stories from your fellow MM Warriors',
        //   image: 'PatientStories',
        //    backgroundColor: 'rgb(255, 236, 179)',
        //
        //   sub: {
        //     subs: [
        {//ok
            title: "Treatments",
            subTitle: 'Find out more about MM specific treatment options',
            href: '/treatments',
            image: 'Treatments',
            backgroundColor: 'rgb(209, 213, 255)',
            sub: {
                title: "Treatments",
                cardType: "ResourceLinkCard",
                subs: [
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Myeloma Precursor Stages MGUS and Smoldering Multiple Myeloma",
                        metaDataUrl: 'https://docs.google.com/document/d/1kG0OS0Y1wE55vp21emJFSKQ6Sd9Opfzd/edit?usp=sharing&ouid=110469655224272566229&rtpof=true&sd=true',
                        urlImage: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Icon-doc.svg'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Stem Cell Pros and Cons",
                        metaDataUrl: 'https://drive.google.com/file/d/1CZrsFS43yRaUeq7fNsRooJBzEsOff40m/view?usp=sharing',
                        urlImage: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Icon-doc.svg'

                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Peripheral Neuropathy Treatments",
                        metaDataUrl: 'https://docs.google.com/document/d/1IksW9uP1E7KcfYt_LXsjpEhP-ag6jGhS/edit?usp=sharing&ouid=110469655224272566229&rtpof=true&sd=true',
                        urlImage: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Icon-doc.svg'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Discover patent specific therapies",
                        metaDataUrl: 'https://drive.google.com/file/d/1dltQyZsSGmMm7w07udjyR0Z1o8pry6S-/view?usp=sharing',
                        urlImage: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Icon-doc.svg'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Stem Cell Transplant Impatient List of Packing items",
                        metaDataUrl: 'https://drive.google.com/file/d/1urB8Jcel640QI5qdYh__kdcb-OvLLBoc/view?usp=sharing',
                        urlImage: 'https://upload.wikimedia.org/wikipedia/commons/8/8a/Icon-doc.svg'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "National Bone Marrow Transplant Link (NBMT Link)",
                        metaDataUrl: 'https://www.nbmtlink.org/',
                        urlImage: 'https://v8b6j5e3.rocketcdn.me/wp-content/uploads/2018/11/nbmt-logo-retina.png',
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "The National Comprehensive Cancer Network (NCCN)",
                        metaDataUrl: 'https://www.nccn.org/home',
                        urlImage: 'https://storage.googleapis.com/crowdnews-media-library/2016/04/nccn-logo.png',
                    },
                ]
            }

        },
        {
            title: 'Patient Story',
            backgroundColor: '#fff',
            subTitle: 'Hear stories from your fellow MM Patients',
            image: 'PatientStories',
            href: '/ThePatientStory',
            sub: {
                title: 'Patient Stories',
                subTitle: 'Hear stories from your fellow MM Patients',
                cardType: "ResourceYoutubeCard",
                subs: [
                    { title: 'Hope from Louisiana: Alan’s MM Journey', youtubeUrl: 'https://www.youtube.com/watch?v=epUX8yYpOgU' },
                    { title: 'Walking on a Tightrope: Gemma’s MM Journey', youtubeUrl: 'https://www.youtube.com/watch?v=aQViL4YCCg8' },
                    { title: 'Conquering Patagonia and Blood Cancer: Michael', youtubeUrl: 'https://www.youtube.com/watch?v=GRARgEbE0Bg' },
                    { title: 'Conquering Blood Cancer with Horses: David', youtubeUrl: 'https://www.youtube.com/watch?v=6EFTTPkYS2k' },
                    { title: 'Bad Blood? Shake it Off!: Kelly’s Car-T Journey', youtubeUrl: 'https://www.youtube.com/watch?v=XmZg0edhypE' },
                    { title: 'Know Your “Why”: Ismael’s MM Journey', youtubeUrl: 'https://www.youtube.com/watch?v=M6JxC1Ec6Ko' },
                    { title: 'Be Curious. Believable Hope: Peter’s MM Story', youtubeUrl: 'https://www.youtube.com/watch?v=Od3ski6mMO4' },
                    //{ title: 'FAITH, STRENGTH, AND COURAGE: A Memoir of Overcoming Adversity & Embracing Life's Journey.', youtubeUrl: 'https://www.youtube.com/watch?v=Od3ski6mMO4' },
                    {
                        cardType: "ResourceLinkCard",
                        urlImage: 'https://m.media-amazon.com/images/I/618vZ0upxkL._SY466_.jpg',
                        title: "FAITH, STRENGTH, AND COURAGE: A Memoir of Overcoming Adversity & Embracing Life's Journey.",
                        subTitle: 'HealthTree unites patients and researchers through cutting-edge technology so they can work together to discover lifesaving treatments for diseases.',
                        metaDataUrl: 'https://www.amazon.com/FAITH-STRENGTH-COURAGE-Overcoming-Adversity/dp/6175361075'
                    },
                    
                ]
            },
            //},
            /* {
                 title: 'Kut2ThaChase Podcast',
                 image: '',
                 href: '/',

             },
             {
                 title: 'Multiple Myeloma Warriors',
                 image: '',
                 href: '/',

             },
             {
                 title: 'Life After CAR T',
                 image: '',
                 href: '/',

             },
         //]
     },*/
        },
        {
            title: 'Patient Advocacy Organizations',
            subTitle: 'These organizations may help you along your journey',
            href: '/PatientAdvocacyOrganizations',
            image: 'PatientAdvocacyOrganizations',
            backgroundColor: 'white',
            sub: {
                cardType: "ResourceLinkCard",
                title: 'Learn About Patient Advocacy Organizations',
                subTitle: 'See if these organizations can help you along your journey',
                subs: [
                    {// ok - image embedded
                        //image: 'PatientAdvocacyOrganizations',
                        title: "International Myeloma Foundation (IMF)",
                        subTitle: 'Improving Lives, Finding the Cure Through Research and Patient Care',
                        metaDataUrl: 'https://www.myeloma.org/',
                        urlImage: 'https://upload.wikimedia.org/wikipedia/en/1/1d/IMF_logo2018.jpg',
                        backgroundColor: 'rgb(209, 213, 255)',
                    },

                    {//ok
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Multiple Myeloma Research Foundation (MMRF)",
                        urlImage: 'https://themmrf.org/wp-content/uploads/2023/06/MMRF-Logo_166x44.png',
                        metaDataUrl: 'https://themmrf.org/'

                    },
                    {//ok
                        backgroundColor: 'rgb(209, 213, 255)',
                        urlImage: 'https://lls.org/themes/custom/llscorp/logo.svg',
                        title: "Leukemia and Lymphoma society",
                        metaDataUrl: 'https://lls.org/'
                    },
                    {//ok
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "American Cancer Society (multiple myeloma section)",
                        metaDataUrl: 'https://www.cancer.org/cancer/types/multiple-myeloma/about/what-is-multiple-myeloma.html'
                    },
                    {//ok
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "National Cancer Institute (myeloma section)",
                        metaDataUrl: 'https://www.cancer.gov/types/myeloma',
                        urlImage: 'https://cdn.cancerhistoryproject.com/media/2020/12/31165740/nci-logo.jpg'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Health Tree",
                        subTitle: 'HealthTree unites patients and researchers through cutting-edge technology so they can work together to discover lifesaving treatments for diseases.',
                        metaDataUrl: 'https://healthtree.org/'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        urlImage: 'https://findhelp.auntbertha.com/images/social/FH_Minisplashimg.png',
                        title: 'findhelp',
                        subTitle: 'Find free or reduced-cost resources like food, housing, financial assistance, health care, and more.',
                        href: "https://www.findhelp.org/"
                    },


                ]
            }
        },
        /*
        {
            title: 'Communities',
            subTitle: 'Connect with other Warriors and Caregivers',
            href: '/community',
            backgroundColor: 'rgb(225, 228, 255)',
            image: 'Communities',
            sub: {
                title: 'Join a Multiple Myeloma Community',
                subTitle: 'Connect with other Warriors and Caregivers',
                cardType: "ResourceLinkCard",
                    subs: [
                        {
                            backgroundColor: 'rgb(225, 228, 255)',
                            title: 'MM Warriors Group',
                            urlImage: 'https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-2019.png',
                            metaDataUrl: 'https://www.facebook.com/groups/239866928407935/'
                        },
                        {
                            backgroundColor: 'rgb(225, 228, 255)',
                            title: 'Throwing Bones',
                            //urlImage: 'https://scontent-lga3-2.xx.fbcdn.net/v/t39.30808-6/241993698_1748878005313683_7365068402931215732_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=RNG86NlADJIAb4_GsK-&_nc_ht=scontent-lga3-2.xx&oh=00_AfDDDER4pvgmIOAZXyQ_9E5F3RD5uhZPbriUttv9Bf-pcw&oe=662AE12E',
                            urlImage: 'https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-2019.png',
                            metaDataUrl: 'https://www.facebook.com/throwingbones4cancer'
                        },
                        {
                            backgroundColor: 'rgb(225, 228, 255)',
                            title: 'CAR T-CELL Patients And Care Givers',
                            urlImage: 'https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-2019.png',
                            metaDataUrl: 'https://www.facebook.com/groups/310245546022052'
                        },
                        {
                            backgroundColor: 'rgb(225, 228, 255)',
                            title: 'Changing Gears: Still Fighting After CAR-T',
                            urlImage: 'https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-2019.png',
                            metaDataUrl: 'https://www.facebook.com/groups/200056681229188'
                        },
                        {
                            backgroundColor: 'rgb(225, 228, 255)',
                            title: 'Darzalex (Daratumumab) / Darzalex Faspro',
                            urlImage: 'https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-2019.png',
                            metaDataUrl: 'https://www.facebook.com/groups/217973475357940'
                        },
                    ]
    
                
            }
        },
        {
            title: "Financial",
            subTitle: 'Learn about patient pharma assistance programs',
            href: '/financials',
            image: 'Money',
            backgroundColor: 'rgb(209, 213, 255)',
            sub: {
                title: "Financial Resources",
                cardType: "ResourceLinkCard",
                subs: [
                    {
                        urlImage: 'https://fundfinder.panfoundation.org/images/logo-fundfinder.svg',
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Fund Finder (find funds from multiple organizations)",
                        metaDataUrl: 'https://fundfinder.panfoundation.org/'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Janssen Patient Assistance Program",
                        metaDataUrl: 'https://drive.google.com/file/d/1dF6B2zNFrHzMucKQSBQrCetbZKGWHt4S/view?usp=sharing',
                        urlImage: 'https://arminarm.org/wp-content/uploads/2022/10/Janssen-logo-web.jpg',
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "LLS CO-PAY ASSISTANCE PROGRAM",
                        metaDataUrl: 'https://www.lls.org/support-resources/financial-support/co-pay-assistance-program',
                        urlImage: 'https://lls.org/themes/custom/llscorp/logo.svg',
                    }, {
                        urlImage: 'https://www.amgensupportplus.com/-/media/Themes/Amgen/amgensupportplus-com/Amgensupportplus-com/images/CoPay/Amgen-support-plus-Logo.svg',
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "Amgen Assist 360",
                        metaDataUrl: 'https://www.amgensupportplus.com/copay'
                    },
                ]
            }
        },
        */
        /*
        {
            title: "Events",
            subTitle: 'Discover MM events happening in the community',
            href: '/events',
            image: 'Event',
            backgroundColor: 'rgb(209, 213, 255)',
            sub: {
                title: "Events",
                cardType: "ResourceLinkCard",
                subs: [
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "MM Warrior trip to Italy in 2025",
                        metaDataUrl: 'https://www.facebook.com/groups/239866928407935/permalink/407987458262547/',
                        urlImage: 'https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-2019.png'
                    },
                    {
                        backgroundColor: 'rgb(209, 213, 255)',
                        title: "MM Warriors Flyer",
                        metaDataUrl: 'https://drive.google.com/file/d/1F9v4azGcMWYbpYUmamyYUSsOBrmkW2l0/view?usp=sharing',
                        urlImage: 'https://1000logos.net/wp-content/uploads/2016/11/Facebook-Logo-2019.png'
                    },

                ]
            }
        },
        {
            title: 'Studies',
            subTitle: 'See if you are eligible to participate in a study',
            backgroundColor: 'rgb(240, 255, 244)',
            image: 'FindaStudy',
            sub: {
                title: 'More Studies will be Avaialbe Soon...',
                subTitle: 'We are working hard to bring more studies to members of the Polygon Health MM Community.',
                cardType: "ResourceLinkCard",
                subs: [
                    {
                        backgroundColor: 'rgb(240, 255, 244)',
                        title: 'Car T Paid Study',
                        metaDataUrl: 'https://www.polygonhealth.app/multiplemyeloma'
                    },
                ]

            }

        },
        */
        /*{
            title: 'Common Treatments',
                description: 'Treatment options for multiple melanomas typically involve a combination of therapies.\
                It is important for patients to work closely with a multidisciplinary team of healthcare professionals, including dermatologists, \
                surgical oncologists, medical oncologists, and radiation oncologists, to develop a comprehensive treatment plan tailored to their specific situation. \
                Treatment decisions will depend on factors such as the stage of the melanomas, their location, the presence of genetic mutations, overall health status, and individual preferences.',
                    links: [
                        { name: 'Chemotherapy', href: 'https://www.cancer.org/cancer/types/multiple-myeloma/treating/chemotherapy.html' },
                        {
                            name: 'Targeted Therapy ',
                            links: [
                                { name: 'daratumumab', href: 'https://www.darzalexhcp.com/' },
                                { name: 'elotuzumab', href: 'https://www.empliciti.com/' }
                            ]
                        },
                        {
                            name: 'Corticosteroids',
                            links: [
                                { name: 'dexamethasone', href: 'http://xpovio.com' },
                                { name: 'prednisone', href: 'http://xpovio.com' }
                            ]
                        },
                        {
                            name: 'Immunotherapy',
                            links: [
                                { name: 'daratumumab', href: 'https://www.darzalexhcp.com/' },
                                { name: 'elotuzumab', href: 'https://www.empliciti.com/' }
                            ]
                        }, {
                            name: 'Corticosteroids',
                            links: [
                                { name: '', href: '' },
                                { name: '', href: '' }
                            ]
                        },
                        { name: 'Stem Cell Transplant', href: 'https://www.cancer.org/cancer/types/multiple-myeloma/treating/stem-cell-transplant.html' },
                        {
                            name: 'Car-T',
                            links: [
                                { name: 'Carvkyti', href: 'https://carvyktihcp.com' },
                                { name: 'Abecma', href: 'https://abecma.com' }
                            ]
                        },
        
                    ]
        },
        {
            name: 'Financial Resources',
                href: 'https://themmrf.org/support/financial-assistance-and-transportation/',
                    links: [
                        { name: 'Insurance Coverage' },
                        { name: 'Pharmaceutical Assistance Programs' },
                        {
                            name: 'Non-Profit Orgs',
                            links: [
                                { name: 'HealthTree', href: 'https://healthtree.org/' },
                            ]
                        },
                        { name: "Gov’t Assistance" },
                        { name: 'Cancer-Specific MM Programs' },
                        { name: 'Hospital-Specific Assistance Programs' },
                        { name: 'Crowdfunding' },
                    ]
        },
        */
    ]
}
export default resourceData;
