import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState =
{
  //  searchTerm: "",
  //  formattedSearchTerm: undefined,//"New York, NY",
  //  center: undefined, //{ lat: 40.730610, lng: -73.935242 },
  status: { isUnInitialized: true },
  zoom: 6
};

export const getSearchByTerm = createAsyncThunk("search/getSearchByTerm", async (address, thunkAPI) => {
  address = address.replace(",", "%2C")
  address = address.replace(" ", "%20")
  address = address.replace("#", "%20")
  
  try {
    const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=AIzaSyDvqapzpG_NMGMUv9Nj9IJnLNasbt_XzR8");
    //console.log(response.data.results[0]);
    //const location = response.data.results[0].geometry.location
    //var lat = location.lat;
    //var lng = location.lng;
    //dispatch(loadData( {type:"carecenters", center: {lat, lng}}));
    return response.data.results[0];
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
export const getSearchByCoordinates = createAsyncThunk("search/getSearchByCoordinates", async (center, thunkAPI) => {

  try {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${center.lat},${center.lng}&key=AIzaSyDvqapzpG_NMGMUv9Nj9IJnLNasbt_XzR8`;
    const response = await axios.get(url)
    const addressComponents = response.data.results[0].address_components;
    //console.log('Address Components:', addressComponents);
    return addressComponents;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearch: (state, action) => {
      //state.searchTerm = action.payload;
      if (action.payload.lat && action.payload.lng && action.payload.formattedsearchterm) {
        state.center = { lat: action.payload.lat, lng: action.payload.lng };
        state.formattedSearchTerm = action.payload.formattedsearchterm;
        state.zipCode = action.payload.zipcode;
        state.status = { isProfileSet: true, isLoading: false, isSuccess: true, isError: false, error: undefined }
      } else
        state.status = { isDefaulted: true, isSuccess: true, isError: false, error: undefined }
    },
    setCenter: (state, action) => {
      state.center = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    }
  },
  extraReducers: (builder) => {
    // getSearchByTerm
    builder.addCase(getSearchByTerm.pending, (state, action) => {
      state.searchTerm = action.meta.arg;
      state.status = { isLoading: true, isSuccess: false, isError: false, error: undefined }
    })
    builder.addCase(getSearchByTerm.fulfilled, (state, { payload }) => {
      if (!payload) {
        state.searchStatus = { ...state.searchStatus, error: "payload" }
        return
      }
      if (!payload.geometry) {
        state.searchStatus = { ...state.searchStatus, error: "payload.geometry" }
        return
      }
      if (!payload.geometry.location) {
        state.searchStatus = { ...state.searchStatus, error: "payload.geometry.location" }
        return
      }
      
      state.center = { lat: payload.geometry.location.lat, lng: payload.geometry.location.lng };
      state.formattedSearchTerm = payload.formatted_address;
      state.status = { isLoading: false, isSuccess: true, isError: false, error: undefined }
      const updateState = getSearchStateFromPayload(payload) 
      state.zipCode = updateState.zipCode
      state.zoom = 12;
    });
    builder.addCase(getSearchByTerm.rejected, (state, action) => {
      state.searchTerm = "";
      state.formattedSearchTerm = "";
      state.status = { isLoading: false, isSuccess: false, isError: true, error: "rejected" }
    })
    //getSearchByCoordinates
    builder.addCase(getSearchByCoordinates.pending, (state, action) => {
      state.status = { isLoading: true, isSuccess: false, isError: false, error: undefined }
      state.centerRequested = { ...action.meta.arg }
      state.center = {lat:0,lng:0}
    })
    builder.addCase(getSearchByCoordinates.fulfilled, (state, { payload }) => {
      //types: ['locality', 'political']
      const foundLocal = payload.filter(row => {
        const rowHasLocalityTypes = row.types.filter(type => type.includes('locality')).length > 0
        return rowHasLocalityTypes
      });
      //const mystate = getSearchStateFromPayload(payload) 

      const foundState = payload.filter(row => row.types.includes('administrative_area_level_1'));
      state.formattedSearchTerm = `${foundLocal.length > 0 ? foundLocal[0].short_name : ""}, ${foundState.length > 0 ? foundState[0].short_name:""}`
      state.center = { ...state.centerRequested }          
      state.centerRequested = undefined
      const foundZip = payload.filter(row => row.types.includes('postal_code'));
      state.zipCode =  foundZip.length ===1? foundZip[0].short_name: undefined
      state.status = { isLoading: false, isSuccess: true, isError: false, error: undefined }
      console.log(state.zoom)
    })
    builder.addCase(getSearchByCoordinates.rejected, (state, action) => {
      state.formattedSearchTerm = "";
      state.status = { isLoading: false, isSuccess: false, isError: true, error: "rejected" }
    });

  }

});
export const getSearchStateFromPayload = (payload) => {
  if (!payload)
    return { searchStatus: { isLoading: false, isSuccess: false,isError: true, error: "payload" } }
  if (!payload.geometry)
    return { searchStatus: { isLoading: false, isSuccess: false,isError: true, error: "payload.geometry" } }
  if (!payload.geometry.location)
    return { searchStatus: { isLoading: false, isSuccess: false, isError: true, error: "payload.geometry.location" } }
 /* const foundLocal = payload.filter(row => {
    const rowHasLocalityTypes = row.types.filter(type => type.includes('locality')).length > 0
    return rowHasLocalityTypes
  });
  const foundState = payload.filter(row => row.types.includes('administrative_area_level_1'));
  */
  const foundZip = payload.address_components.filter(row => row.types.includes('postal_code'));
  return {
    zipCode: foundZip.length ===1? foundZip[0].short_name: '',
    center: payload.geometry.location,
    formattedsearchterm: payload.formatted_address,
    status: { isLoading: false, isSuccess: true, isError: false, error: undefined }
  }
}
export const { setSearch, setCenter, setZoom } = searchSlice.actions;


//export const searchSliceReducer =  searchSlice.reducer;
export default  searchSlice.reducer;
