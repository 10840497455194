import React, { useState, useEffect, useRef } from "react"
import Paper from '@mui/material/Paper';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { showProfile } from "../../features/dialogSlice";
import { useDispatch } from 'react-redux';
import { useAuthProfile } from "../../app/hooks"
import Snackbar from '@mui/material/Snackbar';

export const NavMini: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>();
    const { authStatus, profile: loggedInProfile, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady } = useAuthProfile()
    const [open, setOpen] = useState(false);

    function handleProfileClick() {
        if (authStatus == 'authenticated') {
            dispatch(showProfile({ isForced: true }))
        } else {
            navigate('/sign-in')
        }
    }

    return (
        <Grid>
            <Grid item xs={12} sx={{pb:1}} >
                <Paper onClick={() => {navigate('/resources')}} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                    <Stack direction="column" sx={{pt:1, pb:1, pl:3,pr:2, display:'flex', width:'100%', backgroundColor:'#FFF3FC', borderRadius:8}}>
                        <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Knowledge Connect</Typography>
                            <ArrowOutwardIcon sx={{color:'#AAAAAA'}} />
                        </Stack>
                        <Typography variant="caption">Search anything about Multiple Myeloma</Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{pb:1}}>
                <Paper onClick={() => {navigate('/messages')}} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                    <Stack direction="column" sx={{pt:1, pb:1, pl:3,pr:2, display:'flex', width:'100%', backgroundColor:'#F0F1FF', borderRadius:8}}>
                        <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Messages</Typography>
                            <ArrowOutwardIcon sx={{color:'#AAAAAA'}}/>
                        </Stack>
                        <Typography variant="caption">Chat with other users</Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{pb:1}}>
                <Paper onClick={handleProfileClick} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                    <Stack direction="column" sx={{pt:1, pb:1, pl:3,pr:2, display:'flex', width:'100%', backgroundColor:'#FAF6F4', borderRadius:8}}>
                        <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>My Pin</Typography>
                            <ArrowOutwardIcon sx={{color:'#AAAAAA'}}/>
                        </Stack>
                        <Typography variant="caption">Unlock analytics by telling your story</Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{pb:1}}>
                <Paper onClick={() => {window.open("https://www.polygonhealth.com/blog", '_blank')}} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                    <Stack direction="column" sx={{pt:1, pb:1, pl:3,pr:2, display:'flex', width:'100%', backgroundColor:'#F0F1FF', borderRadius:8}}>
                        <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Blog</Typography>
                            <ArrowOutwardIcon sx={{color:'#AAAAAA'}}/>
                        </Stack>
                        <Typography variant="caption">Read our recent blogs and updates</Typography>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
//{showDeleteAccountState && <ConfirmDeleteProfile handleSignOut={handleSignOut} />}
//export default ChatsPageLoader;