import React, { useState } from 'react';
import {  useMediaQuery, useTheme } from '@mui/material';
//import { ProfileFormData, QuestionsForm, ProfileQuestionsFormProps } from '../wizard/QuestionsForm'
//import { HeaderContent } from '../../app/sharedcomponents';
import {  useDispatch } from 'react-redux';
//import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { showProfile, showSurvey } from "../../features/dialogSlice";
// import Slide from '@mui/material/Slide';
// import { TransitionProps } from '@mui/material/transitions'
//import { ProfileDialogTitle } from '../wizard/ProfileDialogTitle'
import { useGetSurveyQuery,  usePatchSurveyMutation } from '../../features/surveysSlice';
//import { SearchState, getSearchStateFromPayload, getSearchByTerm } from '../../features/searchSlice'
//import { useForm, SubmitHandler, Controller, FormProvider } from 'react-hook-form';
//import StepProgressBar from '../wizard/StepProgressBar';
import { CircularProgressCentered } from '../../app/sharedcomponents'
//import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { StepType  } from '../wizard/QuestionsForm'
//import { YesNo } from '../wizard/YesNo';
//import { store } from '../../app/store';
import { WizardDialog, UpdateStepArgsType } from '../wizard/WizardDialog';
import { Demographics } from './Demographics';
import { Experience } from './Experience';
import { Medical } from './Medical';
//import { Financial } from './Financial';
import { Welcome } from './welcome';
import { Preferences } from './Preferences';
import { ThankYou } from './ThankYou';
import { useUpdateProfileMutation } from '../../features/profilesSlice';
//import { theme1 } from '../../app/theme1';


// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="right" ref={ref} {...props} />;
// });


interface SurveyDialogProps {
  //surveyData: any,  
  iamId: string,
  onClose: () => void;
  profile: any,
  isForced?: boolean
}

// type Inputs = {
//   example: string
//   exampleRequired: string
// }
interface UpdateSate {
  isSuccess: boolean | undefined,
  step: number | undefined,
  data: any
  profileDTO: any
}

//const surveyScreens: ReactJSXElement[] = [<YesNo fieldName='willingToProvideData' />, <Demographics />, <Experience />, <Medical />, <Financial />]

export const defaultSurveySteps: StepType[] = [
  { screen: <Welcome />, title: "Welcome!", buttonTitle: "Continue", noProgressBar: true, titleColor: '#656EFF', autoSizeDialog: true },
  { screen: <Preferences />, title: "Preferences" },

  {
    screen: <Demographics />,
    title: "Demographics",
  },
  //{ screen: <Demographics />, title: "Demographics" },
  { screen: <Experience />, title: "Experience" },
  { screen: <Medical />, title: "Medical History and Treatment", buttonTitle: "Finish" },
  // { screen: <Financial />, title: "Financial Information" }
  {
    screen: <ThankYou />,
    subTitle: "Thank you for answering the profile questions!",
    subTitle2: "Would you like to add your pin on the map?",
    noProgressBar: true, noBackArrow: true, buttonTitle: "Continue", autoSizeDialog: true
  }
];
//const surveySteps :StepType[] =defaultSurveySteps

export interface SurveyFormData {
  willingToProvideData: boolean | undefined
  comment: string | undefined
  type: string | undefined
  communicateWithOthers: string | undefined;
  receiveEmails: string | undefined;
  provideInformationForResearch: string | undefined;

}

//const ReplaceStep: (surveySteps: StepType[], step: StepType, index: number) => StepType[] = (surveySteps, step, index) => {
export const ReplaceStep: (surveySteps: any[], step: any, index: number) => any[] = (surveySteps, step, index) => {
  const ret = surveySteps.slice(0, index)
  const ret1 = ret.concat(step ? [step] : [], surveySteps.slice(index + 1))   // Copy the elements after the index to replace

  return ret1
}


export const ReplaceStepBySubTitle: (surveySteps: any[], newStep: any, subTitle: string) => { isNew: boolean, steps: any[]} = (surveySteps, newStep, subTitle) => {
  var stepReplaced: boolean = false
  const ret = surveySteps.map(step => {
    if (step.subTitle === subTitle) {
      stepReplaced = true
      return newStep
    }
    return step
  })
  const ret1 = ret.filter(s => s)
  return { isNew: stepReplaced,   steps: stepReplaced? ret1 : surveySteps }
}

export const GetStepIndexByTitle : ( steps: any[], title:string )=> number | undefined = (steps, title) =>{
  return steps.findIndex( step => step.title === title)
}

// export const SurveyWizard: React.FC<SurveyDialogProps> = ({ /*iamId,*/ profile, isForced = false }) => {
//   const [stepIndex, setStepIndex] = useState(0);
//   const [wizardSteps, setWizardSteps] = useState(defaultSurveySteps);
//   const [userTypeStepCompleted, setuserTypeStepCompleted] = useState(false);
//   //const [isSurveyInPorgress, setIsSurveyInPorgress] = useState(false);
//   //const showSurveyState = useSelector((state: any) => state.dialog.showSurvey, shallowEqual)
//   const dispatch = useDispatch<any>();// useAppDispatch()
//   const [isUpdated /* setIsUpdated*/] = useState<UpdateSate>({ isSuccess: false, step: undefined, data: undefined, profileDTO: undefined });


//   const { data: surveyData, isLoading: isSurveyLoading, isSuccess: isSurveySuccess /*, isError: isSurveyError, error: SurveyError */} = useGetSurveyQuery({ id: profile.id, type: "D" })
//   const [PatchSurvey,/* { isLoading, isSuccess, isError, error, data: updatedFormData, reset }*/] = usePatchSurveyMutation()
//   const [UpdateProfile /*, { isLoadingUpdateProfile, isSuccessUpdateProfile, isErrorUpdateProfile, errorUpdateProfile, data: updatedProfileFormData, resetUpdateProfile }*/] = useUpdateProfileMutation()

//   // const SkipToLastStep: (stepIndexUpdate: StepIndexUpdateType) => void = (stepIndexUpdate) => {
//   //   stepIndexUpdate.stepIndex = stepIndexUpdate.steps.length - 2
//   //   stepIndexUpdate.isUpdated = true
//   // }


//   const handleBack = () => {
//     if (stepIndex > 0) {
//       setStepIndex(stepIndex - 1); // Go back a step
//     } else {
//       //onClose(); // Close the dialog if already at the initial step
//       //onCloseLocal()
//       dispatch(showProfile({ isForcedFromSurvey: true }))
//     }
//   };


//   const UpdateStep: (args: UpdateStepArgsType) => any = async ({ formData, stepIndexUpdate, methods }) => {
//     const surveySteps: StepType[] = stepIndexUpdate.steps
//    // const { errors, isDirty, dirtyFields } = methods.formState
//     const lastStep = stepIndexUpdate.stepIndex === surveySteps.length - 1
//     //UPDATING EVERY SCREEN IS OK
//     console.log(formData); // Form data
//     //not updating on thank you/finish later step and not including it in total count      
//     //PatchSurvey({ iamId: iamId, type: 'D', surveyDTO: { ...formData, stepsCompleted: (stepIndexUpdate.stepIndex + 1), totalStepCount: surveySteps.length }, stepsCompleted: (stepIndexUpdate.stepIndex + 1) })

//     if (stepIndexUpdate.steps[stepIndexUpdate.stepIndex].title === "Preferences") {
//       await UpdateProfile({ id: profile.id, profileDTO: { ...formData } })
//       setuserTypeStepCompleted(true)
//       if (formData.type === 'P') {
//         //setStepIndex(wizardSteps.length - 1)
//       }
//       else {
//         PatchSurvey({ id: profile.id, type: 'D', surveyDTO: { ...formData, stepsCompleted: (stepIndexUpdate.stepIndex + 1), totalStepCount: surveySteps.length, surveyCancelled: false, surveryCompleted: true }, stepsCompleted: (stepIndexUpdate.stepIndex + 1) })
//         if (profile.is_completed) {
//           const thankYouStep: StepType = { ...surveySteps[surveySteps.length - 1], screen: <ThankYou showSubtitle={false} />, noButton: true }
//           setWizardSteps(ReplaceStep(surveySteps, thankYouStep, surveySteps.length - 1))
//           ReplaceStep(surveySteps, thankYouStep, surveySteps.length - 1)
//         }
//         setStepIndex(wizardSteps.length - 1)//SkipToLastStep(stepIndexUpdate)     
//         return true
//       }
//     }
//     const surveryCompleted = surveyData.surveryCompleted || stepIndexUpdate.stepIndex >= (surveySteps.length - 2)
//     PatchSurvey({
//       id: profile.id, type: 'D', surveyDTO: {
//         ...formData, stepsCompleted: (stepIndexUpdate.stepIndex + 1), totalStepCount: surveySteps.length,
//         surveryCompleted: surveryCompleted,
//         surveyCancelled: false,
//         ...stepIndexUpdate.stepIndex === 4 ? { surveryCompleted: true } : {}
//       },
//       stepsCompleted: (stepIndexUpdate.stepIndex + 1)
//     })
//     if (stepIndex < 1) //const PreferencesIndex = surveySteps.findIndex(step => step.title === "Preferences" )      
//       setuserTypeStepCompleted(surveryCompleted)

//     if (stepIndexUpdate.stepIndex === (surveySteps.length - 2)) {
//       if (profile.is_completed) {
//         const thankYouStep: StepType = {
//           ...surveySteps[surveySteps.length - 1],
//           screen: <ThankYou shoowCheckMark={true} showSubtitle={false} />,
//           noButton: true
//         }
//         //stepIndexUpdate.steps = ReplaceStep(surveySteps, thankYouStep, surveySteps.length - 1)
//         setWizardSteps(ReplaceStep(surveySteps, thankYouStep, surveySteps.length - 1))
//       }
//     }
//     if (lastStep) {
//       dispatch(showSurvey({ isExited: true, isForcedFromSurvey: false, isForced: false }))
//       if (!profile.is_completed) {
//         dispatch(showProfile({ ...userTypeStepCompleted ? { isForcedFromSurvey: true } : { isForced: true } }))
//       }
//       return true
//     }
//     setStepIndex(stepIndex + 1)//SkipToLastStep(stepIndexUpdate)
//     return true
//   }

//   const onCloseSurvey: (args: UpdateStepArgsType) => void = ({ stepIndexUpdate }) => {
//     //const surveySteps1: StepType[] = [...surveyStepsRef.current]
//     if (stepIndexUpdate.stepIndex === (stepIndexUpdate.steps.length - 1)) {
//       dispatch(showSurvey({ isExited: true, isForcedFromSurvey: false, isForced: false }))
//       return
//     }
//     PatchSurvey({
//       id: profile.id, type: 'D', surveyDTO: {
//         surveyCancelled: true
//       },
//       stepsCompleted: (stepIndexUpdate.stepIndex + 1)
//     })
//     const thankYouStep = {
//       ...stepIndexUpdate.steps[stepIndexUpdate.steps.length - 1],
//       subTitle: "You can finish later by using the “Complete Profile” button on the side bar.",
//       screen: profile.is_completed ? <ThankYou shoowCheckMark={false} showSubtitle={false} /> : <ThankYou shoowCheckMark={false} showSubtitle={true} />,
//       ...profile.is_completed ? { buttonTitle: "Finish" } : {}
//     }
//     //stepIndexUpdate.steps = ReplaceStep(stepIndexUpdate.steps, thankYouStep, stepIndexUpdate.steps.length - 1)
//     setWizardSteps(ReplaceStep(stepIndexUpdate.steps, thankYouStep, stepIndexUpdate.steps.length - 1))
//     if (stepIndex === 1) //const PreferencesIndex = surveySteps.findIndex(step => step.title === "Preferences" )      
//       setuserTypeStepCompleted(false)
//     setStepIndex(wizardSteps.length - 1)//SkipToLastStep(stepIndexUpdate)
//   }

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
//   //const isProfileForced = false
//   ///  const updatedEffect: (methods: any) => void = (methods) => { }

//   // if (!showSurveyState)
//   //   return null

//   if (isSurveyLoading)
//     return (<CircularProgressCentered />)

//   if (!isSurveySuccess)
//     return (<>Survey Error</>)


//   //let NeedToSeeDlg: boolean = !surveyData.totalStepCount
//   let NeedToSeeDlg: boolean = !surveyData.surveryCompleted //&& stepIndex > 0
//   // if (!NeedToSeeDlg) {
//   //   NeedToSeeDlg = profile.type && profile.type !== 'P' && surveyData.stepsCompleted < 2 && surveyData.stepsCompleted
//   // }
//   // if (!NeedToSeeDlg) {
//   //   NeedToSeeDlg = surveyData.totalStepCount && (surveyData.stepsCompleted < surveyData.totalStepCount) && profile.type && profile.type === 'P'
//   // }

//   if (!NeedToSeeDlg) {
//     NeedToSeeDlg = isForced
//   }

//   if (!NeedToSeeDlg) {
//     NeedToSeeDlg = stepIndex > 0
//   }
//   if (!NeedToSeeDlg) {
//     // if( !isSurveySuccess) 
//     //dispatch(showSurvey(undefined))
//     return null
//   }

//   // if (!isSurveySuccess)
//   //   return null
//   const surveyDataUpdatedWithProfile = {
//     ...surveyData, communicate_with_others: profile.communicate_with_others,
//     communicate_by_email: profile.communicate_by_email,
//     communicate_by_facebook: profile.communicate_by_facebook,
//     communicate_by_messenger: profile.communicate_by_messenger,
//     receive_emails: profile.receive_emails,
//     type: profile.type
//   }
//   return (

//     <WizardDialog
//       sx={{
//         width: isSmallScreen ? '100vw' : '40vw', // Set width to 100% on small screens, 90% on larger screens
//         maxWidth: isSmallScreen ? 'none' : '500px', // Remove maximum width on small screens, set to 400px on larger screens
//         height: isSmallScreen ? '85vh' : '85vh', // Set height to 100% on small screens, 70% on larger screens
//         maxHeight: isSmallScreen ? 'none' : '85vh', // Remove maximum height on small screens, set to 600px on larger screens
//         borderRadius: '12px', padding: '12px'
//       }}
//       isLoading={isSurveyLoading}
//       handleBack={handleBack}
//       steps={wizardSteps}
//       open={isSurveySuccess && NeedToSeeDlg}
//       onClose={onCloseSurvey}//() => dispatch(showSurvey(undefined))}
//       formData={surveyDataUpdatedWithProfile}//surveyData}//{surveyData.answers??{}}
//       updatedData = {isUpdated}
//       updateStep={UpdateStep}
//       stepIndex={stepIndex} />
//   )

// }
