import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
//import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch } from 'react-redux';
import { getSearchByCoordinates} from "../../features/searchSlice";
import Button from '@mui/material/Button';
import {  setCenter, setPublicPinClicked } from "../../features/dataProcessingSlice"
import { activateMarker } from '../../features/activeMarkers';
import PlaceIcon from '@mui/icons-material/Place';

export const CenterMapHome: React.FC<{ profileLat: number, profileLng: number, id: number }> = ({ profileLat, profileLng, id }) => {

  const dispatch = useDispatch<any>();

  const handleHomeClick = () => {
    //dispatch(getSearchByTerm(zipCode))
    dispatch(getSearchByCoordinates( { lat: profileLat, lng: profileLng }));
    //leocenter no need the below ceters probobly by showing the marker on the map  dispatch(setCenter({ lat: profileLat, lng: profileLng }))
    dispatch(activateMarker({ id: id, disableHover: true }))
    dispatch(setPublicPinClicked( id))
    //dispatch(getProfileFromID(id))//refactordispatch(getProfileFromID(iam_id))
  }

  return (
    
        <Button 
          disableElevation
          variant="contained" 
          style={{
            maxWidth: '40px', 
            maxHeight: '40px', 
            minWidth: '40px', 
            minHeight: '40px', 
            backgroundColor: "white"
          }} 
          onClick={handleHomeClick}
        > 
          <PlaceIcon sx={{color:'#656EFF'}} />
        </Button>
  );
}
