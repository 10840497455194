import {  createSlice } from '@reduxjs/toolkit';
export interface ActiveMarker {
  id: number | null,
  disableHover: boolean
}

const initialState : ActiveMarker = {  id: null, disableHover: false };

export const filterSlice = createSlice({
  name: 'activeMarkers',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    activateMarker: (state: { id: any; disableHover: any; }, action: { payload: { id: any; disableHover: any; }; }) => {
      state.id = action.payload.id;
      state.disableHover = action.payload.disableHover;
    },   
  },
  extraReducers: (builder: any) => {

  }

});

export const { activateMarker /*activateCareCenter, activateWarrior*/ } = filterSlice.actions;

export default filterSlice.reducer;
export const getActiveMarkers = (state: any) => {
   return state.activeMarkers
}