import React, { useState } from "react"
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { SignInSignUpUI } from "./Signin";
import { Stack } from "@mui/material";

export const AccountPromotion: React.FC = () => {
  return (
    <SignInSignUpUI isSignUp noLogo>
      <Stack direction="column" display="flex" alignItems="center" justifyContent="center"  >
        <Avatar sx={{ bgcolor: '#666eff' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography align='center' variant='h6' >Sign In</Typography>
      </Stack>
    </SignInSignUpUI>
  );
}