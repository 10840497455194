import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChatCount, useGetChatUserQuery } from '../../features/ChatSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { CircularProgressCentered } from '../../app/sharedcomponents';
import { useAuthProfile } from "../../app/hooks"
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Link, Paper } from '@mui/material';

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack';

import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';

import { useLocation } from 'react-router-dom';
import { NoChatsMessage } from './NoChatsMessage';
import { AppDispatch } from '../../app/store';
import { printError } from '../../app/utils';
import { SignInSignUpUI } from '../iam/Signin';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { App as SendbirdApp } from '@sendbird/uikit-react';
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import SBConversation, { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import SBChannelList from '@sendbird/uikit-react/GroupChannelList';
import SBChannelSettings from '@sendbird/uikit-react/ChannelSettings';
import withSendBird from '@sendbird/uikit-react/withSendbird';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import '@sendbird/uikit-react/dist/index.css';
import './sendbird.css'
import { closeDirectChat } from '../../features/dialogSlice';
import { themePolygon } from '../../app/theme'
import CloseIcon from '@mui/icons-material/Close';
import { GroupChannelHeaderProps } from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { ChatHeaderMM } from './ChatHeaderMM';
import { UnAuthorizedMessage } from './ChatMain';


export const chatColors = {
  '--sendbird-light-primary-500': '#491389',
  '--sendbird-light-primary-400': '#C3C6FF',
  '--sendbird-light-primary-300': '#656EFF',
  '--sendbird-light-primary-200': '#c2a9fa',
  '--sendbird-light-primary-100': '#dbd1ff',
  '--sendbird-light-secondary-500': '#066858',
  '--sendbird-light-secondary-400': '#027d69',
  '--sendbird-light-secondary-300': '#259c72',
  '--sendbird-light-secondary-200': '#69c085',
  '--sendbird-light-secondary-100': '#a8e2ab',
};


export interface SendbirdChatButtonProps { showChat: Boolean, appId: string, userId: string, channelUrl: string, accessToken: string }
export const SendbirdChatButton: React.FC = (props) => {
  const { showChat, appId, userId, channelUrl, accessToken } = useSelector((state: any) => state.dialog.showDirectChat)
  const APP_ID = '8FD8FACD-95C1-4A2B-815C-E1F54E964A85';
  const exampleChatUrl = 'sendbird_group_channel_274991173_4d01bdfb9cd7dd3dadd0e68236bce89171794db7'
  const patientConnectChatUrl = "sendbird_group_channel_274999460_384167696a9f95ae61f1c484a70683d61c5f0bdf"
  const otherPersonId = 'htht'
  const datumid = '20412'
  const dispatch = useDispatch();
  return (<SendbirdDirectChat showChat={showChat} appId={appId} userId={userId} channelUrl={channelUrl} accessToken={accessToken} onBackClick={() => dispatch(closeDirectChat())} />)
}

export interface SendbirdDirectChatProps extends SendbirdChatButtonProps { onBackClick: () => void }

export const SendbirdDirectChat: React.FC<SendbirdDirectChatProps> = ({ showChat, appId, userId, channelUrl, accessToken, onBackClick }) => {
  const { authStatus, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  return (
    <>{showChat &&
      <Box
        sx={{
          border: 5, borderColor: '#d3d3d3',  borderRadius: '16px',
          position: {
            xs: 'absolute',
            sm: 'absolute',
            md: 'fixed',
          },
          top: {
            xs: '20px',
            sm: '20px',
            md: '380px',
          },
          right: {
            xs: '10px',
            sm: '10px',
            md: '580px',
          },
          width: {
            xs: '95%',
            sm: '95%',
            md: '60%',
            lg: '30%',
          },
          height: {
            xs: '95%',
            sm: '95%',
            md: '60%',
            lg: '50vh',
          },
          zIndex: 300
        }}>


        <Box sx={{
          display: {

            xl: 'block'
          },
          height: '100%'

        }}
        >
          <Paper sx={{ height: '100%',  borderRadius: '16px', backgroundColor: 'rgba(200, 200, 200)' }}  >
            {authStatus === 'unauthenticated' ? <UnAuthorizedMessage /> :
              
                <GroupChannel channelUrl={channelUrl}
                  onBackClick={onBackClick}
                  renderChannelHeader={(props: GroupChannelHeaderProps) => {
                    return (
                      < ChatHeaderMM openSummary={() => { return }} />
                    )
                  }}
                />
              
            }
          </Paper>

        </Box>


      </Box>
    }</>)

}


export const SendbirdDirectChat1: React.FC<SendbirdDirectChatProps> = ({ showChat, appId, userId, channelUrl, accessToken, onBackClick }) => {
  const { authStatus, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  return (
    <>{showChat &&
      <Box
        sx={{
          border: 5, borderColor: '#d3d3d3',
          position: {
            xs: 'absolute',
            sm: 'absolute',
            md: 'fixed',
          },
          top: {
            xs: '20px',
            sm: '20px',
            md: '380px',
          },
          right: {
            xs: '10px',
            sm: '10px',
            md: '580px',
          },
          width: {
            xs: '95%',
            sm: '95%',
            md: '60%',
            lg: '30%',
          },
          height: {
            xs: '95%',
            sm: '95%',
            md: '60%',
            lg: '50vh',
          },
          zIndex: 300
        }}>


        <Box sx={{
          display: {

            xl: 'block'
          },
          height: '100%'

        }}
        >
          <Paper sx={{ height: '100%', backgroundColor: 'rgba(200, 200, 200)' }}  >
            {authStatus === 'unauthenticated' ? <UnAuthorizedMessage /> :
              <SendbirdProvider appId={appId} userId={userId.toString()} accessToken={accessToken} colorSet={chatColors} breakpoint='900px'>
                <GroupChannel channelUrl={channelUrl}
                  onBackClick={onBackClick}
                  renderChannelHeader={(props: GroupChannelHeaderProps) => {
                    return (
                      < ChatHeaderMM openSummary={() => { return }} />
                    )
                  }}
                />
              </SendbirdProvider>
            }
          </Paper>

        </Box>


      </Box>
    }</>)

}
