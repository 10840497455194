import React, { useState, useEffect, useRef } from "react"
import Divider from '@mui/material/Divider';
import { useAuthProfile } from "../../app/hooks"
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLazyGetSearchResultsQuery, useGetMiniSearchQuery, useLazyGetLatestNewsQuery } from '../../features/resourcesSlice'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Markdown from 'react-markdown'
import { Alert, Button, Box, Stack, Skeleton, ListItemButton, ListItem, ListItemText, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { StartChat } from "../markers/MMMarkers";
import { useCreateOrJoinGroupChatMutation } from '../../features/ChatSlice';
import { useNavigate } from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { cleanText, CopyToClipboardButton, MarkedDown, ResourceChatButton } from "./ResourceLanding";
// Clipboard component
// export const CopyToClipboardButton = ({text}) => {
//   const [open, setOpen] = useState(false);

//   let pathOrigin = window.location.pathname;

//   if (pathOrigin.at(-1)== "/") {
//     pathOrigin = pathOrigin.slice(0,-1)
//   }

//   const baseURL = window.location.origin + pathOrigin + "/resources?search=" + encodeURIComponent(text).replace("//","/");

//   const handleClick = () => {
//     setOpen(true);
//     navigator.clipboard.writeText(baseURL.toString());
//   };

//   return (
//     <>
//       <Button startIcon={<ContentCopyIcon fontSize="small"/>} onClick={handleClick} sx={{color:'#777777', fontSize:'11px', backgroundColor:'#F4F4F4'}}>
//           share this resource
//       </Button>
//       <Snackbar
//         message="Copied resource link to clipboard"
//         anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//         autoHideDuration={2000}
//         onClose={() => setOpen(false)}
//         open={open}
//       />
//     </>
//   );
// };

// export function cleanText(text) {
//   let validateText = text.replace(/[^a-zA-Z 0-9]+/g,'').trim()
//   return {cleanText:text, validateText:validateText}
// }


export const ResourceMini = () => {

  // Initialization
  const dispatch = useDispatch()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const [searchText, setSearchText] = useState({ cleanText: '', validateText: '' })
  //const { data:miniSearchResult, isLoading: miniSearchResultLoading } = useGetMiniSearchQuery()
  const [getLatestNews, { data: latestNewsResult, isLoading: latestNewsLoading, isSuccess: latestNewsSuccess, isError: latestNewsError }] = useLazyGetLatestNewsQuery()
  const [showMore, setShowMore] = useState(false);
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  }] = useCreateOrJoinGroupChatMutation()
  const navigate = useNavigate()


  // Event Handling
  // Handle search via URL paramters

  useEffect(() => {
    if (profile) {
      let query = 'Latest treatment developments, science and research on multiple myeloma as of '
      let currentDate = new Date()
      let day = currentDate.getDate();
      let month = currentDate.getMonth() + 1;
      let year = currentDate.getFullYear();
      let date = `${month}-${day}-${year}`;
      query = query + date.toString()
      getLatestNews({ query: query, profile_id: profile.id }, true)//.preferCacheValue?: boolean // { forceRefetch: true })
      setSearchText({ cleanText: query, validateText: query })
    }
  }, [profile])

  useEffect(() => {
    if (isSuccessSetChat) {//&& messagingReady) {
      console.log("setChatData", setChatData)
      // if( !messagingReady)
      //     dispatch(chatApi.util.invalidateTags(['Ready']))
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
    if (isErrorSetChat) {
      // Handle the error, and you can access originalArgs here
      console.log('Original Args:', originalArgs); // Logs { id: 1, name: 'John Doe' }            
    }
  }, [isSuccessSetChat, messagingReady])

  // Root function to handle dispatch of search
  function handleSubmit(e: any) {
    if (e)
      e.preventDefault();
    navigate("/resources?search=" + encodeURIComponent(searchText.cleanText))
  }


  const handleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  // Rendering
  if (profile) {
    return (

      <Stack direction="column" justifyContent="flex-start" gap={2} sx={{ width: '100%' }}>
        <Typography variant="h5" sx={{ margin: 'auto', fontWeight: 'bold' }}>Latest News</Typography>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            flexGrow: 2,
            width: '100%',
            backgroundColor: '#F4F4F8',
            borderRadius: 8,
            pl: 2,
            justifyContent: 'space-between'
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <InputBase
            name="search"
            disabled={authStatus != "authenticated"}
            sx={{ flexGrow: 10 }}
            placeholder="Ask any question"
            value={searchText.cleanText}
            inputProps={{ 'aria-label': 'search resources' }}
            onChange={(e) => {
              setSearchText(cleanText(e.target.value));
            }}
          />
          <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" >
            <ArrowCircleRightRoundedIcon sx={{ color: '#656EFF' }} />
          </IconButton>
        </Paper>
        {authStatus != 'authenticated' &&
          <>
            <Button variant="text" onClick={() => { navigate("/SignUp"/*'/sign-in'*/) }} sx={{ fontSize: '11px' }}>
              create a free account to use search
            </Button>
          </>
        }

        {latestNewsResult?.result
          ?
          <>
            <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
              <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
              <ResourceChatButton searchText={searchText} />
              </Stack>
              <CopyToClipboardButton text={searchText.cleanText} />
            </Stack>

            <Collapse in={showMore} collapsedSize={300} sx={{ borderBottom: 1, borderColor: '#F8F8F8' }}>
            <MarkedDown  searchText={searchText} searchResult={latestNewsResult} />
            
            </Collapse>
            <Stack direction="column" alignItems={'center'} gap={0}>
              {showMore
                ?
                <Button variant="text" endIcon={<ExpandLessIcon />} onClick={handleShowMore} sx={{ color: '#656EFF' }}>
                  View Less
                </Button>
                :
                <Button variant="text" endIcon={<ExpandMoreIcon />} onClick={handleShowMore} sx={{ color: '#656EFF' }}>
                  View More
                </Button>
              }
            </Stack>
          </>
          :
          <Skeleton animation="wave" variant="rectangular" width='100%' height={300} />
        }
      </Stack>

     )
  } else {
    return (
      <Skeleton animation="wave" variant="rectangular" width='100%' height={500} />
    )
  }

}