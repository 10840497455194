import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Chip, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';


export interface CareCenterInfo {
    center_name: string,
    cancer_center_type: string,
    designation: string,
    address: string,
    lat: number,
    lng: number,
    street: string,
    city: string,
    state: string,
    region: string,
    country: string,
    phone: string,
    website: string,
    id: number,
    distance: number,
    count_in_radius: number,
    count_total: number
}

export interface CareCenterProps {
    careCenterInfo: Partial<CareCenterInfo>;
}

export const CareCenterCard: FC<CareCenterProps> = ({ careCenterInfo: cardData }) => {
    const distanceInMiles = (cardData.distance ?? 0) / 1609.34;
    const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    }).format(distanceInMiles);
    return (
        <>
            <Card variant="outlined" sx={{
                mb: 1, height: '100%', display: 'flex'

            }} >
                <CardContent sx={{
                    width: "100%"
                }}>
                        <Tooltip title={cardData.center_name} arrow>
                        <Typography variant="body1">
                            <Link href={cardData.website} target="_blank" rel="noopener noreferrer">
                                {cardData.center_name}
                            </Link>
                        </Typography>
                        </Tooltip>
                        <Typography variant="body1" color="text.secondary">
                            {cardData.address}
                        </Typography>
                        <Grid 
                            container 
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Typography variant="overline" color="text.secondary">
                                {cardData.phone}
                            </Typography>
                            <Chip size="small" label={formattedNumber.toString() + ' miles'} color="success"
                                sx={{ backgroundColor: 'rgba(0, 0, 255, 0.5)' }}
                            />
                        </Grid>
                </CardContent>
            </Card>
        </>);
};

