import React /*, { ReactNode }*/ from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AISVG } from '../../assets/ai_star.svg';
import { showProfile } from '../../features/dialogSlice';

interface RecommendationsProps {
    AIRecommendations: any
}


export const Recommendations: React.FC<RecommendationsProps> = ({ AIRecommendations }) => {
    const dispatch = useDispatch<any>();
    function editStory() {
        dispatch(showProfile({ isForced: true }))
    }

    //console.log(AIRecommendations)
    if (Object.keys(AIRecommendations).length > 0) {
        return (
            <Box sx={{ border: 1, borderRadius: 3, borderColor: '#EDEEFF', backgroundColor: '#EDEEFF', p: 2, mt: 2 }}>
                <Stack direction="column" alignItems="left" gap={1}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <AISVG style={{ height: 50, width: 50 }} />
                        <Typography variant="subtitle1" sx={{ pb: 1, fontWeight: 'bold' }}>
                            Would you like to share more about your story to gain more insights?
                        </Typography>
                    </Stack>
                    <Typography variant="body1" sx={{ pb: 1, fontWeight: 'bold' }}>
                        Here's what other members have shared:
                    </Typography>
                    {Object.keys(AIRecommendations).filter(key => AIRecommendations[key].count > 0).map((key, index) => (
                        <Typography key={key} variant="body2">
                            {"• " + AIRecommendations[key].count + ` member${AIRecommendations[key].count > 1 ? "s" : ""} shared their  ${AIRecommendations[key].formattedVariable}`}
                        </Typography>
                    ))}
                </Stack>
                <Button onClick={editStory} variant="text" sx={{ fontWeight: 'bold', color: '#656EFF', mt: 2 }}>Edit My Story</Button>
            </Box>
        );
    } else {
        return null
    }
}