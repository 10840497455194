import { /*FetchBaseQueryError,*/ createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'



export const utilityApi = createApi({
    reducerPath: 'utilityApi',
    baseQuery: fetchBaseQuery(),
    tagTypes: ['WebPageMetaData', 'YoutubeMetaData'],
    endpoints: (builder) => ({
        getWebPageMetaData: builder.query<any, string>({
            query: (url) => {
                const urlEncoded = encodeURIComponent(url);
                const qry = `/api/get/webpagemetadata/?url=${urlEncoded}`
                return qry
            },
            providesTags: (result, response, updated) => {
                return [{ type: 'WebPageMetaData', id: updated }]
            }
        }),
        getYoutubeMetaData: builder.query<any, string>({
            query: (url) => {
                const urlEncoded = encodeURIComponent(url);
                const qry = `/api/get/youtubemetadata/?url=${urlEncoded}`
                return qry
            },
            providesTags: (result, response, updated) => {
                return [{ type: 'YoutubeMetaData', id: updated }]
            }
        }),
    })
})

export const { useGetWebPageMetaDataQuery, useGetYoutubeMetaDataQuery  } = utilityApi
