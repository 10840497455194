import { Box,  Typography, Link, CircularProgress } from '@mui/material'
import React from 'react'
import Logo from '../assets/logo.png'
import Grid from '@mui/material/Grid';
//import resourceData from './resources/ResourceData'
import { useNavigate } from 'react-router-dom';

export const PrivacyLink: React.ReactElement = (
    <Link href="https://www.polygonhealth.com/privacy-policy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </Link>
);
export const TermsOfUseLink: React.ReactElement = (
    <Link href="https://www.polygonhealth.com/termsofuse" target="_blank" rel="noopener noreferrer">
      Terms of Use
    </Link>
);

export const HeaderContent: React.FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <Box 
        sx={{ 
          flexGrow:50,
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
        }}
      >
        <Box 
          component="img"
          sx={{ 
            height: 64,
            cursor:'pointer'
          }}
          onClick={() => {window.open("https://www.polygonhealth.app", '_blank')}}
          alt="Powered by Polygon Health"
          src={Logo}
        />
        <Grid>
          <Grid  key={0} item xs={12}>
            <Typography
              variant="h6"
              noWrap
              component="pre"
              sx={{
                letterSpacing: '0rem',
                color: 'black',
                textDecoration: 'none',
                fontWeight: 'bold',
                fontSize: {
                  xs:'10px',
                  sm: '1.3rem'
                },
                pt: {
                  xs: 1.5,
                  sm: 0.5
                }
              }}
            >
              Multiple Myeloma Patient Connect
            </Typography>
          </Grid>
          <Grid  key={1} item xs={12}>
            <Typography
              variant="caption"
              noWrap
              component="pre"
              sx={{
                color: 'black',
                textDecoration: 'none',
              }}
            >
              Powered by Polygon Health
            </Typography>
          </Grid>
        </Grid>
      </Box>

    </>)
}

export const CircularProgressCentered = () => { return (
  <div style={{ position: 'absolute', 
  // padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', 
  top: '50%',
  left: '50%',
  //backgroundColor: 'grey'
   }}>
    <CircularProgress />
  </div>)}