import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual, } from "react-redux";
import { InfoWindowF, MarkerF } from '@react-google-maps/api';

//import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
//import Typography from '@mui/material/Typography';

// import patientIcon from '../../assets/patienticon.svg';
// import caregiverIcon from '../../assets/caregiver.svg';
// import medicIcon from '../../assets/medic.svg';
// import { ReactComponent as carecenterIcon } from '../../assets/carecentericon.svg';
// import { ReactComponent as housingIcon } from '../../assets/house.svg';
import carecenterIcon from '../../assets/carecentericon.png';
import housingIcon from '../../assets/house.png';
import coeIcon from '../../assets/coe.png';
import supportIcon from '../../assets/support_group.png';
import { activateMarker } from "../../features/activeMarkers";// activateCareCenter, activateWarrior /*} from '../features/activeMarkers'
//import { setPublicPinClicked, getProfileFromID, getAIProfileSummary } from "../../features/dataProcessingSlice"
//import { useEffect } from "react";
//import { GetUserTypePinSVG1 } from '../MessageProfile';
import { CoeInfoWindowDetails, HousingInforWindowDetails, SupportGroupInfoWindowDetails } from "./InfoWindowDetails";
import { useGeInstitutionsQuery } from "../../features/institutionsSlice";
import { CircularProgressCentered } from "../../app/sharedcomponents";
import { customIcon } from "./MMMarkers";


export const InstitutionTypes = ['E', 'S','H']
export const GetIntitutionTypePinSVG: (markerType: string) => string = (markerType) => markerType === 'H' ? housingIcon : markerType === 'E' ? coeIcon : markerType === 'C' ? carecenterIcon : markerType === 'S' ? supportIcon : carecenterIcon 

export const ShowMarkerType: (pinType: string, institutionTypesState: any, unknownIsGood: boolean) => boolean = (pinType, institutionTypesState, unknownIsGood) =>
{
 const ret = typeof institutionTypesState === 'boolean' ? institutionTypesState : (pinType in institutionTypesState ? institutionTypesState[pinType] : unknownIsGood)
 return ret
}
export const InstitutionMarkers: React.FC<any> = ({zoom}) => {
  const institutionTypesState = useSelector((state: any) => state.filter.showCareCenters, shallowEqual)
  const unknownIsGood = useSelector((state: any) => state.filter.unknownIsGood)
  const { data: institutions, isLoading, isSuccess, isError, error, } = useGeInstitutionsQuery()

  if (isLoading)
    return (<CircularProgressCentered />)
  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return <div>{errMsg}</div>;
  }
  const showAllTypes = typeof institutionTypesState === 'boolean' ? institutionTypesState : undefined
  if (!showAllTypes && showAllTypes !== undefined)
    return null

  //const pinType = row.data.type
  //const showMarkerType = typeof institutionTypesState === 'boolean' ? institutionTypesState : (pinType in institutionTypesState ? institutionTypesState[pinType] : unknownIsGood)

  return (<>
    {institutions?.map(
      (row: any) => ( ShowMarkerType(row.type, institutionTypesState, unknownIsGood) && <InstitutionMarker zoom={zoom} id={-row.id} data={row} key={-row.id} /> )
    )
    }
  </>
  )
}


export const InstitutionMarker: React.FC<any> = ({ id, data, zoom }) => {//{ markerType, lat, lng, name, isInfoWindowOpen = false, animation = null, description = undefined, address = undefined, phone = undefined, href = undefined, id })  =>{
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const isMarkerActive = useSelector(
    (state: any) => {
      if (state.activeMarkers?.id === id) {
        return true
      }
      return false
    }

  );

  const dispatch = useDispatch()
  const markerType = data.type

  const institutionTypePinSVG = GetIntitutionTypePinSVG(markerType)//markerType === 'H' ? housingIcon : markerType === 'E' ? coeIcon : markerType === 'carecenter' ? carecenterIcon : carecenterIcon

  function handleMouseOver() {
    if (isMarkerActive) {//activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(true);
    //activateCallBack(id)
  };

  function handleMouseOut() {
    if (isMarkerActive) {//activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(false);
  };

  function handleWindowClose() {
    setInfoWindowOpen(false);
    dispatch(activateMarker({ id: null, disableHover: false }))
  };

  function onLoad(marker: any) {
    window.google.maps.event.addListener(marker, "click", function (event: any) {
      dispatch(activateMarker({ id: id, disableHover: true }))
      setInfoWindowOpen(false);
    });
  }

  return (
    <MarkerF
      key={`marker-${data.id}-${zoom}`}
      onLoad={onLoad}//onClick={() => handleMarkerClick()}
      position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }}
      icon={customIcon(institutionTypePinSVG as unknown as string, zoom)}
      onMouseOver={() => handleMouseOver()}
      onMouseOut={() => handleMouseOut()}
    >
      {
        (isMarkerActive || infoWindowOpen) && (
          <InfoWindowF onCloseClick={() => handleWindowClose()}
          position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }}
            options={{
              disableAutoPan: true,
            }}
          >
            <Box>
              {(markerType === 'E') && <CoeInfoWindowDetails data={data} />}
              {(markerType === 'H') && <HousingInforWindowDetails data={data} />}
              {(markerType === 'C') && <CoeInfoWindowDetails data={data} />}
              {(markerType === 'S') && <SupportGroupInfoWindowDetails data={data} />}
            </Box>
          </InfoWindowF>
        )}
    </MarkerF>
  );

}




