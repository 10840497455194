import React, { useState } from "react"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as CheckMark } from '../../assets/checkmark.svg'

//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useStep } from "../wizard/QuestionsForm";


export const ThankYou: React.FC<{ showSubtitle?: boolean, shoowCheckMark?: boolean }> = ({ shoowCheckMark = true, showSubtitle = true }) => {
    const { step, data } = useStep()
    return (
        <div style={{  display:'flex', height: '100%', flexDirection: 'column' }}>

            {shoowCheckMark && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', mb: '15px', mt: '0px' }}>
                <CheckMark height={"18%"} width='18%' />
            </Box>}
            <Typography variant="h6" sx={{ textAlign: 'center', color: '#656EFF', }}>
                {step?.subTitle}
            </Typography>

            {showSubtitle && <Typography variant="body1" sx={{ mb: '16px', mt: 1 }}>
                {step?.subTitle2}
            </Typography>
            }

        </div>

    );
}