import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
//import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
//import StepProgressBar from './StepProgressBar';


interface ProfileDialogTitleProps {
    onBack: () => void;
    onClose: () => void;
    children: React.ReactNode;
    color?: string
    stepIndex: number
    noBackArrow?: boolean
}

export const ProfileDialogTitle: React.FC<ProfileDialogTitleProps> = ({ onBack, onClose, children , color, stepIndex, noBackArrow}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color:color, width: '100%' }}>
            <IconButton edge="start" color="inherit" onClick={onBack} aria-label="back">
            {(stepIndex > 0 && !noBackArrow) &&<ArrowBackIcon />}
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' , fontWeight: "bold"}}>
                {children}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                <CloseIcon />
            </IconButton>
        </Box>
    );
}

