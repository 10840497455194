import React, { useState, useEffect, useRef, ReactNode } from "react"
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
//import Button from '@mui/material/Button';
//import Stack from '@mui/material/Stack';
import { useAuthProfile } from "../../app/hooks"
//import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { useLazyGetSearchResultsQuery, useGetFAQsQuery, useGetRecentSearchesQuery, useLazyGetSearchAutocompleteQuery } from '../../features/resourcesSlice'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Markdown from 'react-markdown'

import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Alert, Button, Box, Stack, Skeleton, ListItemButton, ListItem, ListItemText, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
//import Badge from '@mui/material/Badge';
//import PersonIcon from '@mui/icons-material/Person';
import { debounce } from '@mui/material/utils';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { StartChat } from "../markers/MMMarkers";
import { useCreateOrJoinGroupChatMutation } from '../../features/ChatSlice'
import { useNavigate } from "react-router-dom";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { MarkdownEmbeddedImageRenderer } from '../../app/blob-url-from-Base64'
import { Padding } from "@mui/icons-material";


export function cleanText(text: string) {
  let validateText = text.replace(/[^a-zA-Z 0-9]+/g, '').trim()

  return { cleanText: text, validateText: validateText }
}

const ResourceTab: React.FC<TabProps> = (props) => (
  <Tab
    disableRipple
    sx={{
      textTransform: 'none',
      fontWeight: 'bold',
      borderRadius: 4,
      marginRight: 1,
      marginLeft: 1,
      color: '#777777',
      backgroundColor: '#FAFAFA',
      '&:hover': {
        color: '#40a9ff',
      },
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#656EFF',
      },
    }}
    {...props}
  />
);

interface SearchText { cleanText: string, validateText: string }

export const ResourceLanding = () => {
  let root = document.getElementById('root');
  root?.style.setProperty('position', 'static', 'important');
  root?.style.setProperty('width', '100%', 'important')

  // Initialization
  const dispatch = useDispatch()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const { data: faqResult, isLoading: faqLoading } = useGetFAQsQuery()
  const { data: recentSearchResult, isLoading: recentSearchResultLoading } = useGetRecentSearchesQuery()
  const [searchText, setSearchText] = useState<SearchText>({ cleanText: '', validateText: '' })
  const [resultText, setResultText] = useState('')
  const [getSearch, { data: searchResult, isLoading: searchLoading, isSuccess: searchSuccess, isError: searchError, isUninitialized: isSearchUninitialized }] = useLazyGetSearchResultsQuery()
  const [getAutocomplete, { data: autocompleteResult, isLoading: autocompleteLoading, isSuccess: autocompleteSuccess, isError: autocompleteError }] = useLazyGetSearchAutocompleteQuery()
  const [loading, setLoading] = useState(false)
  const [openResultPage, setOpenResultPage] = useState(false)
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [faqTab, setFaqTab] = React.useState(0);
  const [searchURL, setSearchURL] = useSearchParams();
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const hasSearchedFromURL = useRef(false);
  const navigate = useNavigate()

  // Event Handling
  // Handle search via URL paramters
  useEffect(() => {
    if (searchURL.size > 0 && !hasSearchedFromURL.current) {
      const searchQuery = cleanText(searchURL.get("search") ?? '');
      if (searchQuery.validateText.length > 0) {
        setSearchText(searchQuery)
      }
    }
  }, [searchURL])

  // Main useeffect trigger to trigger a search
  useEffect(() => {
    if (searchText.validateText.length === 0) {
      return
    }

    if (triggerSearch) {
      handleSubmit(undefined)
    }

    // for URL parameters, which skips over the triggerSearch state
    if (searchURL.size > 0 && !hasSearchedFromURL.current) {
      handleSubmit(undefined)
      hasSearchedFromURL.current = true;
    }

    // Always reset trigger search after search is triggered
    setTriggerSearch(false)
  }, [searchText, handleSubmit, searchURL.size, hasSearchedFromURL.current, setTriggerSearch])

  // Handle faq tab change
  const handleChange = (event: any, newTab: number) => {
    setFaqTab(newTab);
  };



  // Root function to handle dispatch of search
  function handleSubmit(e: any) {
    if (e)
      e.preventDefault();

    if (searchText.validateText.length === 0 || isSuccess === false) {
      return
    }
    setLoading(true)
    setOpenResultPage(true)
    setSearchURL({ search: searchText.cleanText })

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    getSearch({ query: searchText.cleanText, profile_id: profile.id }, true)// preferCacheValue: false // { forceRefetch: true })
    setResultText(searchText.cleanText)
  }



  // manage debounce logic
  const debouncedSearch = React.useRef(
    debounce(async (text: string) => {
      getAutocomplete(text);
    }, 300)
  ).current;

  // Cancels debounce
  React.useEffect(() => {
    return () => {
      //debouncedSearch.cancel();
      debouncedSearch.clear(); // using MUI's debounce
    };
  }, [debouncedSearch]);



  // For the FAQ categories
  // const ResourceTab = styled((props) => <Tab disableRipple sx={{fontSize: {xs:'0.7rem',sm:'.8rem',md:'1rem'}}} {...props} />)(
  // ({ theme }) => ({
  //     textTransform: 'none',
  //     fontWeight: 'bold',
  //     borderRadius: 4,
  //     marginRight: theme.spacing(1),
  //     marginLeft: theme.spacing(1),
  //     color: '#777777',
  //     backgroundColor:'#FAFAFA',
  //     '&:hover': {
  //     color: '#40a9ff',
  //     },
  //     '&.Mui-selected': {
  //         color: 'white',
  //         backgroundColor: '#656EFF',
  //     },
  // }));

  // const ChatButton : React.FC<profile: any> = ({profile}) =>{
  //   return( <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} loadingPosition="start" id="resource_query_chat" loading={isLoadingSetChat}
  //     disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
  //       const ids = [profile.id, 1]
  //       StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: searchText.cleanText ?? '', ids: [profile.id, 1] }), true)
  //     }}>
  //     Join discussion
  //   </LoadingButton>)
  // }

  // Handle FAQ search
  function handleFAQ(e: any, text: string) {
    document.getElementById('resource_top')?.scrollIntoView({ behavior: 'smooth' })
    if (!loading) {
      setSearchText(cleanText(text))
      setTriggerSearch(true)
      setOpenResultPage(true)
      setShowAutocomplete(false)
    }
  }

  //console.log(searchResult?.result )
  // Rendering
  return (
    <Container>
      <div id="resource_top"></div>
      <Stack sx={{ pt: 8, pb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', }} gap={2} >
        <Typography variant="h5">Knowledge Connect</Typography>
        {authStatus !== 'authenticated' &&
          <>
            <Link sx={{ cursor: 'pointer' }} variant="body1" onClick={() => navigate("/Sign-In")}>
              Sign in or create a free account to use search
            </Link>
            <Typography color="primary" variant="body1">
              Multiple Myeloma Patient Guide below is available to everyone
            </Typography>
          </>
        }

        <Paper
          elevation={0}
          sx={{ display: 'flex', flexGrow: 2, width: '100%', backgroundColor: '#F4F4F8', borderRadius: 8, pl: 2, justifyContent: 'space-between' }}
          component="form"
          onSubmit={handleSubmit}
        >
          {((searchResult && searchResult?.isFutureDate !== true) || isSearchUninitialized) ? <>
            <InputBase name="search" sx={{ flexGrow: 10 }} disabled={loading || authStatus !== "authenticated"}
              placeholder="Ask any question"
              value={searchText.cleanText}
              inputProps={{ autoComplete: 'off' }}
              onChange={(e) => {
                if (e.target.value.replace(/[^a-zA-Z 0-9]+/g, '').trim().length > 0) {
                  setShowAutocomplete(true)
                  debouncedSearch(e.target.value)
                } else {
                  setShowAutocomplete(false)
                }
                setSearchText(cleanText(e.target.value));
              }}
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" >
              <ArrowCircleRightRoundedIcon sx={{ color: '#656EFF' }} />
            </IconButton>
          </> : <Typography variant="subtitle1" sx={{ padding: '10px', fontWeight: 'bold', pt: 1, color: '#62648F' }}>{searchText.cleanText}</Typography>}
        </Paper>
        {(autocompleteResult && showAutocomplete && autocompleteResult?.length > 0) &&
          <Box sx={{ width: '100%', backgroundColor: 'white', border: 1, borderColor: '#E0E0E0', maxHeight: '225px', overflow: 'auto' }}>
            <List>
              {autocompleteResult.map((row: any) => (
                <ListItem disablePadding key={row.search_term} >
                  <ListItemButton onClick={(e) => { handleFAQ(e, row.search_term) }} >
                    <ListItemText
                      primary={row.search_term}
                      primaryTypographyProps={{ fontSize: { xs: '0.75rem', sm: '0.95rem', md: '1rem' } }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        }
        {openResultPage ? <>
          {searchResult &&
            <Paper sx={{ width: '100%' }}>
              <Stack direction="column"  alignItems={"flex-start"} gap={1} sx={{ p: 1 }}>
            
                  <Button startIcon={<ArrowBackIcon />} onClick={() => { setOpenResultPage(false) }}>
                    Back
                  </Button>
                
                <PerplexitySearchResults  searchText={searchText} searchResult={{...searchResult}} />
              </Stack>
            </Paper>
          }</> :
          <PatientGuide handleFAQ={handleFAQ} />}
      </Stack>
    </Container>
  )
}


// Component helpers
const ResourceTabs = styled(Tabs)({
  borderBottom: 0,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});
interface ResourceTabProps {
  children: ReactNode; // Define children as ReactNode
  // Include any other props you might need here
}


export const PatientGuide: React.FC<{ handleFAQ: (e: any, text: string) => void }> = ({ handleFAQ }) => {
  const { data: faqResult, isLoading: faqLoading } = useGetFAQsQuery()
  const [faqTab, setFaqTab] = React.useState(0);
  return (<>
    {(!faqLoading) &&
      <>
        <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>Multiple Myeloma Patient Guide</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1, maxWidth: '100%', border: 1, borderColor: '#E0E0E0' }}>
          <ResourceTabs
            value={faqTab}
            variant="scrollable"
            onChange={(event: any, newTab: number) => setFaqTab(newTab)}
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {faqResult.map((row: any) => (
              <ResourceTab label={row.category} key={row.category} />
            ))}
          </ResourceTabs>
        </Box>
        <List sx={{ width: '100%', border: 1, borderColor: '#E0E0E0' }}>
          {faqResult[faqTab]['questions'].map((row: any) => (

            <ListItem disablePadding key={"item_" + faqTab.toString() + "_" + row}>
              <Stack direction="column" sx={{ width: '100%' }}>
                <ListItemButton key={"button_" + faqTab.toString() + "_" + row} onClick={(e) => { handleFAQ(e, row) }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ListItemText
                    primary={row}
                    key={"text_" + faqTab.toString() + "_" + row}
                    primaryTypographyProps={{ fontSize: { xs: '0.75rem', sm: '0.85rem', md: '1rem' } }}
                  />
                  <ArrowOutwardIcon sx={{ color: '#777777', fontSize: 'medium' }} />
                </ListItemButton>
                <Divider sx={{ width: '99%', margin: 'auto' }} key={faqTab.toString() + "_" + row} />
              </Stack>
            </ListItem>

          ))}
        </List>
      </>
    }
    {(faqLoading) &&
      <>
        <Skeleton animation="wave" variant="rectangular" width='50%' height={60} sx={{ border: 1, borderRadius: 8, borderColor: '#E0E0E0' }} />
        <Skeleton animation="wave" variant="rectangular" width='100%' height={400} />
      </>
    }
  </>)
}

export const PerplexitySearchResults: React.FC<{ searchText: SearchText,  searchResult: any }> = ({ searchText, searchResult }) => {
  //const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  //const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  //}] = useCreateOrJoinGroupChatMutation()
  //console.log(searchResult?.result )
  return (<>
    {searchResult.result
      ?
      <>

        {searchResult.valid &&
          <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
            <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
              <ResourceChatButton searchText={searchText} />
            </Stack>
            <CopyToClipboardButton text={searchText.cleanText} />
          </Stack>
        }
        {(searchResult && searchResult?.isFutureDate !== true) && <Typography variant="subtitle1" sx={{ fontWeight: 'bold', pt: 1, color: '#62648F' }}>{searchText.cleanText.toUpperCase()}</Typography>}
        <MarkedDown searchText={searchText} searchResult={searchResult} />

      </>
      :
      <Stack direction="column" gap={1}>
        <Skeleton animation="wave" variant="rectangular" width='150px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='200px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='100%' height={200} />
      </Stack>
    }

  </>)
}


export const ResourceChatButton: React.FC<{ searchText: SearchText }> = ({ searchText }) => {
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  }] = useCreateOrJoinGroupChatMutation()

  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccessSetChat && setChatData) {//&& messagingReady) {
      // if( !messagingReady)
      //     dispatch(chatApi.util.invalidateTags(['Ready']))
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
    // if (isErrorSetChat) {
    //     // Handle the error, and you can access originalArgs here           
    //   }
  }, [isSuccessSetChat, messagingReady, navigate, setChatData])
  return (<>
    <Stack direction="row">
      <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} loadingPosition="start" id="resource_query_chat" loading={isLoadingSetChat}
        disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
          const ids = [profile.id, 1]
          StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: searchText.cleanText ?? '', ids: [profile.id, 1] }), true)
        }}>
        Join discussion
      </LoadingButton>
    </Stack>

    {(isErrorSetChat) && <Alert severity="error">Oops... Error occurred while  initiating a chat.  Please try again later.</Alert>}

  </>)
}




export const MarkedDown: React.FC<{ searchText: SearchText, searchResult: any }> = ({ searchText, searchResult }) => {
  return (
    <>
      <MarkdownEmbeddedImageRenderer markdownContent={searchResult.result} encodedImages ={searchResult.encodedImages} />
      {
        searchResult.sources?.length > 0 &&
        <>
          <Typography variant="h6">Additional Sources:</Typography>
          <ul>
            {searchResult.sources.map((row: any) => (
              <li key={row.link}><a href={row.link} target="_blank">{row.title}</a></li>
            ))}
          </ul>
          {searchResult.valid &&
            <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
              <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
                <ResourceChatButton searchText={searchText} />
              </Stack>
              <CopyToClipboardButton text={searchText.cleanText} />
            </Stack>
          }
        </>
      }
    </>)
}

// Clipboard component
export const CopyToClipboardButton: React.FC<{ text: string }> = ({ text }) => {
  const [open, setOpen] = useState(false);

  //const baseURL = window.location.origin + window.location.pathname + "?search=" + encodeURIComponent(text);
  const baseURL = window.location.origin + "/map/Resources" + "?search=" + encodeURIComponent(text);


  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(baseURL.toString());
  };

  return (
    <>
      <Button startIcon={<ContentCopyIcon fontSize="small" />} onClick={handleClick} sx={{ color: '#777777', fontSize: '11px', backgroundColor: '#F4F4F4' }}>
        Share this resource
      </Button>
      <Snackbar
        message="Copied resource link to clipboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};


// const ChatButton : React.FC<profile: any> = ({profile}) =>{
//   return( <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} loadingPosition="start" id="resource_query_chat" loading={isLoadingSetChat}
//     disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
//       const ids = [profile.id, 1]
//       StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: searchText.cleanText ?? '', ids: [profile.id, 1] }), true)
//     }}>
//     Join discussion
//   </LoadingButton>)
// }

