import { createSlice, PayloadAction } from '@reduxjs/toolkit' 
import { RootState } from '../app/store';
//import axios from 'axios';

interface FilterState {
  showCareCenters: any;
  showWarriors: any;
  unknownIsGood: boolean
}

interface TypeSelection { type: string, isVisible: boolean }
// Define action types

// interface ShowCareCentersAction extends Action {
//   type: 'filter/showCareCenters';
//   payload:  TypeSelection | boolean;
// }


const initialState: FilterState = {
  showCareCenters: { E: true, S: true, C: false, H: false },
  showWarriors: { P: true, C: true, M: true },  //P for patients or warriors
  unknownIsGood: true
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showCareCenters: ( state, action: PayloadAction< TypeSelection | boolean>) => {
      //state.showCareCenters = action.payload;
      if (typeof action.payload === 'boolean') {
        state.showCareCenters = action.payload;
        state.unknownIsGood = action.payload 
        return
      }
      // if (typeof state.showCareCenters === 'boolean') {
      //   state.showCareCenters = {}
      // }
      //state.showCareCenters[action.payload.type] = action.payload.isVisible
      state.showCareCenters ={...state.showCareCenters, [action.payload.type]: action.payload.isVisible}
    },
    showWarriors: (state, action) => {
      if (typeof action.payload === 'boolean') {
        state.showWarriors = action.payload;
        state.unknownIsGood = action.payload 
        return
      }
      // if (typeof state.showWarriors === 'boolean') {        
      //   state.showWarriors = {}
      // }
      //state.showWarriors[action.payload.type] = action.payload.isVisible
      state.showWarriors = { ...state.showWarriors, [action.payload.type]: action.payload.isVisible}
    },
  },
  extraReducers: (builder) => {

  }

});

export const { showCareCenters, showWarriors } = filterSlice.actions;
export const filterSelector = (state: RootState) => state.filter;
export default filterSlice.reducer;
