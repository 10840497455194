import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  clickedContext: '',
};


export const clickContextSlice = createSlice({
  name: 'clickcontext',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clicked: (state, action) => {
      console.log(action.payload);
      state.clickedContext = action.payload;
    },
  },
  extraReducers: (builder) => {
    // global

  }

});

export const {
    clicked
} = clickContextSlice.actions;


export default clickContextSlice.reducer;
