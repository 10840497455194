import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { aiAnalyticsApi } from './aiAnalytics';
import { AsyncThunk, Slice, PayloadAction } from '@reduxjs/toolkit';
import { Point } from '../components/carecenter/Centers';
//////////////////////////

export type LeftSideComponentType = 'Profile' | 'AnalyticsChannelSummary' | 'ChatUserNotFound';
export interface LeftSideComponentWithOptions {
    component: LeftSideComponentType, options?: AiProfileOptions 
}
export type AnalyticType = "numeric" | "text";

export interface AnalyticsRecord { analyticsArrayIndex: number, analyticType: AnalyticType, analyticsVariable: string }

export type AnalyticUsersClickedType = boolean | AnalyticsRecord



export type PublicPinClicked = number | boolean 
export type PublicPinClickedAction = PublicPinClicked | { id: number, aiProfileOptions: AiProfileOptions }
export interface InitialDataProcessingState {
  publicPinClicked: PublicPinClicked;
  //aiProfileOptions: setAiProfileOptionsPayload,
  current_center: Point,
  //publicPinProfile: any | null;
  //AIProfileSummary: any | null;
  //AIProfileMessageSummary: any | null;
  //AIRecommendations: (any | null)[];
  //isErrorGenerateAISummary: boolean,
  //isSuccessGenerateAISummary: boolean,
  //errorGenerateAISummary: string | null,
  //loading: boolean;
  //loadingMessageSummary: boolean;
  //isLoadingProfile: boolean
  //isSuccessProfile: boolean
  //isErrorProfile: boolean
  analyticUsersClicked: AnalyticUsersClickedType,
  //leftSideComponentType: LeftSideComponentType
  leftSideComponentWithOptions: LeftSideComponentWithOptions
}



interface GenerateAISummaryPayload {
  story: string;
  //iam_id: string;
  id: number
}

interface ProfileFromIDPayload {
  //iam_id: string;
  id: number
}

interface AIProfileSummaryPayload {
  //iam_id: string;
  id: number
}

interface AIProfileMessageSummaryPayload {
  //iam_id: string;
  id: number
}

interface AiProfileOptions { showMessageButton: boolean, showViewUserStoryAnalytics: boolean, showAccountPromotion: boolean }

//////////////////////////



//import axios from 'axios';
//import {isStringEmptyOrWhitespace} from "../app/utils"


const initialState: InitialDataProcessingState = {
  publicPinClicked: false,
  //aiProfileOptions: { showMessageButton: true, showViewUserStoryAnalytics: true, showAccountPromotion: true },
  //leftSideComponentType: 'Profile',
  analyticUsersClicked: false,
  //publicPinProfile: false,
  //AIProfileSummary: null,
  //AIProfileMessageSummary: null,
  //AIRecommendations: [],
  //isErrorGenerateAISummary: false,
  //isSuccessGenerateAISummary: false,
  //errorGenerateAISummary: null,
  //selectedAnalyticUsers: [],
  //loading: false,
  //loadingMessageSummary: false,
  current_center: { lat: 35.0000, lng: -92.0000 }, //arkansas coordinates not in degrees
  // isLoadingProfile: false,
  // isSuccessProfile: false,
  // isErrorProfile: false,
  //clickedMessageIAM: null,
  leftSideComponentWithOptions: { component: 'Profile',options:{ showMessageButton: true, showViewUserStoryAnalytics: true, showAccountPromotion: true }}

};


export const generateAISummaryThunk = createAsyncThunk("processing/generateAISummary", async (data: any, thunkAPI) => {
  try {
    await thunkAPI.dispatch(aiAnalyticsApi.endpoints.generateAISummary.initiate(data))//.unwrap();
    //dispatch(setSuccess());
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const GetIdFromPublicPinClicked: (publicPinClicked: PublicPinClicked) => number | undefined = (publicPinClicked) =>{
  switch (typeof publicPinClicked) {
    case "number":
      return publicPinClicked
    case "boolean":
    return undefined
      break
    default:
      //return publicPinClicked.id
      return undefined
  }

}

export const dataProcessingSlice = createSlice({
  name: 'dataProcessing',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLeftSideComponentWithOptions:  (state :any, action: PayloadAction<LeftSideComponentWithOptions>) => {
      state.leftSideComponentWithOptions = action.payload
    },
    setAiProfileOptions: (state :any, action: PayloadAction<AiProfileOptions>) => {
      if (!action.payload) {
        state.leftSideComponentWithOptions.options.showMessageButton = true
        state.leftSideComponentWithOptions.options.showViewUserStoryAnalytics = true
        state.leftSideComponentWithOptions.options.showAccountPromotion = true
        return
      }
      state.leftSideComponentWithOptions.options.showMessageButton = action.payload.showMessageButton
      state.leftSideComponentWithOptions.options.showViewUserStoryAnalytics = action.payload.showViewUserStoryAnalytics
      state.leftSideComponentWithOptions.options.showAccountPromotion = action.payload.showAccountPromotion
    },
    setPublicPinClicked: (state :any, action: PayloadAction<PublicPinClickedAction >) => {
      if (!action.payload) {
        console.log("setPublicPinClicked Payload is falsy")
      }
      switch (typeof action.payload) {
        case "number":
        case "boolean":
          state.publicPinClicked = action.payload;
          state.leftSideComponentWithOptions.component = 'Profile'
          state.leftSideComponentWithOptions.options.showMessageButton = true
          state.leftSideComponentWithOptions.options.showViewUserStoryAnalytics = true
          state.leftSideComponentWithOptions.options.showAccountPromotion = true
          state.analyticUsersClicked = false;
          //leostate.usersFromAnalyticsClicked = [];
          break
        default:
          state.publicPinClicked = action.payload.id;
          state.leftSideComponentWithOptions.options.showMessageButton = action.payload.aiProfileOptions.showMessageButton
          state.leftSideComponentWithOptions.options.showViewUserStoryAnalytics = action.payload.aiProfileOptions.showViewUserStoryAnalytics
          state.leftSideComponentWithOptions.options.showAccountPromotion = action.payload.aiProfileOptions.showAccountPromotion

          state.analyticUsersClicked = false;
          //leostate.usersFromAnalyticsClicked = [];
          break
      }
    },
    setAnalyticUsersClicked: (state :any, action: PayloadAction<AnalyticUsersClickedType>) => {
      state.analyticUsersClicked = action.payload;
    },
    // getUsersFromAnalyticsClicked: (state, action) => {
    //   state.usersFromAnalyticsClicked = action.payload;
    // },
    setCenter: (state :any, action: PayloadAction<Point>) => {
      state.current_center = action.payload;
    },
    // setClickedMessageIAM: (state, action) => {
    //   state.clickedMessageIAM = action.payload;
    // }
  },
  extraReducers: (builder: any) => {
    //Generate AI summary from story
    // builder.addCase(generateAISummary.pending, (state) => {
    //   state.AIProfileSummary = null;
    //   state.AIRecommendations = []
    //   state.loading = true;
    //   state.isErrorGenerateAISummary = false
    //   state.isSuccessGenerateAISummary = false
    //   state.errorGenerateAISummary = null
    // });
    builder.addCase(generateAISummaryThunk.fulfilled, (state :any, { payload }: PayloadAction<any>) => {
      console.log(payload);
      // state.AIProfileSummary = payload['ai_summary'];
      // state.AIRecommendations = payload['recommendations']
      // state.loading = false;
      // state.isErrorGenerateAISummary = false
      // state.isSuccessGenerateAISummary = true
      // state.errorGenerateAISummary = null
    });

  }

});

export const {
  setLeftSideComponentWithOptions,
  setPublicPinClicked,
  setAiProfileOptions,
  setAnalyticUsersClicked,
  setCenter,
  //setClickedMessageIAM
} = dataProcessingSlice.actions;


export default dataProcessingSlice.reducer;
