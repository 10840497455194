import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Typography, Link, Stack, Box, CardMedia, CardActions } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { SvgIcon } from '@mui/material';
//import { Box, Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useGetWebPageMetaDataQuery } from '../../features/utilitySlice';
import { CircularProgressCentered } from '../../app/sharedcomponents'
import { ArrowButton, LinksProps, MySvgImage } from './ResourceCardCTA'
import { YouTubeContent } from './YouTubeContent';

export const ResourceLinkCard: React.FC<LinksProps> = ({ linkData: resourceData }) => {
    const getWebPageMetaDataArg = resourceData.metaDataUrl ?? skipToken
    const { data: metaData, isSuccess: isSuccess, isLoading, isError, error } = useGetWebPageMetaDataQuery(getWebPageMetaDataArg)

    const navigate = useNavigate();
    const handleClick = () => {

        //navigate(`${resourceData.href}`, resourceData)
        //const sub = resourceData.sub
        //navigate("/ResourceGrid", { state: { linkData: sub, level: 2 }})
        const url = resourceData.href ? resourceData.href : resourceData.metaDataUrl ? resourceData.metaDataUrl : resourceData.url
        if (url)
            window.open(url, '_blank');

    }
    const title = isLoading ? undefined : resourceData.title ? resourceData.title : metaData ? metaData.title : ''
    const subTitle = resourceData.subTitle ? resourceData.subTitle : metaData ? metaData.description : ''
    const url = resourceData.href ? resourceData.href : resourceData.metaDataUrl ? resourceData.metaDataUrl : resourceData.url
    const image = resourceData.urlImage ?? (metaData ? metaData.image ? metaData.image : metaData["image:url"] : undefined)
    const alt = metaData ? (metaData["image:alt:"] ?? "") : ""
    if (isLoading)
        return (<CircularProgressCentered />)
    return (
        <Link href={url} target="_blank" underline="none" >
            <Card variant="outlined" sx={{ height:'100%'}}>
                <CardMedia
                    component="img"
                    height="200"
                    sx={{ p:4, objectFit: "contain"  }}
                    src={image}
                />
                <CardContent
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems: 'center', 
                        alignContent: 'center',
                        justifyContent: 'left'
                    }}
                >
                    {isLoading ?? <CircularProgressCentered />}
                    <Typography variant='subtitle1' fontWeight="bold" >
                        {title}
                    </Typography>
                    <Typography variant="body2" >
                        {subTitle}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    );

};


