import React from 'react';
import { Dialog, DialogContent, Typography, DialogActions, Button,/* useMediaQuery, useTheme,*/ DialogTitle, SxProps, Theme, Box } from '@mui/material';
import { QuestionsForm } from './QuestionsForm'
//import { HeaderContent } from '../../app/sharedcomponents';
//import { useDispatch } from 'react-redux';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions'
import { ProfileDialogTitle } from './ProfileDialogTitle'
//import { SearchState, getSearchStateFromPayload, getSearchByTerm } from '../../features/searchSlice'
import { useForm, /*SubmitHandler, Controller, */FormProvider } from 'react-hook-form';
import StepProgressBar from './StepProgressBar';
import { CircularProgressCentered } from '../../app/sharedcomponents'
//import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { StepType } from './QuestionsForm'
import { ErrorMessage } from "@hookform/error-message"
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface StepIndexUpdateType { stepIndex: number, isUpdated: boolean, steps: StepType[] }
export interface UpdateStepArgsType { formData: any, sumbitAattribute: string | undefined | null, stepIndexUpdate: StepIndexUpdateType, methods: any }

interface WizardDialogProps {
  //UpdateWizardData : ( arg: any) => void
  formData: any
  defaultFormDataformData: any
  updatedData: any
  open: boolean
  onClose: (args: UpdateStepArgsType) => void
  updateStep: (arg: UpdateStepArgsType) => any
  handleBack: () => void,
  //updatedEffect: (stepIndex: number, methods: any) => void
  stepIndex: number,

  //screens: ReactJSXElement[],
  steps: StepType[],
  isUpdating: boolean,
  isLoading: boolean
  sx?: SxProps<Theme> | undefined
  wizardData: any

}


export const FormDataUpdateContext = React.createContext(undefined);

export const WizardDialog: React.FC<WizardDialogProps> = ({ defaultFormDataformData, formData, wizardData, updatedData, isLoading, isUpdating, open, onClose, updateStep: UpdateStep, handleBack, stepIndex, steps: wizardSteps, sx }) => {
  //const [stepIndex, setStepIndex] = useState(0);
  //const [wizardSteps, setWizardSteps] = useState(steps);
  //const [isUpdated, setIsUpdated] = useState<UpdateSate>({ isSuccess: false, step: undefined, data: undefined, profileDTO: undefined });

  //const defaultValues = { location: formData?.location?.country ? formData?.location : { ...formData?.location??{zipCode: null},  country: 'United States' } }
  const methods = useForm<any>({ defaultValues: defaultFormDataformData })
  const { handleSubmit } = methods
  const { errors/*, isDirty, dirtyFields*/ } = methods.formState


  let isLastStep = stepIndex === wizardSteps.length - 1
  const GetArgs: (data: any, sumbitAattribute: string | undefined | null) => UpdateStepArgsType = (data, sumbitAattribute) => {
    const stepIndexUpdate: StepIndexUpdateType = { stepIndex: stepIndex, isUpdated: false, steps: [...wizardSteps] }
    return { formData: data, sumbitAattribute: sumbitAattribute, stepIndexUpdate: stepIndexUpdate, methods }
  }

  const onCloseLocal: () => void = () => {
    const args = GetArgs(null, undefined)
    onClose(args)
    if (args.stepIndexUpdate.isUpdated) {
      // setStepIndex(args.stepIndexUpdate.stepIndex + 1);
      // setWizardSteps( args.stepIndexUpdate.steps)
    }
  }
  const onSubmitProfile = async (data: any, event: any) => {
    let sumbitAattribute: string | null = null
    if (event) {
      const submitter = event.nativeEvent.submitter as HTMLButtonElement;
      sumbitAattribute = submitter.getAttribute('submit-attr');
      //console.log('Form data:', data);
      //console.log('Custom attribute:', sumbitAattribute);
    }
    const args = GetArgs(data, sumbitAattribute)

    await UpdateStep(args)

  }

  const onError = (errors: any) => {
    // Focus on the first field with an error
    const firstError = Object.keys(errors)[0];
    methods.setFocus(firstError);
  };

  const buttonTitle = wizardSteps[stepIndex]?.buttonTitle ?? (isLastStep ? 'Finish' : 'Next')
  const autoSizeSX = wizardSteps[stepIndex]?.autoSizeDialog ? {
    width: undefined,
    maxWidth: undefined,
    height: undefined,
    maxHeight: undefined
  } : {}

  const bottomRightSX = wizardSteps[stepIndex]?.showBottomRight ? {
    position: {
      xs: 'absolute', // theme.breakpoints.up('xs')
      sm: 'absolute', // theme.breakpoints.up('sm')
      md: 'fixed', // theme.breakpoints.up('md')
    },
    zIndex: 1
  } : {}

  if (wizardSteps.length < (stepIndex + 1) || !wizardSteps[stepIndex]) {
    return null;
  }
  //not passing onClose={onClose}

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Stop form from submitting by default
      //designatedSubmitButton.current.click(); // Click designated button programmatically
      handleSubmit(onSubmitProfile, onError)()
    }
  };
         

  return (

    <FormProvider {...methods}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmitProfile, onError),
          autoComplete: 'off'
        }}
      >
        <DialogTitle>
          <ProfileDialogTitle onBack={handleBack} onClose={onCloseLocal} noBackArrow={wizardSteps[stepIndex]?.noBackArrow} color={wizardSteps[stepIndex]?.titleColor} stepIndex={stepIndex}>{wizardSteps[stepIndex]?.title} </ProfileDialogTitle>
          {!wizardSteps[stepIndex].noProgressBar && <StepProgressBar steps={wizardSteps} activeStep={stepIndex} />}
        </DialogTitle>
        <DialogContent sx={{}}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%', }}>
            {(formData && (!isLoading)) ? <QuestionsForm   onKeyDown={handleKeyDown}  steps={wizardSteps} stepIndex={stepIndex} isLastStep={isLastStep} data={formData} wizardData={wizardData} updatedData={updatedData} isLoading={isLoading} isUpdating={isUpdating} />
              : <CircularProgressCentered />}
            <ErrorMessage
              errors={errors}
              name="root.serverError"
              render={({ message }) => <> <Box sx={{ display: 'flex', flexDirection: 'row' }}> <WarningAmberIcon color='error' /><Typography sx={{ ml: '2px' }} variant='subtitle2' color='error'>{message} </Typography></Box></>}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          {!wizardSteps[stepIndex].noButton && <Button id="pin_next_button" type="submit" disableElevation sx={{ alignSelf: 'flex-end', textTransform: 'none', fontWeight: 'normal', borderRadius:8, backgroundColor:'#656EFF' }} variant="contained" >
            {buttonTitle}
          </Button>}
        </DialogActions>
      </Dialog >

    </FormProvider >


  );
};
