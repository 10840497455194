// apiSlice.ts
import {  createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const institutionsApi = createApi({
    reducerPath: 'institutionsApi',
    baseQuery: fetchBaseQuery(),
    tagTypes: ['Institution'],
    endpoints: (builder) => ({
        //gest profile  by iam_is inserts if no matching profile found        
        geInstitutions: builder.query<any[], void>({
            query: (args) => {
                
                const qry = `/api/get/institutions`                
                return qry
            },
            providesTags: (result, response, updated) => {
                return [{ type: 'Institution', id:'LIST' }]
            }
        }),
        
    }),
})


export const { useGeInstitutionsQuery } = institutionsApi


