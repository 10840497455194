import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/query';

//import { useEffect } from 'react';
import { useAuthProfile } from '../../app/hooks';
//import { AnalyticUsersClickedType } from '../../features/dataProcessingSlice';
import { useGetProfileQuery } from '../../features/profilesSlice';
import { aiAnalyticsApi, useAIProfileSummaryQuery } from '../../features/aiAnalytics';
//import { AnyAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';


// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

interface useAIProfile {
    aiSummary: any,
    profile: any,
    authProfile: any,
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    error: any,

}

export const useAIProfileForPinClicked: (profileId: number | typeof skipToken) => useAIProfile = (profileId) => {
    const authProfile = useAuthProfile()
    const { profile: loggedInUserProfile, isLoading: isLoadingLoggedInUserProfile, isSuccess: isSuccessLoggedInUserProfile, isError: isErrorLoggedInUserProfile, error: errorLoggedInUserProfile } = authProfile
    //const publicPinClickedAIState = useSelector((state: any) => state.dataProcessing.publicPinClicked)
    //const analyticUsersClickedAIState: AnalyticUsersClickedType = useSelector((state: any) => state.dataProcessing.analyticUsersClicked)
    
    const ProfileIdArg = profileId === skipToken ? skipToken : isSuccessLoggedInUserProfile ? ((loggedInUserProfile?.id !== profileId && profileId) ? profileId : skipToken) : skipToken
    const { data: profilePublicPinClicked, isFetching: isFetchingProfilePublicPinClicked, isLoading: isLoadingProfilePublicPinClicked, isSuccess: isSuccessProfilePublicPinClicked, isError: isErrorProfilePublicPinClicked, error: errorProfilePublicPinClicked } = useGetProfileQuery(ProfileIdArg)

    // const generateAISummaryState = useSelector(
    //     (state: RootState) => aiAnalyticsApi.endpoints.generateAISummary.select(undefined)(state)
    //   );


    const isGenerateAISummaryLoading = useSelector((state: RootState) => {
        const foundKeyWithPendingStatus = state.aiAnalytics.mutations ? Object.keys(state.aiAnalytics.mutations).find(mkey => state.aiAnalytics.mutations[mkey]?.endpointName === 'generateAISummary' &&  state.aiAnalytics.mutations[mkey]?.status === 'pending') : undefined ///GenerateAISummaryMutation: false// ']//?.isLoading
        //console.log(`isGenerateAISummaryLoading  status: ${foundKeyWithPendingStatus ? state.aiAnalytics.mutations[foundKeyWithPendingStatus]?.status: 'key not found'} `)
        return foundKeyWithPendingStatus?true:false
        //return foundKey ? state.aiAnalytics.mutations[foundKey]?.status === "pending" : false
    });

    // const isProfileUpdateLoading = useSelector((state: RootState) => {
    //     const foundKey = state.profileApi.mutations ? Object.keys(state.profileApi.mutations).find(mkey => state.profileApi.mutations[mkey]?.endpointName === 'UpdateProfile') : undefined ///GenerateAISummaryMutation: false// ']//?.isLoading       
    //     //console.log(`isProfileUpdateLoading  status: ${foundKey ? state.profileApi.mutations[foundKey]?.status: 'key not found'} `)
    //     return foundKey ? state.profileApi.mutations[foundKey]?.status === "pending" : false
    // });

    //console.log(`isProfileUpdateLoading: ${isProfileUpdateLoading}  -----  isGenerateAISummaryLoading: ${isGenerateAISummaryLoading}`)
    

    const AIProfileSummaryArg = ( profileId === skipToken || isGenerateAISummaryLoading ) ? skipToken : isSuccessLoggedInUserProfile ? (
        (loggedInUserProfile?.id === profileId || !profileId) ? loggedInUserProfile?.id :
            isSuccessProfilePublicPinClicked ? profilePublicPinClicked.id : skipToken
    ) :
        skipToken
    const { data: AIProfileSummary, isFetching: isFetchingAIProfileSummary, isLoading: isLoadingeAIProfileSummary, isSuccess: isSuccessAIProfileSummary, isError: isErrorAIProfileSummary, error: errorAIProfileSummary } = useAIProfileSummaryQuery(AIProfileSummaryArg)
    //, {        refetchOnMountOrArgChange: true,    })


    const profileToUse = loggedInUserProfile?.id === profileId ? loggedInUserProfile : profilePublicPinClicked
    //const isSuccessProfileToUse = isSuccessLoggedInUserProfile && (loggedInUserProfile?.id === publicPinClickedAIState ? true : isSuccessProfilePublicPinClicked)


    const isLoading = isGenerateAISummaryLoading ||  isLoadingeAIProfileSummary || isLoadingLoggedInUserProfile || isLoadingProfilePublicPinClicked || isFetchingAIProfileSummary || isFetchingProfilePublicPinClicked
    //console.log(`isLoading status: ${isLoading}`)
    const isSuccess = isSuccessAIProfileSummary && isSuccessLoggedInUserProfile && ((ProfileIdArg === skipToken) || isSuccessProfilePublicPinClicked)
    const isError = isErrorProfilePublicPinClicked || isErrorAIProfileSummary || isErrorLoggedInUserProfile
    const error = isErrorProfilePublicPinClicked ? errorProfilePublicPinClicked : (isErrorLoggedInUserProfile ? errorLoggedInUserProfile : (isErrorAIProfileSummary ? errorAIProfileSummary : undefined))
    return { aiSummary: AIProfileSummary, authProfile: authProfile, profile: profileToUse, isLoading, isSuccess, isError, error }

}


