import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

/*const carecenter_obj = {
  center_name: null,
  address: null,
  lat: null,
  lng: null,
  street: null,
  city: null,
  state: null,
  region: null,
  country: null,
  website: null
}*/

const initialState = {
  carecenters: [] ,
  loadStatus: { isUnInitialized: true }
};

export const loadData = createAsyncThunk("loaddata/loadData", async ( parmObject, thunkAPI) => {
  try {
    const { type } = parmObject
    if (type === "carecenters") {
      //const response = await axios.get("/api/get/carecenters");
      const { center } = parmObject
      const response = await axios.get(`/api/get/carecentersforpoint?lat=${center.lat}&lng=${center.lng}`);
      //console.log(response);
      return response.data;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const loadDataSlice = createSlice({
  name: 'loaddata',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    remove_carecenters: (state) => {
      state.carecenters = [];
    },
  },
  extraReducers: (builder) => {
    // global
    builder.addCase(loadData.pending, (state) => {
      state.loadStatus = { isLoading: true, isSuccess: false, isError: false}
    });
    builder.addCase(loadData.fulfilled, (state, { payload }) => {
      state.carecenters = payload;
      state.loadStatus = { isLoading: false, isSuccess: true, isError: false}
    });
    builder.addCase(loadData.rejected, (state, action) => {
      state.carecenters = [];
      state.loadStatus = { isLoading: false, isSuccess: false, isError: true}
    });
  }

});

export const {
  remove_carecenters
} = loadDataSlice.actions;


export default loadDataSlice.reducer;
