import React, { ReactNode, useState } from "react"
import { Avatar } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { LoadingButton } from '@mui/lab';
//import { Hub } from "aws-amplify/utils";
import { signIn, signOut, getCurrentUser, fetchAuthSession, fetchUserAttributes, signInWithRedirect } from "aws-amplify/auth";
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg'
import { HeaderContent } from '../../app/sharedcomponents';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { /*useSelector,*/ useDispatch } from 'react-redux';
import { LoginErrorInfo, SignInWithFB } from "./utils";
import { FormValuesEmailSignIn } from "./SigninEmail";
import { useForm } from "react-hook-form";

function LoginError(props: any) {
  if (props.errorinfo) {
    return (
      <Typography color="error">{props.errorinfo}</Typography>
    );
  }

  return (
    <Typography color="error">Login Error</Typography>
  );
}


// TODO remove, this demo shouldn't need to reset the theme.

//const defaultTheme = createTheme();

//<ThemeProvider theme={defaultTheme}>
export const SignIn: React.FC<{ deleteProfile: boolean }> = ({ deleteProfile }) => {
  return (

    <Container component="main" maxWidth="sm"  sx={{height:'100%', pt:4, pb:4}}>   
        <SignInSignUpUI deleteProfile={deleteProfile} >
        Sign in  {deleteProfile && <>to delete your account data </>}
        </SignInSignUpUI>
    </Container>
  );
}
//</ThemeProvider>

interface SignInUIProps
{ 
  deleteProfile?: boolean 
  children?: ReactNode;
  isSignUp?: boolean
  noLogo?: boolean
}

export const SignInSignUpUI: React.FC<SignInUIProps> = ({ children,isSignUp, noLogo, deleteProfile = false }) => {
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors }, getValues } = useForm<FormValuesEmailSignIn>();
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [signInLoading, setSignInLoading] = useState(false);
  //const [loginError, setLoginError] = useState();
  const [nonFormMessage, setNonFormMessage] = useState<LoginErrorInfo>();
 
//   //FB app id and secret
  //941485340896838
  //fd32e3409a729a8f6b144b95275ced79
  return (
    <Box component="form" onSubmit={handleSubmit(() => SignInWithFB(setSignInLoading, setNonFormMessage))} noValidate>
      <Stack
        direction="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{ border: 1, borderColor: '#DEDEDE', borderRadius: 2, p: 2 }}
      >
        {!noLogo && <HeaderContent />}
     
        {children}        
     
        <LoadingButton
          loading={signInLoading}
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          sx={{ mt: 1, backgroundColor: '#0866FF' }}
          
          startIcon={<SvgIcon component={FacebookIcon} inheritViewBox />}
        >
          <span>sign in with facebook</span> 
        </LoadingButton>

        {!isSignUp && <Divider sx={{ width: '100%' }}>or</Divider>}

        <Button
          disableElevation
          onClick={ () => navigate('/SigninEmail')}
          variant="outlined"
          sx={{
            bgcolor: 'white',
            color: 'black',
            width: '100%'
          }}
        >
          Sign {isSignUp?'up':'in'} with email
        </Button>
        {isSignUp ?<Link sx={{ cursor: 'pointer' }} variant="body2" onClick ={() => navigate("/Sign-In")}>
        Already have an email account? Sign in
        </Link>:
        <Link sx={{ cursor: 'pointer' }} variant="body2" onClick ={() => navigate("/SignUp")}>
          Don't have an account? Sign Up
        </Link>}
      </Stack>
    </Box>
  )
}
//<Link href="/map/SignUp" variant="body2">
//          {/*onClick={() => SignInWithFB(setSignInLoading, setNonFormMessage)*/}


  //async function handleSignIn({ email, password }) {
// async function handleSignIn({ email, password }: FormValuesEmailSignIn): Promise<any> {
//     await signOut().catch((err) => console.log('Error signing out: ', err));
//     try {
//       const user = await signIn({ username: email, password: password });
//       if (user['isSignedIn'] === true) {
//         /* const AccessToken = new CognitoAccessToken({
//            AccessToken: accessToken,
//        });
         
//          // Now you have access to the tokens
//          console.log('Access Token:', accessToken);
//          console.log('ID Token:', idToken);
//          console.log('Refresh Token:', refreshToken);
//        */
//         return { isSuccess: true }
//       } else {
//         return { isError: true };
//       }
//     } catch (error) {
//       console.log('error signing in:', error);
//       return { isError: true, Error: (error as any).message };
//     }

//   }
