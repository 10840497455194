import React, { useState } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PasswordChecklist from "react-password-checklist"
import { resetPassword, confirmResetPassword, signIn } from "aws-amplify/auth";
import { LoadingButton } from '@mui/lab';
import { ForgotPasswordConfirmation } from './ForgotPasswordConfirmation';
import { HeaderContent } from '../../app/sharedcomponents';
import { LoginError, LoginErrorInfo } from './SigninEmail'

//const loginErrorInfoDefault = { type: "", message: "", status: ""}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export function ForgotPassword() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [validPassword, setValidPassword] = useState("")
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordConfirmation, setResetPasswordConfirmation] = useState(false);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [code, setCode] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitError, setSubmitError] = useState(undefined);


  function SubmitError(props) {
    if (props.exist) {
      return (
        <Typography color="error">Please check if you have entered your confirmation code</Typography>
      );
    }
    return <Box></Box>;
  }

  function SendCodeSubmit(props) {
    if (props.exist) {
      return (
        <LoadingButton
          loading={props.loading}
          fullWidth
          variant="contained"
          disableElevation
          sx={{ mt: 3, mb: 3, bgcolor: '#666eff' }}

          onClick={(e) => {
            e.preventDefault();
            setSendCodeLoading(true);
            handleSendCode();
            setTimeout(() => {
              setSendCodeLoading(false);
            }, 60000)
          }
          }
        >
          Request email confirmation code
        </LoadingButton>
      );
    }
    return <Box></Box>;
  }

  function ResetPassword(props) {
    if (props.exist) {
      return (
        <>
          <TextField
            onChange={(e) => {
              setCode(e.target.value);
              console.log(code);
            }}
            margin="normal"
            required
            fullWidth
            name="code"
            label="Email Confirmation Code"
            id="code"
            value={code}
            autoFocus
          />
          <LoadingButton
            loading={props.loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 3, bgcolor: '#666eff', fontWeight: 'bold' }}
          >
            Reset Password
          </LoadingButton>
        </>
      );
    }
    return <Box></Box>
  }

  async function handleSendCode() {
    //disableButton();
    try {
      try {
        const user = await signIn({ username: email, password: "Cuckoo!"});// password dont matter here - seeing if user has been confirmed
        if (user['isSignedIn'] === true) {
          return { isSuccess: true }
        } else {
          if (user?.nextStep?.signInStep === "CONFIRM_SIGN_UP") {
            setSubmitError({ status: 'error', type: 'emailNotConfirmed', message: "Please click the Verify Email link in the confirmation email that has been sent to your email address, to complete your sign-up.  You can re-send confirmation email by Signing In.", })
            //setCanSubmit( false);
            return
          }
        }
      } catch (error) {
        console.log(error);  
      }



      const data = await resetPassword({ username: email });
      console.log(data);
      switch (data['nextStep']['resetPasswordStep']) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          const codeDeliveryDetails = data['nextStep']['codeDeliveryDetails'];
          console.log(
            `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
          );
          // Collect the confirmation code from the user and pass to confirmResetPassword.
          setCanSubmit(true);
          break;
        case 'DONE':
          console.log('Successfully reset password.');
          break;
        default:
          setCanSubmit(false);
      }
    } catch (err) {
      console.log(err);
      setCanSubmit(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (validPassword && code.length === 6) {
      try {
        // set loading to true before calling API
        setResetPasswordLoading(true);
        await confirmResetPassword({ username: email, confirmationCode: code, newPassword: password });
        setResetPasswordConfirmation(true);

      } catch (error) {
        // add error handling here
        setResetPasswordLoading(false);
        setSubmitError({type: "confirmResetPasswordError", status: "error",  message:error.message});

        console.log(error);
      }
    } else {
      setSubmitError({type: "confirmResetPasswordError", status: "error", message: "Please check if you have entered your confirmation code"});
      setResetPasswordLoading(false);
    }

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm"  sx={{height:'100%', pt:4, pb:4}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 6,
            border: 1,
            borderRadius: 4,
            borderColor: '#DEDEDE',
          }}
        >
          <ForgotPasswordConfirmation open={resetPasswordConfirmation} />
          <HeaderContent />
          <Typography component="h1" variant="h5" sx={{ mt: 3, fontWeight: 'bold' }}>
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <TextField
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              onChange={(e) => {
                setPasswordAgain(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Confirm New Password"
              type="password"
              id="password2"
            />
            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
              minLength={8}
              value={password}
              valueAgain={passwordAgain}
              onChange={(isValid) => {
                if (isValid) { setValidPassword(true) }
                else { setValidPassword(false) }
              }}
              sx={{ mt: 3 }}
            />
            <SendCodeSubmit
              exist={!canSubmit}
              loading={sendCodeLoading}
            />
            <ResetPassword
              exist={canSubmit}
              loading={resetPasswordLoading}
            />
            {/* submitError.type === 'confirmResetPasswordError' && <SubmitError exist={submitError} />*/}
          <LoginError errorInfo={submitError} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}