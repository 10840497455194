import { Box, Container, Divider, Button, List, ListItem, ListItemIcon, Stack, Typography, useTheme } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ReactComponent as AISVG } from '../../assets/ai_star.svg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { selectChat } from '../../features/dialogSlice';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ChatLeftSideWrapper: React.FC<{ channel: any, chatMode?: boolean, children?: React.ReactNode, title?: string }> = ({ channel, chatMode, children, title }) => {
    const dispatch = useDispatch<any>();

    const chatState = useSelector((state: any) => state.dialog);
    function closeProfile() {
        if (chatMode) {
            dispatch(selectChat())
        } else {
            //        dispatch(setPublicPinClicked(false))
        }
    }

    return (<Box sx={chatMode ?
        {
            display: { xs: chatState.showChatProfile, sm: chatState.showChatProfile, md: 'flex' },
            overflow: 'auto',
            width: '100%',
            height: '100%'
        }
        :
        {
            overflow: 'auto',
            width: '100%',
            height: { xs: 'auto', sm: 'auto', md: '90vh' }
        }
    }>
        <Stack sx={{ width: '100%',pt:4 }} alignItems="center" direction="column">
            <Button startIcon={<ArrowBackIcon /> } onClick={closeProfile} sx={chatMode ? { display: { xs: 'flex', sm: 'flex', md: 'none' } } : { display: 'flex' }}>
                Back
            </Button>
            <Stack alignItems="center" justifyContent="center" direction="row" sx={{ width: '100%' }}>
                <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
                    {title}
                </Typography>
            </Stack>
            <Container>
                {children}
            </Container>
        </Stack>
    </Box>)
}


export const ChatUserNotFound: React.FC<{ channel: any, chatMode?: boolean, reason: string }> = ({ channel, chatMode, reason }) => {
    const chatState = useSelector((state: any) => state.dialog);
    return (<ChatLeftSideWrapper channel={channel} chatMode={chatMode} title={'Member Profile'}>
        <Stack sx={{ width: '100%' }} alignItems="center" direction="column">
            <Container>

                <Box>
                    <Typography align="center" variant="h5" sx={{ mt: 4, fontWeight: 'bold' }}>
                        Not Available
                    </Typography>
                </Box>


                <Divider />
                <Typography variant="h6" sx={{ pt: 2 }}>Sorry we're unable to show profile of the member you were chatting with.</Typography>
                {reason}
                <Divider sx={{ pt: 2 }} />

            </Container>
        </Stack>
    </ChatLeftSideWrapper>)
}
export function safeParseJSON(str: string) {
    try {
        return JSON.parse(str);
    } catch (error) {
        console.log(error)
        return null; // Or return a default object 
    }
}




export const SendbirdChannelDataBugWorkaround: (data: any) => any = (data) => {
    //return safeParseJSON(input)

    let decodedString = '';

    // If it's already a string, just use it
    if (typeof data === 'string') {
        try {
            const decodedData = atob(data)
            return safeParseJSON(decodedData)
        } catch (error) {
            console.log(error)
            return null; // Or return a default object 
        }


        //decodedString = data;
    }
    // If it's already an object, return it directly (no need to parse)
    else if (typeof data === 'object' && !ArrayBuffer.isView(data) && !(data instanceof ArrayBuffer) && !(data instanceof Buffer)) {
        return data;
    }
    // If it's a Node.js Buffer
    else if (data instanceof Buffer) {
        decodedString = data.toString('utf-8'); // Convert Buffer to string 
    }
    // If it's an ArrayBuffer
    else if (data instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        decodedString = decoder.decode(data); // Decode ArrayBuffer to string
    }
    // If it's a TypedArray (like Uint8Array)
    else if (ArrayBuffer.isView(data)) {
        const decoder = new TextDecoder('utf-8');
        decodedString = decoder.decode(data.buffer); // Decode TypedArray's buffer to string
    } else {
        throw new Error("Unsupported data type");
    }

    // Try parsing as JSON
    try {
        const parsedData = JSON.parse(decodedString); // Attempt to parse JSON
        return parsedData; // Return JSON object if successful
    } catch (e) {
        // Return as string if not JSON
        return decodedString;
    }


}


export const AnalyticsChannelSummary: React.FC<{ channel: any, chatMode?: boolean, title: string, renderMetadataList: ( /*metadata: any*/) => React.ReactElement[] }> = ({ title, channel, chatMode, renderMetadataList }) => {
    //const chatState = useSelector((state: any) => state.dialog);
    //const channelData = (typeof channel.data === 'string') ? SendbirdChannelDataBugWorkaround(channel.data) : channel.data; //Sendbird bug workaround
    //console.log('safeParseJSON of channelDataString', channelData)
    //const theme = useTheme()
    //const formatted_variable = (channelData?.var) ?? channel.data.var
    const items = renderMetadataList()//? renderMetadataList()//channel.data  ): \
    return (<ChatLeftSideWrapper channel={channel} chatMode={chatMode} title={'Group Chat Summary'}>
        <Stack sx={{ width: '100%' }} alignItems="center" direction="column" gap={2}>
            <Container>
                <Box sx={{ width: '100%', border: 1, borderRadius: 3, borderColor: '#EDEEFF', backgroundColor: '#EDEEFF' }}>
                    <Stack direction="row" alignItems="center" gap={2} sx={{p:1}}>
                        <AISVG style={{ height: 40, width: 40 }} />
                        <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>
                            {title}
                        </Typography>
                    </Stack>
                </Box>

                <List>
                    {items.map((item, index) => (
                        <ListItem key={index} sx={{ alignItems: 'flex-start' }}>
                            <ListItemIcon sx={{ minWidth: '25px', mt: '5px' }}>
                                <FiberManualRecordIcon style={{ fontSize: '12px' }} /> {/* Small bullet */}
                            </ListItemIcon>
                            <Box>
                                <Typography variant="subtitle1">
                                    {item}
                                </Typography>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Container>
        </Stack>
    </ChatLeftSideWrapper>)
}

