import * as React from 'react';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import { IconButton, Button, Popper, Box, Popover } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
//import DirectionsIcon from '@mui/icons-material/Directions';
import PushPinIcon from '@mui/icons-material/PushPin';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector, useDispatch } from 'react-redux';
import { /*search,*/ getSearchByTerm, getSearchByCoordinates } from "../../features/searchSlice";

import { useUpdateProfileMutation } from "../../features/profilesSlice";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AppDispatch } from '../../app/store';
//import { showMapFilters } from '../../features/dialogSlice';
import { MapFilterMenu } from './MapFilterMenu';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

//import { StandaloneSearchBox } from '@react-google-maps/api'

export const SearchBar: React.FC<{ id: number, lat: number, lng: number, profileLat: number, profileLng: number, formattedSearchTerm: string, zipCode: string, authStatus: string }> =
  ({ id: loggedProfileId, lat, lng, profileLat, profileLng, formattedSearchTerm, zipCode, authStatus }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showSearchBar, setSearchBar] = React.useState(false)
    //const [filterVariant, setFilterVariant] = React.useState<| "contained" | "outlined">("outlined")
    const searchState = useSelector((state: any) => state.search);
    const dispatch = useDispatch<any>();

    function useIsWidthUp(breakpoint:any) {
      const theme = useTheme();
      return useMediaQuery(theme.breakpoints.up(breakpoint));
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
      event.preventDefault();
      const form = event.currentTarget;
      const searchTerm: string = (form.elements.namedItem('search-bar') as HTMLInputElement).value
      dispatch(getSearchByTerm(searchTerm));
      //event.target[0].value = "";
      (form.elements.namedItem('search-bar') as HTMLInputElement).value = ""
    }
    const showPin = (lat !== profileLat || lng !== profileLng) && authStatus === 'authenticated'
    // function handleUpdateProfileClick() {
    //   //if (!user)       return
    //   //dispatch(getSearchByTerm(formData.zipCode ?? ''))
    //   UpdateProfile({ id: loggedProfileId, profileDTO: { lat: lat, lng: lng, formattedSearchTerm: formattedSearchTerm, zipCode } })
    //   //dispatch(getSearchByCoordinates( { lat: lat, lng: lng }))
    // }

    const handleHomeClick = () => {
      //dispatch(getSearchByTerm(zipCode))
      dispatch(getSearchByCoordinates({ lat: profileLat, lng: profileLng }));
    }

    const [UpdateProfile, { isLoading, isSuccess, isError, error, data: updatedProfile, reset }] = useUpdateProfileMutation()

    const handleFilterClick: (event: React.MouseEvent<HTMLElement>) => void = (event) => {
      //setFilterVariant(anchorEl ?"contained":"outlined"); 
      setAnchorEl(anchorEl ? null : event.currentTarget)
      //dispatch(showMapFilters(true));
    }
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    //<Paper elevation={0} sx={{ display: 'flex', width: '100%', backgroundColor: 'rgba(255, 255, 255)' }}
    return (
      <Stack direction="row" gap={1} sx={{display:'flex', width:'100%', justifyContent:'flex-end'}}>
        {showSearchBar 
        ?
        <Paper 
          elevation={1} 
          sx={{ 
            display: 'flex', 
            flexGrow:1,
          }}      
          component="form"
          onSubmit={handleSubmit}
        >
          <InputBase name="search-bar"
            sx={{ ml: 1, flexGrow: 1 }}
            placeholder="Search on location"
            inputProps={{ 'aria-label': 'search map' }}
          />
          <Divider orientation="vertical" />
          <IconButton sx={{color:'#656EFF'}} type="submit" aria-label="search" >
            <SearchIcon />
          </IconButton>
          <Divider orientation="vertical" />
          <IconButton sx={{color:'#656EFF'}} onClick={() => {setSearchBar(false)}}>
            <CloseFullscreenIcon/>
          </IconButton>
        </Paper>
        :
        <Paper>
          <IconButton onClick={() => {setSearchBar(true)}} sx={{color:'#656EFF'}} aria-label="search" >
            <SearchIcon />
          </IconButton>
        </Paper>
        }
        <Box>
          <Paper>
            <IconButton sx={{color:'#656EFF'}} onClick={handleFilterClick} aria-label="Filter" >
              <FilterAltIcon />
            </IconButton>
          </Paper>
          <Popover elevation = {3} id={loggedProfileId?.toString()} open={Boolean(anchorEl)}  sx={{ zIndex: 200 }} onClose={(event: React.MouseEvent<HTMLElement>) => setAnchorEl( null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
          >
            <MapFilterMenu/>
          </Popover>
        </Box>
      </Stack>
    );
  }
