import React, { useState, useEffect, useRef, ReactNode } from 'react';
// import { useSelector, useDispatch, shallowEqual } from 'react-redux';
// import { useGetChatUserQuery, useGetUserFromChatQuery } from '../../features/chatSlice';
// import { CircularProgressCentered } from '../../app/sharedcomponents';
//import { Calculate } from '@mui/icons-material';
import { Box, Link } from '@mui/material';

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack';

import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import example from '../../assets/example_message.png';
import example_small from '../../assets/example_message_small.png';
import './chat.css'
import { useNavigate } from 'react-router-dom';



export const NoChatsMessage: React.FC<{message?: string , showInmage?: boolean,  children?: ReactNode}> = ({children, message ="You don't have any messages yet. Click on a member profile on the", showInmage = true}) => {
    const [img, setImg] = React.useState(() => {
        if (window.innerWidth > 900) {
            return example
        } else {
            return example_small
        }
    });
    window.onresize = function () {
        // setChatCSS()
        if (window.innerWidth > 900) {
            setImg(example)
        } else {
            setImg(example_small)
        }
    }
    const navigate = useNavigate()
    return (<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '90svh',
        p: 4,
        width: { sm: '90%', md: '80%', lg: '70%' },
    }}
    >
        <Stack
            direction="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
            spacing={3}
            sx={{ border: 1, borderColor: '#F6F6F6', borderRadius: 2, p: 4, backgroundColor: '#F6F6F6' }}
        >
            <DraftsOutlinedIcon fontSize='large' />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {message} <Link sx={{ cursor: 'pointer' }} underline="always" onClick={()=>navigate("/")}>map</Link> to start a chat.                
            </Typography>
            {showInmage && <Box
                component="img"
                sx={{
                    padding: '10px',
                    maxHeight: '60svh',
                    maxWidth: '60svw'
                }}
                alt="Click on a user pin on the map and click on the message button on their profile to start a chat"
                src={img}
            />}
        </Stack>
    </Box>
    )
}