import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { AccountPromotion } from './iam/AccountPromotion';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { CareCenterSummary } from './carecenter/CareCenterSummary'
import { PatientSummary } from './patient/PatientSummary'
import { Stack } from '@mui/material';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CompleteProfileChip } from './profile/CompleteProfileChip'
import Container from '@mui/material/Container';
//import { ToDoList } from './ToDoList';
//import { useEffect } from "react";
//import LinearProgress from '@mui/material/LinearProgress';

//import { ReactComponent as AISVG } from '../assets/ai_star.svg';
//import { ReactComponent as MMWarriorSVG } from '../assets/label_mmwarrior.svg';
//import { ReactComponent as CaregiverSVG } from '../assets/label_caregiver.svg';
import { useAuthProfile } from "../app/hooks"
import { showProfile } from "../features/dialogSlice";
import Button from '@mui/material/Button';
//import Avatar from '@mui/material/Avatar';
//import PlaceIcon from '@mui/icons-material/Place';
//import CloseIcon from '@mui/icons-material/Close';
//import IconButton from '@mui/material/IconButton';
//import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
//import Link from '@mui/material/Link';
//import { Patients } from './patient/Patients';
import { getSearchByCoordinates } from "../features/searchSlice";
//import { MessageProfile } from './MessageProfile'
import { AISummary } from "./AI/AISummary"
import { /*getProfileFromID,*/ LeftSideComponentWithOptions, setCenter, setPublicPinClicked } from "../features/dataProcessingSlice"
import { activateMarker } from '../features/activeMarkers';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgressCentered } from '../app/sharedcomponents';
import { InitialDataProcessingState } from '../features/dataProcessingSlice'
//import { RootState } from '@reduxjs/toolkit/query';
import { RootState, useTypedSelector } from '../app/store';
//import { AnalyticsChannelSummary, ChatUserNotFound } from './messaging/ChatCardMM';
//import {RoootState} from '../App/store'
import { ResourceMini } from './resources/ResourceMini';
import { NavMini } from './navbar/NavMini';
import { /*useGetProfilesForPointQuery,*/ useGetProfilesPinDataForDefaultPointQuery } from '../features/profilesSlice';
import { useCreateOrJoinOpenChatMutation } from '../features/ChatSlice';
import { OpenChannelMain } from './messaging/OpenChannelMain';

export const GetAnnouncementsChannelUrlForEnvironment: () => { channelName: string, channelUrl: string} = ()=>{
  //return 'Patient_Connect_Announcements'
  return { channelName: 'Patient Connect Announcements', channelUrl: 'Patient_Connect_Announcements'}
  // const environmentNODE_ENV: string = process.env.NODE_ENV || 'development';
  // const environmentREACT_APP_ENV: string = process.env.REACT_APP_ENV || 'test'
  // const url = environmentNODE_ENV !== 'development' && environmentREACT_APP_ENV !== 'test'? 'prod_announcements':'test_announcements'
  // return url
}

export function LeftDrawer(bottomSheetRef: any) {
  const dispatch = useDispatch<any>();
  const { authStatus, profile: loggedInProfile, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  }] = useCreateOrJoinOpenChatMutation()
  const { data: profiles, isLoading } = useGetProfilesPinDataForDefaultPointQuery();
  const AIState_publicPinClicked = useTypedSelector((state: RootState) => state.dataProcessing.publicPinClicked)


  const leftSideComponentWithOptionsState: LeftSideComponentWithOptions = useTypedSelector(
    (state: RootState) => state.dataProcessing.leftSideComponentWithOptions
  );
  const AIState_aiProfileOptions = leftSideComponentWithOptionsState.options


  const searchState = useSelector((state: any) => state.search, (prev, next) => {
    if (!next.center) return true
    if (!prev.center) return false
    return prev.center.lat === next.center.lat && prev.center.lng === next.center.lng
  });


  //need to do this to get user set up after loggin in. the announcement chat is created and the Guest user (361) is too for unautheticeted 
  React.useEffect( () => {
    if (! messagingReady &&  isSuccess){
    //.const idToUseForSendbird =   authStatus === 'unauthenticated'? 11111: loggedInProfile.id
    SetChat(  {ids:[loggedInProfile.id], ... GetAnnouncementsChannelUrlForEnvironment(), channelType: "announcements"})
    }
  }, [isSuccess])

  const center = searchState.center;
  if (searchState.status.isUnInitialized) {
    return null
  }
  const showAccountPromotion = authStatus !== 'authenticated' //&& ((leftSideComponentWithOptionsState.options?.showAccountPromotion)??false)

  const handleHomeClick = () => {
    dispatch(getSearchByCoordinates({ lat: loggedInProfile.lat, lng: loggedInProfile.lng }));
    //leocenterno need the below ceters probobly by showing the marker on the map dispatch(setCenter({ lat: profile.lat, lng: profile.lng }))
    dispatch(activateMarker({ id: loggedInProfile.id, disableHover: true }))
    dispatch(setPublicPinClicked(loggedInProfile.id))
    //dispatch(getProfileFromID(profile.id))
  }

  function editStory() {
    dispatch(showProfile({ isForced: true }))
  }


  if (isError) {
    const profileError = error.data ? error.data.error : error.message ?? ''
    return (<>{profileError}</>)
  }
  if (isAuthProfileLoading)
    return (<CircularProgressCentered />)


  const noStory = loggedInProfile ? !loggedInProfile?.comment?.trim() : true
  const showProfileToComplete: boolean = loggedInProfile ? !loggedInProfile.is_completed : true //loggedInProfile.zipcode === null || loggedInProfile.communicate_with_others === null || !loggedInProfile.provide_information_for_research === null || !loggedInProfile.receive_emails === null : false

  //const showAISummary = AIState_publicPinClicked ? ((!noStory && !showProfileToComplete) ) : false
  const showAISummary = AIState_publicPinClicked
  // const environmentNODE_ENV: string = process.env.NODE_ENV || 'development';
  // const environmentREACT_APP_ENV: string = process.env.REACT_APP_ENV || 'test'
  if (showAISummary) {
    return (<AISummary bottomSheetRef={bottomSheetRef} pinToUse={AIState_publicPinClicked} showAccountPromotion={showAccountPromotion} showMessageButton={AIState_aiProfileOptions?.showMessageButton} showViewUserStoryAnalytics={AIState_aiProfileOptions?.showViewUserStoryAnalytics} />)
  } else {
    return (
      <Box sx={{ display: 'flex', borderRight: 1, borderColor: '#E0E0E0', overflow: 'auto', width: '100%', height: { xs: 'auto', sm: 'auto', md: 'calc(100vh - 80px)' }, pt: 2 /* backgroundColor: 'yellow'*/ }}>

          <Stack sx={{ width: '100%',p:2 }} direction="column" gap={3}>
            <Stack direction="column" sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }} gap={2}>
              <Stack direction="row" sx={{display:'flex', width:'100%', justifyContent:'space-evenly', alignItems:'center'}}>
              <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>
                HOME
              </Typography>
              <Button sx={{ color: '#656EFF' }} size="large" onClick={handleHomeClick} variant="text" disableElevation endIcon={<ArrowForwardIosIcon />}>
                PROFILE
              </Button>
              </Stack>
            </Stack>
            <NavMini />
            <Divider sx={{width:'100%'}}/>
            {messagingReady && <OpenChannelMain channelUrl=  {GetAnnouncementsChannelUrlForEnvironment().channelUrl} />}
            <Divider sx={{width:'100%'}}/>
            <ResourceMini />
            <Divider sx={{width:'100%'}}/>
            <Stack direction="row" sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} >
              <Typography variant="h6" sx={{ justifyItems: "center", alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                Map Summary
              </Typography>

              <Typography variant="body1" sx={{color:'#777777'}}>
                {searchState.formattedSearchTerm}
              </Typography>
            </Stack>
            {center && <PatientSummary center={center} />}
            {center && <CareCenterSummary center={center} />}
          </Stack>
          
        </Box>
  
    );
  }

}