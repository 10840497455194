import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Typography, Link, Stack, Box, CardActions, Button } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { SvgIcon } from '@mui/material';
//import { Box, Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useGetWebPageMetaDataQuery } from '../../features/utilitySlice';
import { CircularProgressCentered } from '../../app/sharedcomponents'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface LinksProps {
    linkData: any
    indexHistory: number[] | undefined
    level: number | undefined
}

interface SvgIconFromUrlProps {
    src: string,
    props: any[]
}
export const SvgIconFromUrl: React.FC<SvgIconFromUrlProps> = ({ src: url, ...props }) => {
    const [svgContent, setSvgContent] = useState<string | null>(null);

    useEffect(() => {
        const fetchSvg = async () => {
            try {
                const response = await fetch(url);
                const svgText = await response.text();
                setSvgContent(svgText);
            } catch (error) {
                console.error('Error fetching SVG:', error);
            }
        };
        fetchSvg();
    }, [url]);

    return svgContent ? (
        <SvgIcon {...props}>
            <span dangerouslySetInnerHTML={{ __html: svgContent }} />
        </SvgIcon>
    ) : null;
}




interface MySvgImageProps {
    props: any
    //filePath: string;
    //formPath: boolean
}
//const SvgComponent = React.lazy(async () => { return { default: (await import(`${fileName}`)).ReactComponent }
export const MySvgImage: React.FC<any> = (props) => {
    const [kuku, setKuku] = useState<string | null>(null);
    const { filePath, ...rest } = props
    useEffect(() => {
        //const path = formPath ? `./assets/${filePath}${filePath.includes('.svg') ? "" : ".svg"}` : filePath
        import(`./assets/${filePath}.svg`)
            .then(module => {
                setKuku(module.default);
            })
            .catch(error => {
                console.error("Dynamic import failed:", error);
            });
    }, [filePath]);

    return (
        <div>
            {/* Render kuku once it's loaded */}
            {kuku && <img src={kuku} alt="Patient Stories" {...rest}/>}
        </div>
    );
}
interface ArrowButtonProps {
    handleClick: () => void
}
export const ArrowButton: React.FC<ArrowButtonProps> = ({ handleClick }) => (
    <Button variant="outlined" size="small" sx={{ textTransform: 'none' }} endIcon={<ArrowForwardIcon />} >
        Learn More
    </Button>)

export const GridLink:  (level:number | undefined , indexHistory:number[] | undefined) => string = (level, indexHistory) => `/ResourceGrid/?level=${level}&indexHistory=${indexHistory?.join('-')}` 
export const ResourceCardCTA: React.FC<LinksProps> = ({ linkData: resourceData , indexHistory, level}) => {
    const navigate = useNavigate();
    const nextLevel = level??0 + 1
    const handleClick = () => {
        if (resourceData.sub) {
            //navigate(`${resourceData.href}`, resourceData)
            const sub = resourceData.sub
            //navigate("/ResourceGrid", { state: { linkData: sub} })
            //navigate(`/ResourceGrid/?level=${level}&indexHistory=${indexHistory?.join('-')}` )
            navigate( GridLink(level, indexHistory))
        }
    }
    return (
        <Card onClick={() => handleClick()} elevation={0} sx={{
            borderRadius: '16px',
            backgroundColor: resourceData.backgroundColor,
            padding: '3%'
            //minHeight: '100vh', // Adjust this value based on your needs
        }}>
            <CardHeader
                avatar={<MySvgImage height={50} filePath={resourceData.image} />}
                title={resourceData.title}
                titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}

            >

            </CardHeader>
            <CardContent>

                {resourceData.subTitle}

            </CardContent>
            <CardActions>
            <ArrowButton handleClick={handleClick} />
            </CardActions>
        </Card>
    );

};


