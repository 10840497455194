import React, { useState } from "react"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";

export const SignUpReConfirmation: React.FC<{ open: boolean, email: string }> = ({ open, email }) => {

  // var open = props.open;
  // var email = props.email;
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Email has already been registered. Resending confirmation link"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have been sent a verification link at {email}. If you are still having issues, please contact support@polygonhealth.com for assistance.
          </DialogContentText>
          <br></br>
          <DialogContentText id="alert-dialog-description">
            Please check your email and click the verification link to complete your account registration before you can login.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/SigninEmail")} autoFocus>
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}