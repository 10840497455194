import React, { useState } from "react"
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import emailjs from '@emailjs/browser';
import { useAuthProfile } from "../app/hooks"
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import Link from '@mui/material/Link';
import PlaceIcon from '@mui/icons-material/Place';
import Box from '@mui/material/Box';


import { /*useSelector,*/ useDispatch } from 'react-redux';


// TODO remove, this demo shouldn't need to reset the theme.

//const defaultTheme = createTheme();


export const Contact = () => {
  let root = document.getElementById('root');
  root?.style.setProperty('position','static','important');
  root?.style.setProperty('width','100%','important')

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const { authStatus, profile, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        'service_300ei6f',
        'template_psc8p6a',
        e.target,
        'IREfcB-Wlqf2SmkvK'
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
    // Clears the form after sending the email
    e.target.reset();
  };
  

  return (
    <Container>
      <Stack
        sx={{
          pt:8,
          pb:8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          justifyItems: 'center',
        }}
      >
        <Typography sx={{fontWeight:'bold'}}component="h1" variant="h3">
          Contact Us
        </Typography>

        <Grid container spacing={0} sx={{mt:4,border:1, borderRadius: 4, borderColor:'white', overflow:'hidden'}}>
          <Grid item md={4} 
            sx={{
              backgroundColor:'#656EFF', 
              color:'white', 
              pt:{lg:8,md:8,sm:4,xs:2}, 
              pl:{lg:4,md:4,sm:4,xs:2}, 
              pr:{lg:4,md:4,sm:4,xs:2}, 
              pb:{lg:8,md:8,sm:4,xs:2}, 
              width:'100%'
            }}>
            <Typography variant="h5" sx={{fontWeight:'bold'}}> 
              Contact Information
            </Typography>
            <Stack gap={1} sx={{pt:1,display: 'flex',flexDirection: 'row',alignItems: 'center' }}>
              <EmailIcon />
              <Link href={`mailto:contact@polygonhealth.com`} underline="always" color="#FFFFFF">
                {'contact@polygonhealth.com'}
              </Link>
            </Stack>
            <Stack gap={1} sx={{pt:1,display: 'flex',flexDirection: 'row',alignItems: 'center' }}>
              <PlaceIcon />
              <Typography variant="body1" sx={{color:'white'}}>
                One Broadway, 14th Floor, Cambridge MA 02142
              </Typography>
            </Stack>
            <Stack sx={{pt:1}} gap={1}>
              <Link href={`https://www.polygonhealth.com/privacy-policy`} underline="always" color="#FFFFFF" target="_blank">
                {'Privacy Policy'}
              </Link>
              <Link href={`https://www.polygonhealth.com/termsofuse`} underline="always" color="#FFFFFF" target="_blank">
                {'Terms of use'}
              </Link>
            </Stack>
          </Grid>
          <Grid item md={8} sx={{
            backgroundColor:'#F8F8F8',
            width:'100%',
            pt:{lg:8,md:8,sm:4,xs:2}, 
            pl:{lg:8,md:8,sm:4,xs:2}, 
            pr:{lg:8,md:8,sm:4,xs:2}, 
            pb:{lg:8,md:8,sm:4,xs:2}, 
          }}>
            <Typography variant="h5" sx={{fontWeight:'bold'}}>
              Send us your feedback
            </Typography>
            <Typography variant="body2" sx={{fontWeight:'bold'}}>
              We are continuing to improve the Multiple Myeloma Patient Connect and would love to hear your feedback and suggestions to help us create a better user experience!
            </Typography>
            <form onSubmit={sendEmail}>
              <Stack sx={{pt:3,display: 'flex',flexDirection: 'column',alignItems: 'left'}}>
                {(authStatus === 'authenticated' && !isAuthProfileLoading && profile) 
                  ? <TextField sx={{display:'none'}} id="outlined-basic" label="Outlined" variant="outlined" type="text" name="user_email" value={profile.name_public}/>
                  : <TextField sx={{display:'none'}} id="outlined-basic" label="Outlined" variant="outlined" type="text" name="user_email" value="Anonymous"/>
                }
                <TextField
                  name="message"
                  id="outlined-multiline-static"
                  label="Write your feedback here"
                  multiline
                  rows={4}
                  sx={{width:'100%', mt:1,mb:1, backgroundColor:'white'}}
                />
                <Button type="submit" variant="contained" disableElevation disabled={isSubmitting} sx={{backgroundColor:'#656EFF'}}>
                  Send Feedback
                </Button>
                {stateMessage && <p>{stateMessage}</p>}
              </Stack>
            </form>
          </Grid>
        </Grid>
        </Stack>
      </Container>
  )
}