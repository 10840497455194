import React, { FC, useState } from "react";
//import { loadData, remove_carecenters } from '../../features/loadDataSlice';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { AnyAction, AsyncThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
//import { CareCenterCard, CareCenterInfo } from './kuku';
import { CareCenterCard, CareCenterInfo } from './CareCenterCard'


//import { CareCenterCard, CareCenterInfo } from "./CareCenterCard";


export interface Point {
    lat: number,
    lng: number
}
export interface CareCentersProp {
    careCentersData: CareCenterInfo[]
}

export const Centers: FC<CareCentersProp> = React.memo( ({ careCentersData }) => {
    const [page, setPage] = useState(0)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };
    const onPageCount = 3
    const pageCount = Math.ceil(careCentersData.length / onPageCount)
    const renderContent = (centersData: any[]) => {
        if (centersData) {
            if (centersData.length > 0)
                return centersData.slice(onPageCount * page, onPageCount * page + onPageCount).map((row, index) => (
                    <CareCenterCard key={index} careCenterInfo={row} />
                ))
        }
        return <></>
    }

    return (
        <>
            {renderContent(careCentersData)}
            <Grid item xs={12}  >
                <Stack alignItems="center">
                    <Box sx={{ display: 'flex', justifyContent: "center"}}>
                        <Pagination onChange={handleChange} count={pageCount} color="primary" sx={{ width: '100%', alignContent: 'center', alignItems: 'center' }} />
                    </Box>
                </Stack>
            </Grid>

        </>
    )
}, shallowEqual)