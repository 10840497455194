import React, { useEffect, useRef, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

 // interface CustomTextFieldProps extends TextFieldProps {
 //    Define any additional props specific to CustomTextField, if needed
 // }
  


export const  TextFieldDynamicRows: React.FC<TextFieldProps> = (props) => {
  const textFieldRef = useRef<HTMLDivElement>(null);
  const [rows, SetRows] = useState(2)

  useEffect(() => {
    if (textFieldRef.current) {
      const textField = textFieldRef.current;
      const lineHeight = parseInt(window.getComputedStyle(textField).lineHeight, 10);
      const height = textField.clientHeight;
      const calculatedRows = Math.floor(height / lineHeight);
      //textField.setAttribute('rows', calculatedRows); // Set the rows attribute
      SetRows(calculatedRows)
    }
  }, []);

  return <TextField ref={textFieldRef} rows={rows} variant="outlined" multiline  {...props}/>;
}

// Usage
//<CustomTextField />;
/*
import React, { useEffect, useRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface CustomTextFieldProps extends TextFieldProps {
  // Define any additional props specific to CustomTextField, if needed
}

const CustomTextField: React.FC<CustomTextFieldProps> = (props) => {
  const textFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries && entries[0]) {
        const { height } = entries[0].contentRect;
        const lineHeight = parseInt(window.getComputedStyle(textFieldRef.current!).lineHeight, 10);
        const calculatedRows = Math.floor(height / lineHeight);
        textFieldRef.current!.setAttribute('rows', calculatedRows.toString());
      }
    });

    if (textFieldRef.current) {
      resizeObserver.observe(textFieldRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Spread the props to pass them down to the TextField component
  return <TextField ref={textFieldRef} variant="outlined" multiline {...props} />;
}

// Usage
<CustomTextField fullWidth label="Custom Text Field" />;

*/