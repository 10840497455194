import React, { useEffect, useRef, useState } from 'react';
import { Card, CardHeader, CardContent, Typography, Link, Stack, Box, CardMedia } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { SvgIcon } from '@mui/material';
//import { Box, Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useGetYoutubeMetaDataQuery } from '../../features/utilitySlice';
import { CircularProgressCentered } from '../../app/sharedcomponents'
import { LinksProps, MySvgImage } from './ResourceCardCTA'
import Linkify from 'react-linkify';
import { YouTubeContent, YouTubeContent1 } from './YouTubeContent';
interface YouTubeVideoPlay extends LinksProps {
    isPlaying: boolean
}
export const ResourceYoutubeCard: React.FC<YouTubeVideoPlay> = ({ linkData: resourceData, isPlaying }) => {
    const playerRef = useRef(null);
    const useGetYoutubeMetaDataQueryArg = resourceData.youtubeUrl ?? skipToken
    const { data: metaData, isSuccess, isLoading, isError, error } = useGetYoutubeMetaDataQuery(useGetYoutubeMetaDataQueryArg)

    const navigate = useNavigate();
    // const handleClick = () => {
    //     if (resourceData.sub) {
    //         //navigate(`${resourceData.href}`, resourceData)
    //         const sub = resourceData.sub
    //         navigate("/ResourceGrid", { state: sub })
    //     }
    // }
    const title = isLoading ? undefined : metaData ? metaData.title ? metaData.title : resourceData.title : resourceData.title
    const subTitle = isLoading ? undefined : metaData ? metaData.snippet.description ? metaData.snippet.description : resourceData.subTitle : resourceData.subTitle

    const playVideo = () => {
        //playerRef.current?.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    };
    if (isLoading)
        return (<CircularProgressCentered />)
    if (isSuccess){
        return (<Card sx={{ padding: '3%', backgroundColor:'white' , border: 1, borderColor: '#E0E0E0'}}  variant='outlined' >
            {<YouTubeContent1 videoId={metaData.id} title={title} description={subTitle} isPlaying={isPlaying} />}
            {/*<YouTubeContent1 videoId={metaData.id} title={title} description={subTitle} isPlaying={isPlaying} />*/}
            </Card> 
        )
    }

    return (null)
  
};


