import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Typography, Box, Stack/*, TextField*/ } from '@mui/material';


import { ProfileFormData, useStep } from '../wizard/QuestionsForm'
import {  GetSubTitleFromTitleSubtitle } from '../wizard/QuestionsForm'
import { TextFieldDynamicRows } from '../../app/components/TextFieldDynamicRows';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Define a stateless functional component as an arrow function
export const AnythingToShare: React.FC = () => {
  const { step,  data } = useStep()
  const { getValues, control, formState: { errors } } = useFormContext<ProfileFormData>();
  //const formData =  getValues()
  return (

    <>
      <Controller
        name="comment"
        control={control}
        defaultValue={data.comment}
        render={({ field }) => (
          <>
          <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Set the height of the parent container
                //backgroundColor: 'red'
              }}
            >
            <Typography variant="h6" sx={{mt: 1, mb: 1}}>
              My Story
            </Typography>
            <Box sx={{border:1, borderRadius:2, width:'100%', backgroundColor:'#F5F5F5', borderColor:'#F5F5F5', p:2 }}>
              <Typography variant="body2" sx={{mb: 1, fontWeight:'bold'}}>
                Your story enables Patient Connect to find other people with similar stories so you:
              </Typography>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'center', pb:1}}>
                <CheckCircleIcon sx={{color:'#656EFF', fontSize:'medium'}}/>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>
                  Don't feel alone
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'center', pb:1}}>
                <CheckCircleIcon sx={{color:'#656EFF', fontSize:'medium'}}/>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>
                  Gain valuable insights
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'center'}}>
                <CheckCircleIcon sx={{color:'#656EFF', fontSize:'medium'}}/>
                <Typography variant="body2" sx={{fontWeight:'bold'}}>
                  Support others
                </Typography>
              </Stack>
            </Box>
              <Typography variant="caption" sx={{mt:2, color:'#777777'}}>
                Others have shared diagnosis, treatments, medications, stage, treatment location, etc.
              </Typography>
              <TextFieldDynamicRows  sx={{ mb: 1, mt: 3, width: '100%', minHeight:'200px'  }}
              margin="dense"
                multiline
                InputLabelProps={{     margin: "dense" , size: 'small'  , variant: 'outlined'  }}

                InputProps={{
                  sx: {
                    flexGrow: 1,
                    alignItems: 'start',   
                    margin: "dense"             
                  },

                }}
                label="Share your story"
                variant="outlined"
                error={!!errors.comment}
                helperText={errors.comment && errors.comment.message}
                value={field.value??''}
                onChange={(event) => {
                  field.onChange(event.target.value)
                } // Use field.onChange to update the value in React Hook Form                
                }
              />
            </div>
          </>
        )}
      />
    </>
  )
}