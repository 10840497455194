import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';
import { GetTitleSubTitleObject } from './QuestionsForm';

interface StepProgressBarProps {
  steps: any[];
  activeStep: number;
  //maxSteps: number
}

export const StepProgressBar: React.FC<StepProgressBarProps> = ({ steps: allsteps, activeStep: activeStepAll }) => {

  if (allsteps[activeStepAll].noProgressBar)
    return null


  const steps: any[] = allsteps.filter(step => !step.noProgressBar)
  const activeStepAccumulater = allsteps.reduce((accumulator, step) => {
    if (accumulator.index > activeStepAll)
      return accumulator
    return ({ activeStep: (accumulator.activeStep - (step.noProgressBar ? 1 : 0)), index: accumulator.index + 1 })
  }, { activeStep: activeStepAll, index: 0 });
  const activeStep = activeStepAccumulater.activeStep
  //const activeStep = activeStepAll + steps.length - allsteps.length
  const lastStep: boolean = activeStep === steps.length - 1 - allsteps.length + steps.length

  return (
    <Box display="flex" flexDirection="row" alignItems="center" >
      {
        steps.map((step, index) => (
          <Box key={index} flexGrow={1} sx={{ marginRight: index === (steps.length - 1) ? 0 : 1, ml: lastStep ? 0 : 0 }}>
            {false && <Typography variant="body1" align="center">
              {step}
            </Typography>}
            {index <= activeStep && <Divider variant="middle" />}
            <Box 
              sx={{
                color: index < activeStep ? "#656EFF" : index === activeStep ? "#656EFF" : "grey.300"
              }}
            >
              <LinearProgress
                variant="determinate"
                color='inherit'
                value={index < activeStep ? 100 : index === activeStep ? 100 : 100}
                sx={{ height: 8 }}
              />
            </Box>

          </Box>
        ))}
    </Box>
  );
};

export default StepProgressBar;
