import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
// import { //useMultiChatLogic, MultiChatSocket, MultiChatWindow, ChatFeedProps, ChatListProps, ChatCardProps, useSingleChatLogic, ChatFeed, SingleChatSocket, MessageListProps,  ChatList, 
//     ChatHeaderProps, ChatObject } from "react-chat-engine-advanced";

// // import ListItemButton from '@mui/material/ListItemButton';
// // import Divider from '@mui/material/Divider';
// // import ListItemText from '@mui/material/ListItemText';
// // import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack';
// // import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useMediaQuery, useTheme, Box, IconButton, Paper } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
// // import Drawer from '@mui/material/Drawer';

// //import { getAIProfileMessageSummary, getAIProfileMessageSummaryOrgetAIProfileSummaryOrgetProfileFromIDAndGenerateAIProfileSummary, getOrGenerateAIProfileSummary } from "../../features/dataProcessingSlice"

// //import { Container, IconButton } from '@mui/material';
// import './chat.css'
// //import DeleteIcon from '@mui/icons-material/Delete';
// //import Modal from '@mui/material/Modal';

// import { OptionsSettings } from 'react-chat-engine-advanced';
// import { useLocation } from 'react-router-dom';
// import { WelcomeGifProps } from 'react-chat-engine-advanced/dist/components/MultiChatWindow/ChatFeed/WelcomeGif/props';


// import { ConfirmChatDelete } from './ConfirmChatDelete';
import { GetOtherPerson, SelectedChat } from './Chats';
// import { ChatCountValueAndSet } from './ChatMain';
// import { SelectedChatValueSet } from './ChatCardMM';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { closeDirectChat, showChatProfile } from '../../features/dialogSlice';
import { Refresh, UndoTwoTone } from '@mui/icons-material';
import { useAuthProfile } from '../../app/hooks';
import { useGroupChannelListContext } from '@sendbird/uikit-react/GroupChannelList/context';
import { CircularProgressCentered } from '../../app/sharedcomponents';
import { chatApi } from '../../features/ChatSlice';
interface ChatHeaderMMProps {
    //   chatHeaderProps: ChatHeaderProps,
    //   selectedChatValueSet: SelectedChatValueSet
    //   chatListProps: any
    //   chatUserData: any
    //chatCountValueSet: ChatCountValueAndSet
    openSummary?: () => void
    showCloseMd?: boolean
}

export const ChatHeaderMM: React.FC<ChatHeaderMMProps> = ({ openSummary = () => { return }, showCloseMd = true }) => {
    const dispatch = useDispatch();
    const context = useGroupChannelContext();
    const { currentChannel, initialized } = context
    const { authStatus, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
    const otherPerson = (context.initialized && isSuccess) ? GetOtherPerson(context.currentChannel, profile.id) : undefined //chatListProps.chat, supposed to be the active chat
    const userName = "cuckoo"
    const description = "description"
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (initialized) {
            dispatch(chatApi.util.invalidateTags(['MessageCount']))
        }
    }, [initialized])
//        <Stack direction="row" alignItems={'center'} justifyContent={'center'} gap={1} sx={{ p: 1, backgroundColor: '#F8F8F8' }}>
    return (
        <Paper elevation={0} sx={{ pr: 1, pl: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: 1, borderRadius: 0, borderColor: '#E0E0E0' }}>
            {(showCloseMd || !isBigScreen) && 
            <Button size="small" startIcon={<ArrowBackIcon fontSize='small' sx={{ color: "white" }}/>} onClick={() => dispatch(closeDirectChat())} variant="contained" disableElevation sx={{ backgroundColor: '#656EFF', fontSize: { xs: '.5rem', sm: '.6rem', md: '.8rem' } }}>
                chat list
            </Button>
            }         
            <Box sx={{ m: '15px', display: 'flex', alignItems: 'center', flexDirection: 'row', }}>                
                <Avatar sx={{ mr: '10px', bgcolor: '#656EFF', display: { xs: 'none', sm: 'flex' } }} />
                <Typography variant="h7" sx={{ flex: 2, fontWeight: 'bold', fontSize: { xs: '.875rem', sm: '1.25rem' }, flexGrow: 10 }} >
                    <div>{currentChannel?.isSuper ? currentChannel?.name : `Private Chat${otherPerson ? ` with ${otherPerson.nickname}` : ' (the other user has left this chat)'} `} </div>
                </Typography>
            </Box>
            {(showCloseMd || !isBigScreen) && 
            <Button startIcon={<InfoIcon fontSize='small' sx={{ color: "white" }} />} size="small" disabled={description?.toLowerCase().includes('loading')} onClick={() => dispatch(showChatProfile())} variant="contained" disableElevation sx={{  backgroundColor: '#656EFF', fontSize: { xs: '.5rem', sm: '.6rem', md: '.8rem' } }}>
                {context.currentChannel?.isSuper ? 'View Chat Info' : 'View Profile'}
            </Button>
            }
        </Paper>
    )
}



interface ChatListHeaderMMProps {
    //   chatHeaderProps: ChatHeaderProps,
    //   selectedChatValueSet: SelectedChatValueSet
    //   chatListProps: any
    //   chatUserData: any
    //chatCountValueSet: ChatCountValueAndSet
    setChannel: (channel: any) => void
    url?: string
    expectedUserChatCount: number
}
export const ChatListHeaderMM: React.FC<ChatListHeaderMMProps> = ({ setChannel, url, expectedUserChatCount }) => {
    const dispatch = useDispatch();
    const context = useGroupChannelListContext();
    const [refreshCount, setRefreshCount] = useState(0)
    const { authStatus, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
    const { groupChannels, initialized, refresh, loadMore, refreshing } = useGroupChannelListContext();
    //const otherPerson = (context.initialized && isSuccess) ? GetOtherPerson(context., profile.id) : undefined //chatListProps.chat, supposed to be the active chat
    const userName = "cuckoo"
    const description = "description"
    useEffect(() => {
        if (context.initialized && !context.refreshing && context.groupChannels?.length < expectedUserChatCount) {
            //refresh()
        }


        if (context.initialized && !context.refreshing && context.groupChannels?.length > 0) {
            if (!context.selectedChannelUrl || context.selectedChannelUrl !== url) {
                if (url) {
                    const channelMatched = context.groupChannels.find(ch => ch.url === url)
                    if (channelMatched) {
                        setChannel(channelMatched)
                        return
                    }
                }
                setChannel(context.groupChannels[0])
            }
        }
    }, [context.initialized, context.refreshing, context.groupChannels.length, url])

    // useEffect(() => {
    //     if (initialized) {
    //         if (groupChannels.length === 0 || (groupChannels.length  < expectedUserChatCount)) {
    //             console.log("refershing ChannelList")
    //             const timer1 = setTimeout(() => {
    //                 if( groupChannels.length === 0 ){
    //                     refresh()
    //                 }
    //                 //refresh()
    //                 loadMore()
    //                 setRefreshCount(refreshCount + 1)
    //             }, 500);
    //             return () => { clearTimeout(timer1) };
    //         }
    //     }
    // }, [initialized, refreshing, groupChannels, refreshCount])
    return (
        <Stack direction="row" alignItems='center' sx={{ height: '100%', borderBottom: 1, borderColor: '#E0E0E0' }}>
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: 'row', }}>
                <Avatar sx={{ bgcolor: '#656EFF', display: { xs: 'none', sm: 'flex' } }} />
            </Box>
            <Typography variant="h7" sx={{ flex: 2, fontWeight: 'bold', fontSize: { xs: '.875rem', sm: '1.25rem' }, flexGrow: 10 }} >
                Your Chats ({groupChannels.length})
            </Typography>
        </Stack>
    )


}


export const ChatListPreview: React.FC<{ channel: any }> = ({ channel }) => {
    const dispatch = useDispatch();
    const context = useGroupChannelListContext();
    const { authStatus, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
    const otherPerson = (channel) ? GetOtherPerson(channel.channel, profile.id) : undefined //chatListProps.chat, supposed to be the active chat
    const userName = "cuckoo"
    const description = "description"


    return (<Paper sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ m: '15px', display: 'flex', alignItems: 'center', flexDirection: 'row', }}>
            <Avatar sx={{ mr: '10px', bgcolor: '#656EFF', display: { xs: 'none', sm: 'flex' } }} />

            <Typography variant="inherit" sx={{ flex: 2, fontWeight: 'bold', fontSize: { xs: '.875rem', sm: '1.25rem' }, flexGrow: 10 }} >
                <div>Chat with {otherPerson?.nickname} </div>
            </Typography>
        </Box>



    </Paper>
    )
}

export const PlaceHolderEmptyList: React.FC<{ expectedUserChatCount: number }> = ({ expectedUserChatCount }) => {
    const dispatch = useDispatch();
    const { groupChannels, initialized, refresh, loadMore, refreshing } = useGroupChannelListContext();
    const [refreshCount, setRefreshCount] = useState(0)
    useEffect(() => {
        if (initialized) {
            if (groupChannels.length === 0 || (groupChannels.length < expectedUserChatCount)) {
                console.log("refershing ChannelList")
                const timer1 = setTimeout(() => {
                    if (groupChannels.length === 0) {
                        refresh()
                    }
                    loadMore()
                    setRefreshCount(refreshCount + 1)
                }, 500);
                return () => { clearTimeout(timer1) };
            }
        }
    }, [initialized, refreshing, groupChannels, refreshCount])

    return (<><CircularProgressCentered /></>)
}
