import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import { defaultSurveySteps } from '../components/survey/SurveyWizard'
import { StepType } from '../components/wizard/QuestionsForm'
//import { ConfirmChatDeleteProps } from '../components/messaging/ConfirmChatDelete'
import { SendbirdChatButtonProps } from '../components/messaging/SendbirdChatButton'

export interface ShowDialogInterface {
  //isShown: boolean,
  isForced?: boolean
  isForcedFromSurvey?: boolean
  isExited?: boolean
  step?:number
  screenTitle?: string
  singleScreen?: boolean
  hideUserAndLocationPinsForIncompleteProfile?: boolean
  //surveySteps?: StepType[]
}

export interface ShowConfirmChatDelete {
  confirmChatDeleteData?: any// ConfirmChatDeleteProps
  isShown: boolean
}

export interface DialogState {
  showProfile: undefined | ShowDialogInterface,
  showDeleteAccount: boolean
  showDataDeletionInstructions: boolean
  showSurvey: ShowDialogInterface
  showDisclaimer: boolean
  isToDoPinClicked: boolean
  isToDoSurveyClicked: boolean
  isToDoResourcesClicked: boolean
  showMapFilters: boolean
  showConfirmChatDelete: ShowConfirmChatDelete | boolean
  showDirectChat: SendbirdChatButtonProps 
  showChatConvo: string
  showChatList: string
  showChatProfile: string
}

const initialState: DialogState =
{
  showProfile: undefined,
  showDeleteAccount: false,
  showDataDeletionInstructions: false,
  showSurvey: { isForced: false, isExited: false, isForcedFromSurvey: false },
  showDisclaimer: true,
  isToDoPinClicked: false,
  isToDoSurveyClicked: false,
  isToDoResourcesClicked: false,
  showMapFilters: false,
  showConfirmChatDelete: { isShown: false },
  showDirectChat: { showChat: false, appId:'', userId:'', channelUrl:'', accessToken:''},
  showChatConvo:'none',
  showChatList:'flex',
  showChatProfile:'none',

};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    showProfile: (state: any, action: PayloadAction<undefined | ShowDialogInterface>) => {
      state.showProfile = action.payload;
    },
    showDeleteAccount: (state: any, action: PayloadAction<boolean>) => {
      state.showDeleteAccount = action.payload;
    },
    showDataDeletionInstructions: (state: any, action: PayloadAction<boolean>) => {
      state.showDataDeletionInstructions = action.payload;
    },
    showSurvey: (state: any, action: PayloadAction<ShowDialogInterface>) => {
      //if( action.payload)      
      state.showSurvey = { ...state.showSurvey, ...action.payload };
      //state.showSurvey = {  isForced:   action.payload.isForced}
    },
    showDisclaimer: (state: any, action: PayloadAction<boolean>) => {
      state.showDisclaimer = action.payload;
    },
    checkToDoPin: (state: any) => {
      state.isToDoPinClicked = true
    },
    checkToDoSurvey: (state: any) => {
      state.isToDoSurveyClicked = true
    },
    checkToDoResources: (state: any) => {
      state.isToDoResourcesClicked = true
    },
    showConfirmChatDelete: (state: any, action: PayloadAction<ShowConfirmChatDelete | boolean>) => {
      if (typeof action.payload === 'boolean') {
        state.showConfirmChatDelete = { isShown: action.payload }
      } else {
        state.showConfirmChatDelete = action.payload
      }
    },
    showDirectChat: (state: any, action: PayloadAction<SendbirdChatButtonProps>) => {
      state.showDirectChat = action.payload;
    },
    closeDirectChat: (state: any, action: PayloadAction<void>) => {
      state.showChatConvo = 'none';
      state.showChatList = 'flex';
      state.showChatProfile = 'none';
      state.showDirectChat = {...state.showDirectChat, showChat: false}
      
    },
    selectChat: (state: any, action: PayloadAction<void>) => {
      state.showChatConvo = 'flex';
      state.showChatList = 'none';
      state.showChatProfile = 'none';
    },
    showChatProfile: (state: any, action: PayloadAction<void>) => {
      state.showChatConvo = 'none';
      state.showChatList = 'none';
      state.showChatProfile = 'flex';
    },
  },

});

export const { closeDirectChat, showDirectChat, showConfirmChatDelete, showProfile, showDeleteAccount, showDataDeletionInstructions, showSurvey, showDisclaimer, checkToDoPin, checkToDoSurvey, checkToDoResources, selectChat,showChatProfile } = dialogSlice.actions;


export default dialogSlice.reducer;
