import { TypedUseSelectorHook, useDispatch, useSelector, shallowEqual } from 'react-redux'
import type { RootState, AppDispatch } from './store'

//import React, { useEffect, useState, useRef } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useGetLoggedInUserProfileQuery } from '../features/profilesSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { getUserAttributes } from '../features/userAttributesSlice';
import { useEffect, useState } from 'react';
import { useGetMessagingQuery } from '../features/ChatSlice';


// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

interface useAuthProfileInterface {
    authStatus: string,
    isFederated: boolean | null,
    profile: any,
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    error: any,
    userAttributesStateStatus: any
    authUser: any,
    userAttributesState: any,
    messagingData: any,
    isSuccessMessaging: boolean,
    isUninitializedMessaging: Boolean,
    messagingReady: boolean,
    deleteTriggered: boolean,
    refetchProfile: () => Promise<any>,

}

export const useAuthProfile = (): useAuthProfileInterface => {
    const [deleteTriggered, SetdeleteTriggered] = useState(false)
    const dispatch = useDispatch<AppDispatch>();
    const { user, authStatus } = useAuthenticator((context) => [context.user])
    //console.log( "useAuthenticator user:", user, "authStatus:", authStatus)
    //TODO: for now commented out equality function because it was causing Warning: Failed prop type: Invalid prop open of type object supplied to ForwardRef(Dialog), expected boolean.
    const userAttributesState = useSelector((state: any) => state.userattributes, shallowEqual
        // , (prev, next) => {
        //     if (!next.status.isSuccess) return true
        //     if (!prev.userAttributes) return false
        //     return prev.userAttributes.sub === next.userAttributes?.sub &&//&& ( prev.showProfileDialog === next.showProfileDialog  )
        //     (prev.HUBSignInEventData?.userId === next.HUBSignInEventData?.userId && next.HUBSignInEventData?.userId) &&
        //     (prev.HUBEvent === next.HUBEvent && next.HUBEvent)
        // }
    );

    const isDelteProfileStatus = useSelector((state: RootState) => {
        const foundKey = state.profileApi.mutations ? Object.keys(state.profileApi.mutations).find(mkey => state.profileApi.mutations[mkey]?.endpointName === 'DeleteUserData') : undefined
        //console.log(`isDelteProfileLoading  status: ${foundKey ? state.profileApi.mutations[foundKey]?.status : 'key not found'} `)
        return foundKey ? state.profileApi.mutations[foundKey]?.status : 'uninitialized'//=== "pending"
    });
    if (isDelteProfileStatus === 'pending' && !deleteTriggered) {
        SetdeleteTriggered(true)
    }


    const nickname = userAttributesState.userAttributes ? userAttributesState.userAttributes.nickname ?? "" : ""
    const email = userAttributesState.userAttributes ? userAttributesState.userAttributes.email ?? "" : ""
    const isFederated: boolean | null = userAttributesState.userAttributes ? userAttributesState.userAttributes.isFederated ?? null : null
    const isUserAttributesActionDone = userAttributesState.status.isSuccess || userAttributesState.status.isError
    //for authStatus === 'authenticated' && user must be truthy    
    useEffect(() => {
        if (userAttributesState.status.isIdle && authStatus === 'authenticated') {//&& ( user || userAttributesState?.HUBSignInEventData)) {
            dispatch(getUserAttributes())
        }
    }, [authStatus, dispatch]);

    // const userAttributes = { nickname: nickname, email: email, sub: authSession.tokens.idToken.payload.sub, ...(isFBbIdentityType ? { isFederated: true } : {}) }
    const iamId = user ? user.userId : userAttributesState.userAttributes?.sub ?? userAttributesState?.HUBSignInEventData?.setSignInDetails?.userId ?? null
    const getUserProfileArg = ((iamId && userAttributesState.status.isSuccess) || authStatus === 'unauthenticated') && !deleteTriggered ?
        {
            iamId: iamId,
            nickname: user ? nickname : userAttributesState.userAttributes?.nickname ?? null,
            email: user ? email : userAttributesState.userAttributes?.email ?? null,
            //zipCode: user ? email : null,
            isFederated: isFederated
        } : skipToken
    //console.log(getUserProfileArg )
    const { data: profile, isLoading: isLoadingProfile, isSuccess: isProfileSuccess, isError: isProfileError, error: profileError, refetch } = useGetLoggedInUserProfileQuery(getUserProfileArg)
    const loggedInId = (isProfileSuccess /*&& authStatus === 'authenticated'*/ && profile.id && !deleteTriggered// ( isDelteProfileStatus === 'fulfilled' ||  isDelteProfileStatus === 'uninitialized' )
    ) ? profile.id : skipToken
    const { data: messaging, isUninitialized: isUninitializedMessaging, isSuccess: isSuccessMessaging, isLoading: isLoadingMessaging, isError: isErrorMessaging, error: errorMessaging } = useGetMessagingQuery(loggedInId)
    const messagingReady: boolean = isSuccessMessaging ? (messaging.userCreationResults.secret1 ? true : false) : false

    const isLoading = isLoadingProfile || getUserProfileArg === skipToken || (!isUserAttributesActionDone && authStatus !== 'unauthenticated') || authStatus === 'configuring' || isLoadingMessaging
    const userAttributesStateStatus = userAttributesState.status
    //const iamId = user?.userId
    const isSuccess = isProfileSuccess && (userAttributesStateStatus.isSuccess || authStatus === 'unauthenticated') && (isSuccessMessaging || authStatus === 'unauthenticated')
    const isError = isProfileError || userAttributesStateStatus.isError || (authStatus !== 'authenticated' && authStatus !== 'unauthenticated' && authStatus !== 'configuring') || isErrorMessaging
    const error = isProfileError ? profileError : isErrorMessaging ? errorMessaging : undefined
    return {
        authStatus, authUser: user, userAttributesState, isFederated, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus,
        messagingReady, messagingData: messaging, isSuccessMessaging: isSuccessMessaging && !isError && !isLoading, isUninitializedMessaging: isUninitializedMessaging, deleteTriggered, refetchProfile:refetch
    }

}


