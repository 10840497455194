import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Button, Divider, TextField, Typography/*, TextField*/ } from '@mui/material';


import { ProfileFormData, useStep } from '../wizard/QuestionsForm'
import { GetSubTitleFromTitleSubtitle } from '../wizard/QuestionsForm'
import { TextFieldDynamicRows } from '../../app/components/TextFieldDynamicRows';
import { MessageProfile } from '../MessageProfile';


// Define a stateless functional component as an arrow function
export const ReviewYourPin: React.FC = () => {
  const { step, data, updatedData, wizardData } = useStep()
  const { getValues, control, formState: { errors }, formState: { dirtyFields }, clearErrors, trigger } = useFormContext<ProfileFormData>();
  var formOverProfile = { ...data, ...updatedData.data, ...updatedData.profileDTO ?? {} }
  const formValues = getValues()
  return (

    <>
      <Controller
        name="comment"
        control={control}
        defaultValue={data.comment}
        render={({ field }) => (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Set the height of the parent container
                //backgroundColor: 'red'
              }}
            >
              <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
                {GetSubTitleFromTitleSubtitle(step)}
              </Typography>
              <Typography variant='body2' sx={{ mb: 2 }}> The personal info and the story will be visible to the public.  </Typography>
              <Divider />


              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>Personal Info</Typography> <Button type="submit" submit-attr="edit-personal-info" sx={{ textTransform: 'none' }}>Edit</Button>
              </Box>
              <MessageProfile hideUserAndLocationPinsForIncompleteProfile={!!!data.is_completed && !!!(formValues.location?.country && formValues.location?.zipCode)} profile={formOverProfile} renderUserName={() => {
                return (
                  <Box flexGrow='10'>
                    <Controller
                      rules={{
                        required: 'Please enter your user name', // Required field message
                        //validate: validatePostalCode, // Use custom validation function
                      }}
                      name="name_public"
                      control={control}
                      defaultValue={data.name_public}
                      render={({ field }) => (
                        <TextField
                          sx={{
                            '& .MuiInputBase-root': {
                              typography: 'h7',
                            },
                          }}
                          label="Personalize your username"
                          variant="outlined"
                          size="small"
                          error={!!errors["name_public"]}
                          helperText={`${errors.name_public ? errors.name_public.message : ''}`}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value)//to update the value in React Hook Form  
                            clearErrors("name_public")
                            trigger("name_public")
                          }
                          }
                        />

                      )
                      }
                    />
                  </Box>)
              }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}> My Story</Typography><Button type="submit" submit-attr="edit-story" sx={{ textTransform: 'none' }}>Edit</Button>
              </Box>
              <Typography variant="body2" sx={{ mt: 1, color: '#777777' }}>
                {field.value}
              </Typography>
            </div >
          </>
        )}
      />
    </>
  )
}