import React /*, { ReactNode }*/ from 'react';
import { Box, Button, Container, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
//import { ReactComponent as AISVG } from '../../assets/ai_star.svg';
import { setPublicPinClicked } from "../../features/dataProcessingSlice"
//import { showProfile } from '../../features/dialogSlice';
import CloseIcon from '@mui/icons-material/Close';
import { MessageProfile } from '../MessageProfile';
import { SummarizedAIProfile } from "./SummarizedAIProfile"
import { Recommendations } from './Recommendations';
import { showProfile } from '../../features/dialogSlice';
import { useAuthProfile } from '../../app/hooks';
import BarChartIcon from '@mui/icons-material/BarChart';
import { selectChat } from '../../features/dialogSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AccountPromotion } from '../iam/AccountPromotion';
import { useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface AIProfileProps {
    bottomSheetRef: any,
    profileToShow: any,
    //story: string
    //name: string
    //authStatus: any
    AIProfileSummary: any,
    //showAccountPromotion: boolean,
    showViewUserStoryAnalytics?: boolean,
    showMessageButton?: boolean,
    chatMode? : boolean
}

export const AIProfile: React.FC<AIProfileProps> = ({ bottomSheetRef, profileToShow, AIProfileSummary,  showViewUserStoryAnalytics = true,    showMessageButton = true, chatMode = false }) => {
    const { authStatus, profile: loggedInUserProfile, isLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
    const isLoggedInUserProfile = loggedInUserProfile.iam_id === profileToShow.iam_id
    const dispatch = useDispatch<any>();
    const chatState = useSelector((state: any) => state.dialog);
    const navigate = useNavigate()
    // function editStory() {
    //     dispatch(showProfile({ isForced: true }))
    // }

    function closeProfile() {
        if (chatMode) {
            dispatch(selectChat())
        } else {
            dispatch(setPublicPinClicked(false))
        }
    }

    function scrollToSummary() {
        document.getElementById('summary')?.scrollIntoView({behavior: 'smooth'})
        if (bottomSheetRef) {
            bottomSheetRef.bottomSheet?.current.snapTo(1000);
        }
    }

    function handleProfileClick() {
        if (authStatus == 'authenticated') {
            dispatch(showProfile({ isForced: true }))
        } else {
            navigate('/sign-in')
        }
    }
    // function editStory() {
    //     dispatch(showProfile({ isForced: true }))
    // }
    const name = profileToShow.name_public
    const story = profileToShow.comment
    const AIRecommendations = AIProfileSummary.recommendations
    let haveAIRecommendations: boolean = (authStatus === 'authenticated' && isLoggedInUserProfile && AIRecommendations) ? Object.keys(AIRecommendations).length > 0 : false
    console.log(profileToShow)
    console.log(loggedInUserProfile)
    //{((authStatus === 'authenticated' && isLoggedInUserProfile && (AIRecommendations?.length ?? Object.keys(AIRecommendations[0]).length > 0))) ?
    //<Recommendations AIRecommendations={AIRecommendations} /> : null}
    return (
 
            <Stack sx={{ width: '100%', pt:4 }} alignItems="center" direction="column" gap={1}>
                {chatMode
                    ?
                    <Button sx={{display:{xs:'flex',sm:'flex',md:'none'}}} variant="text" onClick={closeProfile} startIcon={<ArrowBackIcon />} size="large">
                        Back
                    </Button>
                    :
                    <Stack direction="row" sx={{display:'flex', width:'100%', justifyContent:'space-evenly', alignItems:'center'}}>
                        <Button variant="text" onClick={closeProfile} startIcon={<ArrowBackIcon />} size="large">
                            HOME
                        </Button>
                        <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>
                            PROFILE
                        </Typography>
                    </Stack>
                }
                <Container>
                {authStatus == 'authenticated' || profileToShow.name_public != 'Guest'
                ?
                    ((profileToShow.name_public != loggedInUserProfile.name_public) || loggedInUserProfile.is_completed)
                    ?
                        <>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', pt:2 }}>
                                {profileToShow.name_public + "'s Profile"}
                            </Typography>
                            <MessageProfile profile={profileToShow} showMessageButton={showMessageButton} onEdit={() =>   dispatch(showProfile({ isForced: true, step: 2, singleScreen: true }))}/>
                            <Divider />
                            <Typography variant="h6" sx={{ pt: 2 }}>{name}'s story </Typography>
                            {story && <Typography variant="body1" sx={{ pb: 1, color: '#777777' }}>{story}</Typography>}
                            {haveAIRecommendations && <Recommendations AIRecommendations={AIRecommendations} />}
                            <Divider sx={{ pt: 2 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', pb: 10, }} >
                                <Typography variant="h6" sx={{ pt: 2 }}> Summary (AI Generated) </Typography>
                                <Box id="summary">
                                    <SummarizedAIProfile profileToShow={profileToShow} aiSummary={AIProfileSummary.ai_summary} loggedInUserprofileCoordinates={{ lat: loggedInUserProfile.lat, lng: loggedInUserProfile.lng }} />
                                </Box>
                            </Box>
                        </>
                    :
                    <>
                        <MessageProfile hideUserAndLocationPinsForIncompleteProfile  profile={profileToShow} showMessageButton={showMessageButton} onEdit={() =>   dispatch(showProfile({ isForced: true, step: 2, singleScreen: true, hideUserAndLocationPinsForIncompleteProfile: true }))}/>
                        <Paper onClick={handleProfileClick} elevation={0} sx={{width:'100%', borderRadius:8, cursor:'pointer'}}>
                            <Stack direction="column" gap={2} sx={{p:4, display:'flex', width:'100%', backgroundColor:'#FAF6F4', borderRadius:8}}>
                                <Stack direction="row" gap={1} sx={{display:'flex', justifyContent:'space-between'}}>
                                    <Typography variant="subtitle1" sx={{fontWeight:'bold'}}>Click to create your profile and map pin</Typography>
                                    <ArrowOutwardIcon sx={{color:'#AAAAAA'}}/>
                                </Stack>
                                <Box>
                                    <Typography variant="body2" sx={{mb: 1, fontWeight:'bold'}}>
                                        Your story enables Patient Connect to find other people with similar stories so you:
                                    </Typography>
                                    <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'center', pb:1}}>
                                        <CheckCircleIcon sx={{color:'#656EFF', fontSize:'medium'}}/>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>
                                        Don't feel alone
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'center', pb:1}}>
                                        <CheckCircleIcon sx={{color:'#656EFF', fontSize:'medium'}}/>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>
                                        Gain valuable insights
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} sx={{display:'flex', alignItems:'center'}}>
                                        <CheckCircleIcon sx={{color:'#656EFF', fontSize:'medium'}}/>
                                        <Typography variant="body2" sx={{fontWeight:'bold'}}>
                                        Support others
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Paper>
                        </>
                :
                    <AccountPromotion />
                }
       
                    
                </Container>
            </Stack>
        
    )
}


