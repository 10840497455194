import { //signIn, signOut, getCurrentUser, resendSignUpCode,
   signInWithRedirect } from "aws-amplify/auth";


export interface LoginErrorInfo { type: string, message: string, status: string}

export const SignInWithFB: (setSignInLoading: (value: React.SetStateAction<boolean>) => void, 
                                    setNonFormMessage: (value: React.SetStateAction<LoginErrorInfo | undefined>) => void,
                                    customState?: string | undefined ) => Promise< boolean> =  async (setSignInLoading, setNonFormMessage, customState) =>
 {
  try {
    //await signOut().catch((err) => console.log('Error signing out: ', err));
    //const user = await signIn({ provider: 'Facebook' });
    if (setSignInLoading) setSignInLoading(true);
    //const user = 
    await signInWithRedirect({ provider: 'Facebook' ,  customState: "MMMap"  })
    //redirects tp / so no point to 
    return true;
  } catch (errorFB) {
    if (setSignInLoading) setSignInLoading(false);
    //console.log('error signing in via FB:', errorFB);
    if (setNonFormMessage) setNonFormMessage( {
      type: 'apiMessageFB',
      message: (errorFB instanceof Error) ? errorFB.message : "",
      status: 'error'
    });
    return false;
  }

}


// function Copyright(props: any) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }
