import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, CardContent, Typography, Link, Stack, Paper, Grid } from '@mui/material';
import data from './ResourceData'
import { SvgIcon } from '@mui/material';
import { Box, Container } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResourceCardCTA, LinksProps, MySvgImage , GridLink} from './ResourceCardCTA';
import { ResourceYoutubeCard } from './ResourceYoutubeCard';
import { ResourceLinkCard } from './ResourceLinkCard';
import { YoutubeCard } from './YoutubeCard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as LinkRouter } from "react-router-dom";


// export interface LinkGridProps {
//     //linkData: any
//     level: number | undefined,
//     index: number | undefined,
//     //level: number
// }

export const LinkGrid: React.FC = () => {
    let location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const location_state = location.state

    //    const resourceData = location.state ? location.state.linkData ?? data : data
    //   const resourceDataPath = location.state ? location.state.linkDataLevelIndexPath ?? data : data
    const params = useMemo(() => {
        const path = location.search;
        const params = new URLSearchParams(path);
        const paramsArray = [...params.entries()];
        const levelParam = paramsArray.find(([name, value]) => name === "level");
        const indexHistoryParam = paramsArray.find(([name, value]) => name === "indexHistory");
        const indexParam = paramsArray.find(([name, value]) => name === "index");
        const [, index = '0'] = indexParam ?? []
        const [, level = '0'] = levelParam ?? []
        const [, indexHistory = ''] = indexHistoryParam ?? []
        const indexHistoryNumbers: number[] = indexHistory === '' ?[] :indexHistory.split("-").map( str => parseInt( str))
        return { level: parseInt(level), levelIndex: parseInt(index), indexHistory: indexHistoryNumbers };
    }, [location.search]);

    let resourceData: any = data
    const jsxElements: JSX.Element[] =[]// [        <LinkRouter to={"/"} color="inherit" key={-1}  >            {' '}        </LinkRouter>];
    const indexHistoryMinus1: number[]=[]// = [...params.indexHistory]
    for (let i = 0; i < (params.level); i++) {       
        const indexHistoryMinus1 = params.indexHistory.slice( 0, i)        
        //const to = params.level === 1? `/ResourceGrid` : GridLink( i, indexHistoryMinus1)
     
        const to =  GridLink( i, indexHistoryMinus1)
         jsxElements.push(
            <Typography  variant="subtitle2" fontWeight="bold"  >
            <LinkRouter to={to} color="inherit" >
                {(i === 0) && 'Back to All Resources'}                
                {i > 0 && (resourceData.title??"")}
            </LinkRouter>
            </Typography>
        );
    
        resourceData = resourceData.subs[params.indexHistory[i]].sub
    }


    function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
   
    
    return (<>
    <Breadcrumbs separator="<" aria-label="breadcrumb" sx={{ position: 'fixed', ml: '3%', mt: '.8%'}}>  {jsxElements}  </Breadcrumbs>
        <Card sx={{ width: '100%', height: '100%', textDecoration: 'none' }}>
           
            {(resourceData.subTitle || resourceData.title || true) && <CardHeader title={
                <>
                 
                <Stack sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>                    
                    {resourceData.image && <MySvgImage filePath={resourceData.image} />}
                    <Typography sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }} variant="h5" fontWeight="bold" >
                        {resourceData.title}
                    </Typography>
                    {<Typography sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }} variant="subtitle1" >
                        {resourceData.subTitle} 
                    </Typography>}
                </Stack>
                </>
            } >

            </CardHeader>}
            <CardContent sx={{ width: '100%', height: '100%' }}>
                <Grid container spacing={3} padding={3} >
                    {
                        resourceData.subs && resourceData.subs.map((row: any, index: number) => {
                            const nextIndexHistory =[...params.indexHistory, index]
                            const cardType = row.cardType?? resourceData.cardType
                            return (                                
                                <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={4}  >
                                    {cardType === "ResourceCardCTA" && <ResourceCardCTA linkData={row} level={(params.level ?? 0) + 1} indexHistory={nextIndexHistory} />}
                                    {cardType === "ResourceLinkCard" && <ResourceLinkCard linkData={row} level={(params.level ?? 0) + 1} indexHistory={nextIndexHistory} />}
                                    {cardType === "ResourceYoutubeCard" && <ResourceYoutubeCard linkData={row} level={(params.level ?? 0 + 1)} indexHistory={nextIndexHistory} isPlaying={false} />}
                                </Grid>

                            )
                        }
                        )
                    }
                </Grid>
            </CardContent>
        </Card>
    </>
    );
};

/*
export const Education: React.FC = () => {
    return (<LinkScreen option={'Education'} />)
}
            export const LinkScreen: React.FC<{ option: string }> = ({option}) => {
    const route = option.replace(/\s/g, '')
    const linkDataSlice = data.find((el: any) => el.name.replace(/\s/g, '') === route)
            return (<LinkGrid linkData={{ linkData: linkDataSlice, level: 0 }} />)
}
            */
export const Resources: React.FC = () => {
    return (<LinkGrid />)
}


/*
export const CommonTreatments: React.FC = () => { return (<LinkScreen option={'CommonTreatments'} />) }
export const FinancialResources: React.FC = () => { return (<LinkScreen option={'FinancialResources'} />) }
xport const PatientStories: React.FC = () => { return (<LinkScreen option={'PatientStories'} />) }
*/
