import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { showProfile } from "../../features/dialogSlice";
import { useDispatch/*, useSelector */ } from 'react-redux';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';

export const AddPinChip: React.FC<{ id: string, chipText: string /*, handleClick: ()=> void*/}> = ({ id, chipText }) => {
    const dispatch = useDispatch<any>();


    //const theme = useTheme();
    function handleClick() {
        dispatch(showProfile({ isForced: true }))
    }

    return (
        <Box>
            <Button id={id}
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleClick}
                sx={{ backgroundColor: "white", maxHeight: '40px', minHeight:'40px', maxWidth:'40px', minWidth:'40px' }}
            >
                <EditLocationAltIcon sx={{color: "#656EFF"}}/>
            </Button>

        </Box>

    );
}