import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import Link from '@mui/material/Link';
import {  AccordionSummary, AccordionDetails, Accordion, Stack } from '@mui/material';
//import { CareCenterInfo, CareCenterProps } from './CareCenterCard'
import { Centers, Point } from './Centers';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { loadData, remove_carecenters } from '../../features/CareCenterDataSlice';
//import { AnyAction } from '@reduxjs/toolkit';
import { useAuthenticator } from '@aws-amplify/ui-react';
//import { loadData, remove_carecenters } from '../features/CareCenterDataSlice';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Container from '@mui/material/Container';

export interface CareCenterSummaryProps {
    //careCentersData: CareCenterInfo[]
    center: Point
    user: any
}
export const CareCenterSummary: FC<any> = React.memo (({ center }) => {
    const { user } = useAuthenticator( (context) => [context.user])
    return <CareCenterSummaryMemoized center={center} user={user}/>
    return null
})
export const CareCenterSummaryMemoized: FC<CareCenterSummaryProps> = ({  center, user }) => {
    //const center = searchState.cnter
    const centersData  = useSelector<any>(state => state.loaddata.carecenters, shallowEqual) as any[]   
    const dispatch = useDispatch<any>(); 
    React.useEffect(() => {
        //getSearchByTerm("Newark, New Jersey"));
        //if( center !== undefined)
        if (center)
          if (center.lat !== 0){
            //console.log(`dispatch loadData for ${center.lat} ${center.lng }`)
            dispatch(loadData({ type: "carecenters", center:  center  }));
          }
      }, [center])
    if( !centersData) 
        return null
    
    return (
        <Box sx={{pb:10}}>
            {(centersData as any[]).length > 0 &&

                <Accordion disableGutters elevation={0} sx={{border:1, borderColor:'#C3C6FF'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Card elevation={0} sx={{ width: '100%' }}>
                            <CardContent >
                                <Stack sx={{ width: "100%" }}>
                                    <Typography variant="h6">
                                        {centersData[0].count_in_radius} Care Center{centersData[0].count_in_radius>1?"s":""} near your pin
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {centersData[0].count_total} Care Centers in the US
                                    </Typography>
                                </Stack>

                            </CardContent>
                        </Card>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Centers careCentersData={centersData} />
                    </AccordionDetails>
                </Accordion>

            }
        </Box>
    );
};

