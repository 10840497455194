import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { showDeleteAccount } from "../../features/dialogSlice";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Backdrop, Fade, Modal } from '@mui/material';
import { useDeleteUserDataMutation } from '../../features/profilesSlice';
//import { useDeleteUserSurveysMutation } from '../../features/surveysSlice';
import { signOut, deleteUser, GetCredentialsOptions } from 'aws-amplify/auth';
import TextField from '@mui/material/TextField';
import { useAuthProfile } from '../../app/hooks';
import { CircularProgressCentered } from '../../app/sharedcomponents';
import { setPublicPinClicked } from '../../features/dataProcessingSlice';
import { showCareCenters, showWarriors } from '../../features/filterSlice';
import { chatApi } from '../../features/ChatSlice';

declare global {
    interface Window {
        FB: {
            logout(callback: (response: any) => void): void;
            // Add other FB methods you need here
        };
    }
}
const style = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '400px',
    height: '400px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
interface DeleteProfileProps {
    //showDeleteAccountState: boolean
    //handleSignOut: () => Promise<void>
}

export const signOutDeleteUserFunc: ( isFederated: boolean , postSignOutFunction: ( ) => void ) => any = async (isFederated, postSignOutFunction) => {
    if (!isFederated) {
        console.log(`Deleting non federated user`)
        await deleteUser();
    }
    const response = await signOut();
    postSignOutFunction()//dispatch(chatApi.util.invalidateTags(['Ready']))
    console.log("signOut:", response)
    if (!isFederated) {
        window.location.replace('/map');
        //window.location.assign('/'); // Navigate to '/'
        window.location.reload(); 
    }
    return response
}

export const ConfirmDeleteProfile: React.FC<DeleteProfileProps> = ({  }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [errorVisible, setErrorVisible] = React.useState('hidden');
    const { authStatus, isFederated, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const showDeleteAccountState = useSelector((state: any) => state.dialog.showDeleteAccount);
    const [DeleteUserData, { isLoading: isLoadingDeleteProfile, isSuccess: isSuccessDeleteProfile, isError: isErrorDeleteProfile, error: errorDeleteProfile, reset }] = useDeleteUserDataMutation()
    //const [DeleteSurvey, { isLoading: isLoadingDeleteSurvey, isSuccess: isSuccessDeleteSurvey, isError: isErrorDeleteSurvey, error: errorDeleteSurvey,  }] = useDeleteUserSurveysMutation()
    async function handleDeleteSubmit(event: any) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const confirmation = data.get("deleteAccount");

        if (confirmation?.toString().toLowerCase() === "delete") {
            try {
                setErrorVisible('hidden');
                setDeleteLoading(true)
                try {
                    dispatch(setPublicPinClicked(false))
                    dispatch(showCareCenters(false));
                    dispatch(showWarriors(false))
                    console.log('deleting account data', profile.iam_id);                  
                    //too soon dispatch(chatApi.util.invalidateTags(['Ready']))                    
                    DeleteUserData(profile.id)
                } catch (error) {
                    // add error handling here
                    console.log(error);
                }
            } catch (error) {
                console.log(error);
            }
            setDeleteLoading(false)
        } else {
            setErrorVisible('visible');
        }
    }
    const handleClose = () => {
        dispatch(showDeleteAccount(false))
        setErrorVisible('hidden');
    }
    console.log("useAuthProfile() isSuccess: ", isSuccess)

    const signOutDeleteUserFunc: () => any = async () => {
        if (!profile.is_federated && !isFederated) {
            console.log(`Deleting non federated user`)
            await deleteUser();
        }
        const response = await signOut();
        dispatch(chatApi.util.invalidateTags(['Ready']))
        console.log("signOut:", response)
        if (!profile.is_federated && !isFederated) {
            window.location.replace('/map');
            //window.location.assign('/'); // Navigate to '/'
            window.location.reload(); 
        }
        return response
    }
    useEffect(() => {
        console.log(`Deletion  ${isErrorDeleteProfile ? "ERROR" : "SUCCESS"}`)
        if (isSuccessDeleteProfile) {
            dispatch(chatApi.util.invalidateTags(['Ready']))
            setTimeout(() => {
                //signOutDeleteUserFunc( profile.is_federated || isFederated, () => dispatch(chatApi.util.invalidateTags(['Ready']))) //signout reloads
                signOutDeleteUserFunc( ) //signout reloads
                dispatch(showDeleteAccount(false))
            }, 3000)
            //const response = await signOut();
            //setDeleteLoading( F)] = React.useState(false);
        }
    }, [isSuccessDeleteProfile])
    console.log("isLoadingDeleteProfile", isLoadingDeleteProfile, "isSuccess", isSuccessDeleteProfile, "isError", isErrorDeleteProfile)
    //const disableClose = deleteLoading  //&& !isSuccessDeleteProfile && !isErrorDeleteProfile
    return (
        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description"
            open={showDeleteAccountState}

            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500, },
            }} >
            <Fade in={showDeleteAccountState}>
                <Box sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: (isErrorDeleteProfile || isSuccessDeleteProfile || isError) ? 'space-between' : null,
                    alignItems: 'flex-start',
                    width: '400px',
                    height: '400px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    //width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Box sx={{ height: '80%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                      {(isLoading || isLoadingDeleteProfile)   &&    <CircularProgressCentered />}
                        <Typography variant="h6" component="h2">
                            {(isError || isErrorDeleteProfile) && <>Error Occurred</>}
                            {isLoadingDeleteProfile && 'Deleting your account data...'}
                            {isSuccessDeleteProfile && ' Account and data has been deleted.  You will now be logged out.'}
                        </Typography>


                    </Box>
                    {(authStatus === 'authenticated' && !isLoadingDeleteProfile && !isSuccessDeleteProfile && !isErrorDeleteProfile) && <>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Confirm Account Deletion
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            Type "delete" in the box and click confirm to complete account deletion.
                        </Typography>
                        <br></br>
                        <Box component="form" onSubmit={handleDeleteSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField name="deleteAccount" id="deleteAccount" label='type "delete" in box' variant="outlined" />
                            <br></br>
                            <Typography variant="caption" sx={{ visibility: errorVisible }} color='error'                            >
                                Type "delete" in the box to confirm account deletion
                            </Typography>
                            <LoadingButton loading={deleteLoading || isLoadingDeleteProfile} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bcolor: '#666eff' }} disabled={!isSuccess}                            >
                                Delete account
                            </LoadingButton>

                        </Box>
                        <Button disabled={isLoadingDeleteProfile} onClick={handleClose}>Close</Button>

                    </>}
                   
                    {(authStatus === 'unauthenticated' && !isSuccessDeleteProfile) && <>
                        <Typography variant="h5" sx={{}}                    >
                            Please login in order to delete your account data
                        </Typography>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#666eff' }} onClick={() => navigate("/SignInToDeleteProfile")}                         >
                            LOGIN
                        </Button>
                    </>
                    }
                </Box>
            </Fade>
        </Modal >
    )
}
{
/* <Typography variant="h6" component="h2">
Account data Has been deleted.
</Typography> */}
