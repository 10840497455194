import { Box, Button, Typography } from '@mui/material';
import React, { Component, ReactNode, ErrorInfo } from 'react';
import { Link, useRouteError } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false,   error: undefined };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (<ErrorPage   title="Oops! <h1>Something went wrong." errorText={this.state.error ? this.state.error.message : 'Unknown error'}/>);
    }

    return this.props.children;
  }
}
interface RouteError {
  status?: number;
  statusText?: string;
  message?: string;
}

export const RouteErrorPage: React.FC = () => {
  const error = useRouteError() as RouteError;
  console.error("Route Error:", error);

  return (<ErrorPage errorText={`Status: ${error?.status} ${error?.statusText} ${error.message}`} />)
}


export const ErrorPage: React.FC<{ errorText: string, title?: string }> = ({ title = "Oops! Sorry, an unexpected error has occurred.", errorText }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <div>
        <Typography variant="h2" gutterBottom>{title}</Typography>
      </div>
      <div>
        <Typography variant="body2" gutterBottom>
          {errorText}
        </Typography>
      </div>
   
      <div>        
        <Button component={Link}
          to="/"
          variant="contained"
          color="primary"
          size="large" // Adjust button size to large
          sx={{
            marginTop: '30px', 
            borderRadius: '20px', // Rounded corners
            padding: '15px 30px', // Increase padding for bigger size
            fontSize: '1.2rem'
          }}>
          Back to Map
        </Button>
      </div>
    </Box>
  );
};

//export default ErrorPage;


export default ErrorBoundary;
