import React, { useEffect, createContext, useContext } from 'react';
//import { useForm, Controller } from 'react-hook-form';
import { /*RadioGroup, FormControlLabel, Radio, Button, CircularProgress, TextField, */Box } from '@mui/material';
// import { profileCreateMatch, useGetLoggedInUserProfileQuery, useUpdateProfileMutation } from '../../features/profilesSlice';
// import { skipToken } from '@reduxjs/toolkit/query';
// import { /*search,*/ getSearchByTerm, SearchState } from "../../features/searchSlice";
//import { useDispatch } from 'react-redux';
//import { getSearchStateFromPayload } from '../../features/searchSlice'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { CircularProgressCentered } from '../../app/sharedcomponents';
import { useFormContext } from 'react-hook-form';



export interface StepType {
  screen: ReactJSXElement,
  title?: string;
  subTitle?: string;
  subTitle2?: string;
  buttonTitle?: string
  noProgressBar?: boolean
  noBackArrow?: boolean
  titleColor?: string
  autoSizeDialog?: boolean
  showBottomRight?: boolean
  noButton?: boolean

}
export function isTitleSubtitle(obj: any): obj is { title: string; subTitle: string; } {
  return typeof obj === 'object' && 'title' in obj && 'subTitle' in obj;
}
export const GetTitleFromTitleSubtitle: (step: StepType | string | undefined) => string | null = (step) => step ? (isTitleSubtitle(step) ? step.title : (step as string)) : null
export const GetSubTitleFromTitleSubtitle: (step: StepType | string | undefined) => string | null = (step) => step ? isTitleSubtitle(step) ? step.subTitle : null : null
export const GetSubTitle2FromTitleSubtitle: (step: StepType | string | undefined) => string | null = (step) => step ? isTitleSubtitle(step) ? (step as any).subTitle2 : null : null
export const GetTitleSubTitleObject: (step: StepType | string | undefined) => StepType | null = (step) => isTitleSubtitle(step) ? step as StepType : null

export interface ProfileFormData {
  location: { country: string, zipCode: string } | undefined
  zipCode: string | undefined
  comment: string | undefined
  type: string | undefined
  communicateWithOthers: string | undefined;
  receiveEmails: string | undefined;
  provideInformationForResearch: string | undefined;
  name_public: string

}


export interface ProfileQuestionsFormProps {
  data: any
  wizardData: any
  updatedData?: any
  //onClose: () => void;
  //handleNext: () => void;
  stepIndex: number;
  isLastStep: boolean
  //control: any, formState: any
  //screens: ReactJSXElement[]
  steps: StepType[]
  isLoading: boolean
  isUpdating: boolean
  [key: string]: any; // Allows any additional props
}

export interface StepProps {
  data: any,
  updatedData?: any,
  //profile: any,
  //control: any,
  //formState: any
  step: StepType | undefined
  stepIndex: number
  wizardData: any 

}


const StepContext = createContext<StepProps>({
  data: undefined,
  //profile: undefined,
  //control: undefined,
  //formState: undefined,
  step: undefined,
  stepIndex: 0,
  wizardData: {}
});
export const useStep: () => (StepProps) = () => useContext(StepContext);

export const QuestionsForm: React.FC<ProfileQuestionsFormProps> = ({ data, wizardData, updatedData, steps, stepIndex, isLastStep: lastStep, isLoading, isUpdating,  ...rest  }) => {
  //const dispatch = useDispatch<any>();
  //const { reset: resetForm, getValues, control, handleSubmit, formState} = useForm<FormData>();
  //const [UpdateProfile, { isLoading, isSuccess, isError, error, data: updatedProfile, reset }] = useUpdateProfileMutation()
  //const  { errors, isDirty, dirtyFields } = formState
  const currentStep = steps[stepIndex]
  //const formRef = useRef(null);
  useEffect(() => {
    //formRef.current?.submit()
  }, [stepIndex])

  const { control, formState: { errors }, trigger, clearErrors, setValue, reset, getValues: kuku, setFocus, watch } = useFormContext<ProfileFormData>();
  const formData = kuku();
  
  return (
    < StepContext.Provider value={{ data, updatedData, step: currentStep, stepIndex: stepIndex, wizardData: wizardData }} >
      {steps.map((step, index) => {       
        return (
          <Box   {...rest} key={index} sx={index !== stepIndex ? { display: 'none' } : { height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', /*backgroundColor: 'pink'*/ }}>
            {(isLoading || isUpdating) && <CircularProgressCentered />}
            {(index === stepIndex && !isLoading) && step.screen}
          </Box>)
      }
      )}
    </StepContext.Provider>
  );
}


export const yesNoErrorMessage = "Please choose Yes or No"
export const prefNotTo = "Prefer not to respond"